import SponsorshipRedux from 'redux/sponsorships';
import Dues from 'redux/dues';
import { Form, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import CtaButton from 'components/atoms/ctaButton';
import { yearTranslateHelper } from 'utils/yearsTranslateHelper';
import InputForm from 'components/atoms/InputForm';
import { parseDate, frequency, months } from 'utils/date';
import Pagination from 'components/atoms/Pagination';
import Table from 'components/atoms/Table';
import PriceText from 'components/atoms/PriceText';
import { ReactComponent as LargeCloseIcon } from 'assets/images/largeCloseIcon.svg';
import { ReactComponent as MediumCloseIcon } from 'assets/images/mediumCloseIcon.svg';
import style from './index.module.scss';
import InfoTable from 'components/molecules/InfoTable';

const PER_PAGE = 4;
const incomeEarnerValues = {
  brother: { en: 'Brother', ar: 'أخي' },
  father: { en: 'Father', ar: 'أبي' },
  mother: { en: 'Mother', ar: 'أمي' },
  other_relative: { en: 'Other Relative', ar: 'قريب اخر' },
  outside_source: { en: 'Outside Source', ar: 'مصدر خارجى' },
  sister: { en: 'Sister', ar: 'أختي' },
  other: { en: 'Other', ar: 'آخر' },
};

const SponsoredSingleChild = () => {
  const { t, i18n } = useTranslation('singleChild');
  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const history = useHistory();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { id } = useParams();

  const xxLargeScreen = useMedia(`(max-width: 1600px)`);
  const largeScreen = useMedia(`(max-width: 992px)`);
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const smallScreen = useMedia(`(max-width: 576px)`);

  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelReasonIsOther, setCancelReasonIsOther] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    per_page: PER_PAGE,
    sponsorship_id: id,
  });

  const sponsorship = useSelector(
    (state) => SponsorshipRedux.selectors.sponsorshipsSelectors.selectById(state, id) || {},
  );

  const dues = useSelector(Dues.selectors.duesSelectors.selectAll);
  const duesCount = useSelector(Dues.selectors.totalEntries);
  const currenPage = useSelector(Dues.selectors.currentPage);

  const { village } = sponsorship.sponsoree || {};
  const { district } = village || {};
  const { governorate } = district || {};

  const columns = [
    {
      title: '',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '',
      dataIndex: 'price',
      key: 'price',
    },
  ];

  const getDataSource = () =>
    dues.map((el, index) => {
      return {
        key: index,
        date: (
          <div className={style.sponsorshipPayDate}>
            <div>
              <img src="/images/Line-blue.svg" />
            </div>
            {parseDate(el.due_date, i18n.language)}
          </div>
        ),

        price: (
          <div className={style.sponsorshipPrice}>
            <PriceText price={el.amount} currency={el.currency} />
          </div>
        ),
      };
    });

  const childFamilyMembers = {
    father_name: t('father'),
    mother_name: t('mother'),
    sisters: t('sister'),
    brothers: t('brother'),
  };

  const cancelationReasons = [
    { value: 'financial', label: t('financial') },
    { value: 'personal', label: t('personal') },
    { value: 'communication_issues', label: t('communication_issues') },
    { value: 'not_interested_anymore', label: t('not_interested_anymore') },
    { value: 'other', label: t('other') },
  ];

  useEffect(() => {
    dispatch(SponsorshipRedux.thunks.getSponsorship({ id, includes: 'sponsoree' }));
  }, [i18n.language]);

  useEffect(() => {
    setLoading(true);
    dispatch(Dues.thunks.getAllDues(params));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [params, i18n.language]);

  const handlePageChagne = (page) => {
    setParams((prevState) => {
      return {
        ...prevState,
        page: page,
      };
    });
  };

  const showModal = () => {
    setIsVisible(true);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const redirect = (path) => {
    history.push(path);
  };

  const parseDateOfBirth = () => {
    if (!sponsorship.sponsoree?.date_of_birth) return '';

    const date = new Date(sponsorship.sponsoree?.date_of_birth);
    const day = date.getDate();
    const month = months[date.getMonth()][i18n.language];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const getChildLocalizeValue = (key) => {
    return sponsorship.sponsoree && sponsorship.sponsoree[`${key}_${i18n.language}`];
  };

  const onSubmit = useCallback(
    (values) => {
      setLoading(true);

      var currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, '0');
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const year = currentDate.getFullYear();
      currentDate = day + '-' + month + '-' + year;

      let sponsorshipData = {
        exit_reason: values.exit_reason,
        ended_at: currentDate,
        status_event: 'request_deactivation',
      };

      if (cancelReasonIsOther) sponsorshipData.other_exit_reason = values.other_exit_reason;
      else delete sponsorshipData.other_exit_reason;

      let formData = {
        sponsorship_id: sponsorship.id,
        sponsorship: sponsorshipData,
      };

      dispatch(SponsorshipRedux.thunks.cancelSponsorship({ formData, id })).then((res) => {
        redirect('/sponsorships');
      });
    },
    [cancelReasonIsOther],
  );

  const renderChildName = () => (
    <>
      <div className={style.childCode}>{sponsorship.sponsoree?.code}</div>
      <div className={style.childName}>{sponsorship.sponsoree?.full_name}</div>
    </>
  );

  const renderSponsorshipStatus = () => {
    if (sponsorship.status === 'active') return t('ongoing');
    else if (sponsorship.status === 'inactive') return t('canceled');
    else return t('deactivation-requested');
  };
  const renderChildFamilyMember = (MemberKey) =>
    sponsorship.sponsoree[MemberKey] && (
      <div className={style.childDetail}>
        <span>{childFamilyMembers[MemberKey]}</span>
        {sponsorship.sponsoree[MemberKey]}
      </div>
    );

  const renderSponsorshipAmount = () => (
    <>
      {sponsorship.payment_currency === 'usd' ? (
        <div>
          {t('$')}
          {sponsorship.sponsoree?.price.toFixed(2) * frequency[sponsorship.frequency]?.value}
          <span className={style.frequency}>/{frequency[sponsorship.frequency].label[i18n.language]}</span>
        </div>
      ) : (
        <div>
          {sponsorship.sponsoree?.price.toFixed(2) * frequency[sponsorship.frequency]?.value}
          {t('le')}
          <span className={style.frequency}>/{frequency[sponsorship.frequency]?.label[i18n.language]}</span>
        </div>
      )}
    </>
  );

  const childSponsorshipDetails = {
    sponsoring_as: { label: t('sponsored-as'), value: sponsorship[`sponsoring_as_${i18n.language}`] },
    price: { label: t('sponsorship-amount'), value: renderSponsorshipAmount() },
    started_at: {
      label: t('sponsored-since'),
      value: parseDate(sponsorship.subscription_start_date ?? sponsorship.started_at, i18n.language),
    },
    status: { label: t('status'), value: renderSponsorshipStatus() },
  };

  const renderChildSponsorshipDetails = (Key) => (
    <>
      {childSponsorshipDetails[Key].value && (
        <div className={style.childDetail}>
          <span className={style.sponsorshipDetailsLabel}>{childSponsorshipDetails[Key]?.label}</span>
          {childSponsorshipDetails[Key]?.value}
        </div>
      )}
    </>
  );

  const renderVillageName = () =>
    village && (
      <span>
        {t('I-live-in')} <span>{village.name}</span>,
      </span>
    );

  const renderGovernorateName = () =>
    governorate && (
      <span>
        {t('which-is-a-village-in-the-governorate-of')} {governorate.name}.
      </span>
    );

  const renderAdditionalInfo = () => (
    <div className={style.childAdditionalInfo}>
      <div className={style.childTitle}>
        <div>
          <img src="/images/Line-blue.svg" />
        </div>
        <div>{t('additional-information')}</div>
      </div>
      <div className={style.childInfoData}>
        {t('I-am')} <span>{sponsorship.sponsoree?.character}</span>.{' '}
        {(sponsorship.sponsoree?.special_talents || sponsorship.sponsoree?.hobbies) && `${t('I-enjoy')} `}
        {sponsorship.sponsoree?.special_talents && `${sponsorship.sponsoree?.special_talents} ,`}
        {sponsorship.sponsoree?.hobbies && `${sponsorship.sponsoree?.hobbies}. `}
        {t('I-want-to-be')} {sponsorship.sponsoree?.dreams}.{' '}
        {sponsorship.sponsoree?.best_subject && `${t('My-best-subject-is')} ${sponsorship.sponsoree?.best_subject}. `}
      </div>
    </div>
  );

  const renderVillageInfo = () =>
    village && (
      <div className={style.singleChildVillageInfo}>
        <div className={style.childTitle}>
          <div>
            <img src="/images/Line-blue.svg" />
          </div>
          <div>
            <p>{t('village-information')}</p>
          </div>
        </div>
        <div className={style.childInfoData}>{village.village_info}</div>
      </div>
    );

  const renderChildFamilyIncome = () => [
    {
      key: t('income-earner'),
      value: sponsorship.sponsoree?.income_earner
        ? incomeEarnerValues[sponsorship.sponsoree.income_earner][i18n.language]
        : '',
    },
    { key: t('income-amount'), value: getChildLocalizeValue('income_amount') },
    { key: t('income-source'), value: getChildLocalizeValue('income_earner_professions') },
    { key: t('income-frequency'), value: t(sponsorship.sponsoree?.income_frequency) },
  ];

  return (
    <div className={style.sponsoredSingleChildWrapper}>
      <div className={style.sponsoredSingleChildTitle}>
        <div
          onClick={() => {
            redirect('/sponsorships');
          }}
          className={style.backIcon}
        >
          <img src="/images/back-arrow.svg" />
        </div>
        <div>
          <p>{t('sponsorship-details')}</p>
        </div>
      </div>
      <div className={style.sponsoredSingleChildDetailsWrapper}>
        <div className={style.sponsorship}>
          <div className={style.sponsoredSingleChildImage}>
            {sponsorship?.sponsoree?.education_level === 'rising_leader' && (
              <div className={style.childBadge}>{t('university')}</div>
            )}
            <img src={sponsorship?.sponsoree?.image_url} />
          </div>
          {!largeScreen && (
            <div className={style.sponsoredSingleChildDetails}>
              <div className={style.childCodeName}>{renderChildName()}</div>
              <div className={style.sponsoredChildDetails}>
                <div className={style.childTitle}>
                  <div>
                    <img src="/images/Line-blue.svg" />
                  </div>
                  <div>
                    <p>{t('sponsorship-details')}</p>
                  </div>
                </div>
                <div className={style.childDetails}>
                  {renderChildSponsorshipDetails('sponsoring_as')}
                  {renderChildSponsorshipDetails('price')}
                  {renderChildSponsorshipDetails('started_at')}
                  {renderChildSponsorshipDetails('status')}
                </div>
              </div>
              {sponsorship.status === 'active' && (
                <div className={style.sponsorshipDueWrapper}>
                  <div className={style.childTitle}>
                    <div>
                      <img src="/images/Line-blue.svg" />
                    </div>
                    <div>
                      <p>{t('next-due')}</p>
                    </div>
                  </div>
                  <div>
                    <img src="/images/Line.png" />
                  </div>
                  <div>
                    <span>{parseDate(sponsorship.next_due_date, i18n.language)}</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {largeScreen && (
          <div className={style.sponsoredSingleChildDetails}>
            <div className={style.childCodeName}>{renderChildName()}</div>

            <div className={style.sponsoredChildDetails}>
              <div className={style.childTitle}>
                <div>
                  <img src="/images/Line-blue.svg" />
                </div>
                <div>
                  <p>{t('sponsorship-details')}</p>
                </div>
              </div>
              <div className={style.childDetails}>
                {renderChildSponsorshipDetails('sponsoring_as')}
                {renderChildSponsorshipDetails('price')}
                {renderChildSponsorshipDetails('started_at')}
                {renderChildSponsorshipDetails('status')}
              </div>
            </div>
            {sponsorship.status === 'active' && (
              <div className={style.sponsorshipDueWrapper}>
                <div className={style.childTitle}>
                  <div>
                    <img src="/images/Line-blue.svg" />
                  </div>
                  <div>
                    <p>{t('next-due')}</p>
                  </div>
                </div>
                <div>
                  <img src="/images/Line.png" />
                </div>
                <div>
                  <span>{parseDate(sponsorship.next_due_date, i18n.language)}</span>
                </div>
              </div>
            )}
          </div>
        )}
        <div className={style.sponsoredSingleChildBtnsWrapper}>
          <div className={style.childCodeName}>{renderChildName()}</div>
          {sponsorship.status === 'active' && (
            <div className={style.sponsoredSingleChildBtns}>
              <CtaButton
                type={'fill-primary-500'}
                text={t('buy-gift')}
                icon={'/images/gift-icon.png'}
                customTextStyle={{
                  marginTop: '5px',
                }}
                customStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: largeScreen ? 'center' : '',
                  columnGap: '10px',
                  padding: '0 23px',
                  width: '180px',
                  minHeight: largeScreen ? '46px' : '45px',
                  fontSize: xxLargeScreen ? '' : '1.25rem',
                }}
                onClick={() => {
                  redirect('/gift');
                }}
              />
              <CtaButton
                type={'fill-primary-500'}
                text={t('send-letter')}
                icon={'/images/chat-icon.png'}
                customStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: largeScreen ? 'center' : '',
                  columnGap: '10px',
                  padding: '0 23px',
                  width: '180px',
                  minHeight: largeScreen ? '46px' : '45px',
                  fontSize: xxLargeScreen ? '' : '1.25rem',
                }}
                onClick={() => {
                  redirect(`/letters/${sponsorship.id}`);
                }}
              />
              <CtaButton
                type={'border-primary'}
                text={t('cancel-sponsorship')}
                customStyle={{
                  width: largeScreen ? '180px' : '180px',
                  minHeight: largeScreen ? '46px' : '45px',
                  fontSize: '1rem',
                }}
                onClick={showModal}
              />
              <Modal
                title="Basic Modal"
                visible={isVisible}
                onCancel={handleCancel}
                width={1019}
                closeIcon={mediumScreen ? <MediumCloseIcon /> : <LargeCloseIcon />}
              >
                <div className={style.cancelSponsorshipModalTitle}>
                  <p>{t('modal-title')}</p>
                </div>
                <Form onFinish={onSubmit} form={form} layout="vertical">
                  <InputForm
                    label={t('cancelation-reasons')}
                    name="exit_reason"
                    type="select"
                    options={cancelationReasons}
                    placeholder={t('cancelation-reasons')}
                    onChange={(value) => {
                      setCancelReasonIsOther(value === 'other');
                    }}
                    rules={[{ required: true, message: t('cancelation-reasons-required') }]}
                  />

                  {cancelReasonIsOther && (
                    <InputForm
                      label={t('cancelation-reasons')}
                      name="other_exit_reason"
                      type="text"
                      placeholder={t('cancelation-reasons')}
                      maxLength={255}
                      rules={[{ required: true, message: t('cancelation-reasons-required') }]}
                    />
                  )}

                  <div className={style.cancelSponsorshipModalBtn}>
                    <CtaButton
                      text={t('cancel-sponsorship')}
                      onClickType="submit"
                      type={'fill-primary'}
                      customStyle={{
                        minWidth: smallScreen ? '230px' : '180px',
                        minHeight: smallScreen ? '60px' : '46px',
                      }}
                      loading={loading ? antIcon : ''}
                    />
                  </div>
                </Form>
              </Modal>
            </div>
          )}
        </div>
      </div>
      <div className={style.sponsoredSingleChildInfo}>
        <div className={style.childInfo}>
          <div className={style.childInfoYear}>
            <div className={style.qouteIcon}>
              <img src="/images/qouteIcon.svg" />
            </div>
            <div style={{ display: 'flex', columnGap: '5px' }}>
              <div>{t('I-am')}</div>
              <div>{yearTranslateHelper(t, sponsorship.sponsoree?.current_age)}</div>
              <div>{t('old')}</div>
            </div>
          </div>
          <div className={style.childInfoData}>
            {renderVillageName()}
            {renderGovernorateName()} {t('My-birthday-is')} {parseDateOfBirth()}.
            {sponsorship.sponsoree?.school_year && `${t('I-am-in-grade')}  ${t(sponsorship.sponsoree?.school_year)}.`}
          </div>
        </div>
        {(sponsorship.sponsoree?.father_name ||
          sponsorship.sponsoree?.mother_name ||
          sponsorship.sponsoree?.sisters ||
          sponsorship.sponsoree?.brothers) && (
          <div className={style.childFamily}>
            <div className={style.childTitle}>
              <div>
                <img src="/images/Line-blue.svg" />
              </div>
              <div>
                <p>{t('family')}</p>
              </div>
            </div>
            <div className={style.childDetails}>
              {renderChildFamilyMember('father_name')}
              {renderChildFamilyMember('mother_name')}
              {renderChildFamilyMember('sisters')}
              {renderChildFamilyMember('brothers')}
            </div>
          </div>
        )}
        <InfoTable title={t('family-income-information')} rows={renderChildFamilyIncome()} />
        {renderAdditionalInfo()}
        {renderVillageInfo()}
      </div>
      {dues.length > 0 && (
        <div className={style.paymentHistoryWrapper}>
          <div className={style.paymentHistoryTitle}>{t('payment-history')}</div>
          <div className={`${style.paymentHistoryData} ${'hideTableHeader'} ${'paymentHistoryInput'}`}>
            <Table columns={columns} dataSource={getDataSource()} loading={loading} />
          </div>
          <div className={style.paymentHistoryPagination}>
            <Pagination total={duesCount} pageSize={PER_PAGE} current={currenPage} onChange={handlePageChagne} />
          </div>
        </div>
      )}
      <div className={style.singleChildRemember}>
        <div className={style.qouteIcon}>
          <img src="/images/qouteIcon.svg" />
        </div>
        {t('remember', { name: sponsorship.sponsoree && sponsorship.sponsoree[`full_name_${i18n.language}`] })}
      </div>
    </div>
  );
};

export default SponsoredSingleChild;
