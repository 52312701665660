import { Checkbox, Col, Form, Row } from 'antd';
import CtaButton from 'components/atoms/ctaButton';
import InfoCard from 'components/molecules/InfoCard';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import Sponsors from 'redux/sponsor';
import CartRedux from 'redux/cart';
import style from './index.module.scss';
import ChildrenSection from './sections/ChildrenSection';
import GiftSection from './sections/GiftSection';
import CartSummary from './sections/Summary';
import { useEffect, useState } from 'react';
import { getFreqKey } from 'utils/date';
import { LoadingOutlined } from '@ant-design/icons';
import Alert from 'components/atoms/Alert';
import LoadingList from 'components/molecules/LoadingList';
import InputForm from 'components/atoms/InputForm';

const Cart = () => {
  const { t, i18n } = useTranslation('cart');
  const dispatch = useDispatch();
  const children = useSelector(CartRedux.selectors.getCartChildren);
  const gifts = useSelector(CartRedux.selectors.getCartGift);
  const cartErrors = useSelector(CartRedux.selectors.getCartErrors);
  const cart = useSelector(CartRedux.selectors.getCart);
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [agreementChecks, setAgreementChecks] = useState({ statementOfFaith: false, termsAndConditions: false });
  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const parseCartData = () => ({
    checkout_session: {
      sponsorships_frequency: getFreqKey(cart.freq),
      sponsorships_attributes: children.map((child) => ({
        sponsoree_id: child.id,
        [`sponsoring_as_${i18n.language}`]: child.behalfName,
      })),
      purchased_gifts_attributes: gifts.map((gift) => ({
        sponsoree_id: gift.childId,
        gift_id: gift.giftId,
        quantity: gift.count,
      })),
    },
  });

  const handleCartSubmit = () => {
    setLoading(true);
    dispatch(CartRedux.thunks.checkout());
    let valid = gifts.reduce((acc, gift) => acc && !!gift.childId, true);
    if (valid) {
      dispatch(CartRedux.thunks.submit(parseCartData()));
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    if (cartErrors.length) {
      setErrors(cartErrors);
      dispatch(CartRedux.slice.actions.clearErrors());
    }
  }, [cartErrors]);

  const renderItems = () => (
    <div>
      <div className={style.childrenSection}>{<ChildrenSection />}</div>
      <div className={style.giftSection}>
        <GiftSection />
      </div>
    </div>
  );

  const renderContent = () => (
    <div className={style.cartContent}>
      <Row gutter={40}>
        <Col lg={15} xs={24}>
          {renderItems()}
        </Col>
        <Col lg={9} xs={24}>
          {<InfoCard title={t('summary')} info={<CartSummary />} customWrapper="cartSummary" />}
          <div className={style.checksWrapper}>
            <Checkbox
              name={'statmentOfFaith'}
              checked={agreementChecks.statementOfFaith}
              onChange={() => {
                setAgreementChecks({ ...agreementChecks, statementOfFaith: !agreementChecks.statementOfFaith });
              }}
              style={{ color: '#032f61', fontSize: '1rem' }}
            >
              {t('agreement-title')}
              <a href="/statement-of-faith" style={{ color: '#6bc4e8' }}>
                {t('statement-of-faith')}
              </a>
            </Checkbox>
            <div>
              <Checkbox
                name={'termsAndConditions'}
                checked={agreementChecks.termsAndConditions}
                onChange={() => {
                  setAgreementChecks({ ...agreementChecks, termsAndConditions: !agreementChecks.termsAndConditions });
                }}
                style={{ color: '#032f61', fontSize: '1rem' }}
              >
                {t('agreement-title')}
                <a href="/terms-conditions" style={{ color: '#6bc4e8' }}>
                  {t('terms-conditions')}
                </a>
              </Checkbox>
            </div>
          </div>

          <div className={style.checkoutButton}>
            {(children.length > 0 || gifts.length > 0) && (
              <CtaButton
                text={t('checkout')}
                type="fill-primary-500"
                disabled={!agreementChecks.statementOfFaith || !agreementChecks.termsAndConditions}
                customStyle={{
                  padding: mediumScreen ? '12px 53px' : '16px 67px',
                  fontSize: mediumScreen ? '1.063rem' : '1.375rem',
                }}
                onClick={() => {
                  handleCartSubmit();
                }}
                loading={loading ? antIcon : ''}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );

  const renderErrors = () =>
    !!errors.length && (
      <div className={style.alertWrapper}>
        <Alert type="alert-danger" value={errors.join('\r\n')} />
      </div>
    );

  useEffect(() => {
    dispatch(Sponsors.thunks.getProfile());
  }, [i18n.language]);

  return (
    <div className={style.cartWrapper}>
      <div className={style.cartTitle}>{!loading && renderErrors()}</div>
      {loading && <LoadingList />}
      {!loading && <div className={style.cart}>{renderContent()}</div>}
    </div>
  );
};

export default Cart;
