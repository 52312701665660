const fields = (t) => [
  {
    item: {
      rules: [{ required: true, message: t('cityRequire') }],
      name: 'city',
      type: 'default',
    },
    inputProps: { label: t('cityLabel'), placeholder: t('city') },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [{ required: true, message: t('provinceStateRequire') }],
      name: 'state',
      type: 'default',
    },
    inputProps: { label: t('provinceStateLabel'), placeholder: t('provinceState') },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [{ required: true, message: t('streetNameRequire') }],
      name: 'street_name',
      type: 'default',
    },
    inputProps: { label: t('streetNameLabel'), placeholder: t('streetName') },
    columns: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12, xxl: 12 },
  },
  {
    item: {
      rules: [],
      name: 'zip',
      type: 'default',
    },
    inputProps: { label: t('zipCodeLabel'), placeholder: t('zipCode') },
    columns: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12, xxl: 12 },
  },
];
export default fields;
