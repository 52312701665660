import axios from 'axios';
import set from 'lodash/set';
import assign from 'lodash/assign';
import i18n from 'i18next';
import { clearLocalStorage, loadFromLocalStorage } from 'utils/localStorageHelpers';
import { API_BASE_URL } from 'utils/constants';

axios.defaults.baseURL = API_BASE_URL;

const injectAuthHeaders = (request) => {
  const authHeaders = loadFromLocalStorage('authHeaders');
  assign(request.headers, authHeaders);
  return request;
};

const injectLocale = (request) => {
  set(request, ['params', 'locale'], i18n.language);
  return request;
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    let classResponseCode = +('' + error.response.status)[0];
    if (error.response.status === 401 && window.location.pathname !== '/sign-in') {
      clearLocalStorage();
      window.location.href = '/sign-in';
    }
    if (classResponseCode === 5) window.location.href = '/500';
    return Promise.reject(error);
  },
);
axios.interceptors.request.use(injectAuthHeaders);
axios.interceptors.request.use(injectLocale);

export default axios;
