import axios from '../../utils/requestHelper';

// const getChild = (id) => axios.get(`/singleChild/${id}`);
const getChild = (id) => axios.get(`https://dev-healing-grace-json-server.thewebops.com/singleChild`);

const getAllChildren = (params) => axios.get(`/guests/sponsorees`, { params });

const ChildrenAPI = {
  getChild,
  getAllChildren,
};

export default ChildrenAPI;
