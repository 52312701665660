import { Form as AntForm } from 'antd';
import InfoCard from 'components/molecules/InfoCard';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Sponsor from 'redux/sponsor';
import CtaButton from 'components/atoms/ctaButton';
import style from './index.module.scss';
import { formatDatawithStep, getFieldStepIdentifier } from 'utils/updateProfileSteps/stepMapper';
import updateProfileSteps from 'utils/updateProfileSteps';
import FormStep from 'components/molecules/FormStep';
import { LoadingOutlined } from '@ant-design/icons';
import SponsorRedux from 'redux/sponsor';
import { useDispatch } from 'react-redux';

const UpdateProfile = () => {
  const { t, i18n } = useTranslation('updateProfile');
  const [form] = AntForm.useForm();

  const dispatch = useDispatch();
  const history = useHistory();

  const sponsor = useSelector(Sponsor.selectors.getCurrentSponsor);

  const [fieldData, setFieldData] = useState({});
  const [errorsList, setErrorsList] = useState({});

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;
  const handleFormChange = () => {
    const isFormValid = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(isFormValid);
    if (fieldData?.undefined?.community) {
      if (!fieldData?.undefined?.community?.id && fieldData?.undefined?.community?.code != '') setDisabled(true);
    }
  };

  const redirect = (path) => {
    history.push(path);
  };

  const getCurrentStep = (index) => {
    return updateProfileSteps(t)[index];
  };

  const getCurrentStepValues = (index) => {
    let id = getCurrentStep(index).identifier;
    return fieldData[id];
  };

  const formatDataValue = (data) => {
    let _data = Object.keys(data)
      .map((key) => data[key])
      .reduce((result, current) => Object.assign(result, current), {});

    if (_data.image_url) _data.image = _data.image_url;
    else if (_data.image_url == false) _data.remove_image = true;
    if (_data.community && _data.community.id) _data.community_id = _data.community.id;
    if (_data.community && _data.community.code === '') _data.community_id = '';
    if (_data.phone_number_primary) {
      _data['phone_number_primary[code]'] = _data.phone_number_primary.code;
      _data['phone_number_primary[number]'] = _data.phone_number_primary.number;
    }
    if (_data.phone_number_secondary) {
      _data['phone_number_secondary[code]'] = _data.phone_number_secondary.code;
      _data['phone_number_secondary[number]'] = _data.phone_number_secondary.number;
    } else {
      _data['phone_number_secondary[code]'] = '';
      _data['phone_number_secondary[number]'] = '';
    }
    if (_data.date_of_birth === 'dd/mm/yyyy') _data.date_of_birth = '';
    delete _data.image_url;
    delete _data.community;
    delete _data.phone_number_primary;
    delete _data.phone_number_secondary;
    return _data;
  };

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleFieldChange = (fieldValue) => {
    if (!Object.keys(fieldValue)) return;
    let updateStepKey = getFieldStepIdentifier(Object.keys(fieldValue)[0], updateProfileSteps(t));
    let updateStep = fieldData[updateStepKey];
    setFieldData((prevState) => {
      return {
        ...prevState,
        [updateStepKey]: {
          ...updateStep,
          ...fieldValue,
        },
      };
    });
  };

  const submitAllStep = () => {
    setLoading(true);
    let postData = formatDataValue(fieldData);
    let formData = getFormData(postData);
    dispatch(SponsorRedux.thunks.updateProfile(formData)).then((res) => {
      if (!res.error) {
        redirect('/profile');
      } else {
        let { errors } = res.payload.response.data;
        setErrorsList(errors);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
    return;
  };

  const renderFormStep = (index) => (
    <div className={`${style.formStepWrapper} ${style['formStepWrapper' + index]} formStepWrapper`}>
      <FormStep
        stepData={getCurrentStep(index)}
        stepValues={getCurrentStepValues(index)}
        errorsList={errorsList}
        t={t}
        showSubmit={false}
      />
    </div>
  );

  const renderFormSteps = () =>
    Object.keys(updateProfileSteps(t)).map((key, index) => {
      return (
        <div className={'UpdateProfileInfoCardWrapper'}>
          <InfoCard title={getCurrentStep(index).name} info={renderFormStep(index)} />
        </div>
      );
    });

  useEffect(() => {
    dispatch(Sponsor.thunks.getProfile());
    let fieldDataFormated = formatDatawithStep(sponsor, updateProfileSteps(t));
    setFieldData(fieldDataFormated);
  }, [dispatch, i18n.language]);

  return (
    <div className={style.updateProfileWrapper}>
      <div
        onClick={() => {
          redirect('/profile');
        }}
        className={style.updateProfileHeader}
      >
        <img src="/images/back-arrow.svg" />
        <p>{t('UpdateProfile')}</p>
      </div>
      <div className={style.updateProfile}>
        <AntForm
          name="form"
          layout="vertical"
          initialValues={sponsor}
          onValuesChange={handleFieldChange}
          onFinish={submitAllStep}
          onFieldsChange={handleFormChange}
          scrollToFirstError={true}
          form={form}
        >
          {renderFormSteps()}
          <div className={style.updateProfileButton}>
            <CtaButton
              text={t('Submit')}
              type={'fill-primary-500'}
              customStyle={{ padding: '17px 60px' }}
              loading={loading ? antIcon : ''}
              disabled={disabled}
            />
          </div>
        </AntForm>
      </div>
    </div>
  );
};

/*
to do

- phone number

*/

export default UpdateProfile;
