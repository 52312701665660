import styles from './index.module.scss';

const ProgressSteps = ({ steps, currentStep, onStepChange, disableNext = false }) => {
  const handleStepChange = (index) => {
    if (index > currentStep && disableNext) return;
    onStepChange(index);
  };

  return (
    <div className={styles.progressStepWrapper}>
      <div className={styles.progressStep}>
        {steps.map((step, index) => (
          <div
            className={currentStep >= index ? styles.singleStep + ' ' + styles.singleStepActive : styles.singleStep}
            onClick={() => {
              handleStepChange(index);
            }}
          >
            <div className={styles.singleStepNum}>{step.number}</div>
            <div className={styles.singleStepName}>{step.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressSteps;
