import PaymentCards from 'redux/cards';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import CtaButton from 'components/atoms/ctaButton';
import PaymentCard from 'components/atoms/PaymentCard';
import { Row, Col } from 'antd';
import EmptyTable from 'components/atoms/Table/Empty';
import LoadingList from 'components/molecules/LoadingList';
import Alert from 'components/atoms/Alert';
import { LoadingOutlined } from '@ant-design/icons';
import style from './index.module.scss';

const MyCards = () => {
  const { t } = useTranslation('myCards');
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const dispatch = useDispatch();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [addCardLoading, setAddCardLoading] = useState(false);
  const [messageError, setMessageError] = useState('');

  const cards = useSelector(PaymentCards.selectors.cardsSelectors.selectAll);
  const default_payment_method = useSelector(PaymentCards.selectors.getDefaultPaymentMethod);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: '#00008B' }} spin />;

  useEffect(() => {
    setLoading(true);
    dispatch(PaymentCards.thunks.getAllCards());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [dispatch]);

  const addNewCard = useCallback(() => {
    setAddCardLoading(true);
    dispatch(PaymentCards.thunks.createCard()).then((res) => {
      window.open(res.payload.data.url, '_self');
      setTimeout(() => {
        setAddCardLoading(false);
      }, 1000);
    });
  }, [dispatch]);

  return (
    <div className={style.myCardsWrapper}>
      <div className={style.myCardsHeader}>
        <div className={style.myCardTitle}>{t('my-cards')}</div>
        {cards.length > 0 && (
          <div className={style.addNewCard}>
            <CtaButton
              text={t('add-new-card')}
              onClick={() => {
                addNewCard();
              }}
              type="border-primary"
              customStyle={{
                minWidth: mediumScreen ? '180px' : '230px',
                minHeight: mediumScreen ? '45px' : '60px',
                fontSize: mediumScreen ? '1.063rem' : '1.375rem',
              }}
              loading={addCardLoading ? antIcon : ''}
            />
          </div>
        )}
      </div>
      {messageError != '' && (
        <div className={style.error}>
          <Alert type="alert-danger" value={messageError} />
        </div>
      )}
      {!loading && cards.length > 0 && (
        <Row
          gutter={[
            { xs: 20, sm: 20, md: 30, lg: 30, xl: 30, xxl: 40 },
            { xl: 0, xxl: 2 },
          ]}
          className={style.myCards}
        >
          {cards.map((card) => (
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <PaymentCard
                cardId={card?.id}
                cardName={card?.billing_details?.name}
                cardNumber={card?.card?.last4}
                cardExpires={`${card?.card?.exp_month}/${card?.card?.exp_year}`}
                cardsLength={cards?.length}
                hasBtns={true}
                defaultCard={default_payment_method}
                changeMessageError={setMessageError}
              />
            </Col>
          ))}
        </Row>
      )}

      {!loading && cards.length == 0 && <EmptyTable />}
      {loading && <LoadingList />}
    </div>
  );
};

export default MyCards;
