import { useHistory } from 'react-router-dom';
import style from './index.module.scss';

const FormCard = ({ title, form, backRoute, customTitleStyle, type = 'full-background-color' }) => {
  const history = useHistory();
  const goBack = () => {
    history.push(backRoute || '');
  };
  return (
    <div className={style.formWrapper}>
      {title && (
        <div className={`${style.formTitle} ${style[type]}`}>
          {backRoute && (
            <div onClick={goBack} className={style.backIcon}>
              <img src="/images/back-arrow.svg" />
            </div>
          )}

          <div className={style.title}>
            <p>{title}</p>
          </div>
        </div>
      )}
      <div className={style.form}>
        <div>{form}</div>
      </div>
    </div>
  );
};

export default FormCard;
