import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const childrenSelectors = {
  ...adapter.getSelectors((state) => state.sponsorees),
};

export const getMeta = createSelector(
  (state) => state?.sponsorees,
  (sponsorees) => sponsorees?.meta,
);

export const totalEntries = createSelector(
  (state) => state?.sponsorees?.meta,
  (meta) => meta?.total_entries,
);

export const currentPage = createSelector(
  (state) => state?.sponsorees?.meta,
  (meta) => meta?.current_page,
);
