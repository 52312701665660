import Sponsors from 'redux/sponsor';
import Dues from 'redux/dues';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import { useEffect, useState } from 'react';
import Table from 'components/atoms/Table';
import PaymentCard from 'components/molecules/PaymentCard';
import style from './index.module.scss';
import PriceText from 'components/atoms/PriceText';
import { parseDate } from 'utils/date';

const LateDues = () => {
  const { t, i18n } = useTranslation('dashboard');
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const sponsor = useSelector(Sponsors.selectors.getCurrentSponsor);
  const lateDues = useSelector(Dues.selectors.duesSelectors.selectAll);
  const [params, setParams] = useState({
    per_page: 4,
  });

  const columns = [
    {
      title: '',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: '',
      dataIndex: 'id_name',
      key: 'id_name',
    },
  ];

  const getDataSource = () =>
    lateDues.map((el, index) => {
      return {
        key: index,
        date: <div className={style.childPayDate}>{parseDate(el.due_date, i18n.language)}</div>,
        price: (
          <div className={style.childPrice}>
            <PriceText price={el.amount} currency={sponsor.payment_currency} />
          </div>
        ),
        id_name: (
          <div className={style.childIdName}>
            <span>{el.sponsoree_code}</span>
            {el.sponsoree_name}
          </div>
        ),
      };
    });

  useEffect(() => {
    setLoading(true);
    dispatch(Dues.thunks.getLateDues(params));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [params, i18n.language]);

  const redirect = (path) => {
    history.push(path);
  };

  const calculateTotal = () => lateDues.map((el) => el?.amount).reduce((acc, price) => acc + price, 0);

  return (
    <div className={style.lateDuesWrapper}>
      <div className={style.lateDuesTitle}>
        <p>
          {t('lateDues')}
          {!mediumScreen && (
            <span className={style.lateDuesTitleTotal}>
              {t('total')}{' '}
              <span>
                <PriceText price={calculateTotal()} currency={sponsor.payment_currency} />
              </span>
            </span>
          )}
        </p>
        <p
          className={style.lateDuesTitleView}
          onClick={() => {
            redirect('/late-dues');
          }}
        >
          {mediumScreen && (
            <span className={style.lateDuesTitleTotal}>
              {t('total')}{' '}
              <span>
                <PriceText price={calculateTotal()} currency={sponsor.payment_currency} />
              </span>
            </span>
          )}
          {lateDues.length > 0 && <span className={style.lateDuesTitleTotalSettleAll}>{t('settleAll')}</span>}
        </p>
      </div>
      {!mediumScreen ? (
        <div className="hideTableHeader">
          <Table
            columns={columns}
            dataSource={getDataSource()}
            loading={loading}
            EmptyTablePath="/dashboard-donations"
          />
        </div>
      ) : (
        <PaymentCard dataSource={getDataSource()} EmptyTablePath="/dashboard-donations" />
      )}
    </div>
  );
};

export default LateDues;
