import SectionHeader from 'components/atoms/SectionHeader';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import style from './index.module.scss';

const HowWeWork = () => {
  const { t } = useTranslation('home');
  const xxLargeScreen = useMedia(`(max-width: 1600px)`);
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const xxsmallScreen = useMedia(`(max-width: 360px)`);

  return (
    <div className={style.HowWeWorkWrapper}>
      <SectionHeader
        text={t('how-we-work')}
        customTextStyle={{ fontSize: mediumScreen ? '1.563rem' : xxLargeScreen ? '30px' : '50px' }}
      />
      <div>
        <iframe
          title="HowWeWork vedio"
          width="100%"
          className={style.HowWeWorkVideo}
          height={!mediumScreen ? '500' : xxsmallScreen ? '220' : '280'}
          src="https://player.vimeo.com/video/850100994?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        />
      </div>
    </div>
  );
};

export default HowWeWork;
