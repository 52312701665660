import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const sponsorshipsSelectors = {
  ...adapter.getSelectors((state) => state.sponsorships),
};

export const getMeta = createSelector(
  (state) => state?.sponsorships,
  (sponsorships) => sponsorships?.meta,
);

export const totalEntries = createSelector(
  (state) => state?.sponsorships?.meta,
  (meta) => meta?.total_entries,
);

export const currentPage = createSelector(
  (state) => state?.sponsorships?.meta,
  (meta) => meta?.current_page,
);

export const getNextPay = createSelector(
  (state) => state?.sponsorships,
  (sponsorships) => sponsorships?.nextPayment,
);
