import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import {
  addGiftToCart,
  addChildToCart,
  changeGiftCounter,
  removeGift,
  assignChild,
  removeChild,
  changeFreq,
  changeBehalfName,
  checkout,
  submit,
} from './thunks';
import { loadFromLocalStorage, addToLocalStorage } from 'utils/localStorageHelpers';

const INIT_CART = {
  gifts: [],
  children: [],
  freq: 1,
  submitted: false,
  errors: [],
};

export const slice = createSlice({
  name: 'cart',
  initialState: adapter.getInitialState({
    cart: loadFromLocalStorage('cart') || INIT_CART,
  }),
  reducers: {
    updateCart(state) {
      state.cart = loadFromLocalStorage('cart') || INIT_CART;
    },
    clearErrors(state) {
      state.cart.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addGiftToCart.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      let giftId = data.id;
      let index = state.cart.gifts.findIndex((cart) => cart.giftId == giftId);
      if (index === -1) state.cart.gifts.push({ giftId: data.id, gift: data, count: 1, childId: '' });
      else state.cart.gifts[index].count++;
      saveCartLocal(state);
    });
    builder.addCase(addChildToCart.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      let childId = data.id;
      let index = state.cart.children.findIndex((child) => child.id == childId);
      if (index === -1) state.cart.children.push(data);
      saveCartLocal(state);
    });
    builder.addCase(changeGiftCounter.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      let giftId = data.gift.gift.id;
      let newCount = data._count;
      let index = state.cart.gifts.findIndex((cart) => cart.giftId == giftId);
      if (index !== -1) state.cart.gifts[index].count = newCount;
      saveCartLocal(state);
    });
    builder.addCase(removeGift.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      let index = state.cart.gifts.findIndex((cart) => cart.giftId == data);
      if (index !== -1) state.cart.gifts.splice(index, 1);
      saveCartLocal(state);
    });
    builder.addCase(assignChild.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      const { giftId, childId } = data;
      let index = state.cart.gifts.findIndex((cart) => cart.giftId == giftId);
      if (index !== -1) state.cart.gifts[index].childId = childId;
      saveCartLocal(state);
    });
    builder.addCase(removeChild.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      let index = state.cart.children.findIndex((child) => child.id == data);
      if (index !== -1) state.cart.children.splice(index, 1);
      saveCartLocal(state);
    });
    builder.addCase(changeFreq.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      state.cart.freq = data;
      saveCartLocal(state);
    });
    builder.addCase(changeBehalfName.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      const { childId, name } = data;
      let index = state.cart.children.findIndex((child) => child.id == childId);
      if (index !== -1) state.cart.children[index].behalfName = name;
      saveCartLocal(state);
    });
    builder.addCase(checkout.fulfilled, (state, action) => {
      state.cart.submitted = true;
    });
    builder.addCase(submit.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      window.open(data, '_self');
      state.cart = INIT_CART;
      saveCartLocal(state);
    });
    builder.addCase(submit.rejected, (state, action) => {
      const { payload } = action;
      const { errors } = payload;
      const { failed_sponsorees_ids, failed_gifts_ids, messages } = errors;
      state.cart.gifts = state.cart.gifts.filter((gift) => !failed_gifts_ids.includes(gift.giftId));
      state.cart.children = state.cart.children.filter((child) => !failed_sponsorees_ids.includes(child.id));
      state.cart.errors = messages;
      state.cart.submit = false;
      saveCartLocal(state);
    });
  },
});

const saveCartLocal = (state) => {
  let cardClone = { ...state.cart };
  delete cardClone.submitted;
  delete cardClone.errors;
  addToLocalStorage('cart', cardClone);
};

const Gifts = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Gifts;
