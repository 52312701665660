const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV || 'development') {
    case 'development':
      return 'http://localhost:3000';
    case 'stg-dev':
      return 'https://dev-healing-grace-api.thewebops.com';
    case 'staging':
      return 'https://healing-grace-api.thewebops.com';
    case 'production':
      return 'https://api.hgegypt.org';
    default:
      return 'https://dev-healing-grace-api.thewebops.com';
  }
};

export const API_BASE_URL = `${getBaseUrl()}/v1`;

export const CABLE_API_BASE_URL = getBaseUrl();
