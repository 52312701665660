import style from './index.module.scss';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Sponsors from 'redux/sponsor';
import { getCountryObj_Code } from 'utils/country';
import { useEffect } from 'react';

const ContentInfo = () => {
  const { t, i18n } = useTranslation('dashboard');
  const dispatch = useDispatch();
  const sponsor = useSelector(Sponsors.selectors.getCurrentSponsor);
  const data = [
    { key: t('email'), value: sponsor['email'], className: 'english' },
    {
      key: t('phoneNumber'),
      value: sponsor.phone_number_primary.code + sponsor.phone_number_primary.number,
      className: 'ltr',
    },
    { key: t('gender'), value: t([sponsor.gender]) },
    {
      key: t('language'),
      value: sponsor.language_preference === 'either' ? t('english-and-arabic') : sponsor.language_preference,
      className: 'english',
    },
    { key: t('country'), value: getCountryObj_Code(sponsor.country_code)?.name[i18n.language] },
    { key: t('address'), value: `${sponsor.street_name}, ${sponsor.city}, ${sponsor.state}`, className: 'english' },
  ];

  useEffect(() => {
    dispatch(Sponsors.thunks.getProfile());
  }, [i18n.language]);

  return (
    <div className={style.contentWrapper}>
      <div className={style.contentHeader}>{t('contactInformation')}</div>
      <div className={style.contentCard}>
        <img className={style.contentCardImage} src={sponsor.image_url || '/images/profile.svg'} />
        <h1 className={style.contentCardName}>{sponsor.full_name}</h1>
        {sponsor.community && (
          <p className={style.contentCardCommunity}>
            {t('youArePartOf')}
            {sponsor.community.name}
          </p>
        )}
        <div className={style.contentCardData}>
          {data.map((el) => (
            <Row className={style.contentCardDataRow}>
              <Col xs={8} xl={12} className={style.contentCardDataKey}>
                {el.key}
              </Col>
              <Col xs={16} xl={12} className={`${style.contentCardDataValue} ${style[el.className]}`}>
                {el.value}
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentInfo;
