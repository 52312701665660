import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { getVillage, getAllVillages } from './thunks';

export const slice = createSlice({
  name: 'villages',
  initialState: adapter.getInitialState({ meta: {} }),
  extraReducers: (builder) => {
    builder.addCase(getVillage.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.village) return;
      adapter.setOne(state, data.village);
    });
    builder.addCase(getAllVillages.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.villages) return;
      state.meta = payload.data.meta;
      adapter.setAll(state, data.villages);
    });
  },
});
const Villages = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Villages;
