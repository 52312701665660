import Sponsor from 'redux/sponsor';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Form, Typography, Row, Col } from 'antd';
import FormCard from 'components/molecules/FormCard';
import InputForm from 'components/atoms/InputForm';
import CtaButton from 'components/atoms/ctaButton';
import Alert from 'components/atoms/Alert';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const SignIn = () => {
  const { t } = useTranslation('signIn');
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const messages = useSelector(Sponsor.selectors.getMessages);

  const handleFormChange = () => {
    const isFormValid = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(isFormValid);
  };

  const submit = useCallback(
    (values) => {
      setLoading(true);
      dispatch(Sponsor.thunks.signIn(values)).then((res) => {
        if (res.error) {
          setError(res?.payload?.[0] || 'Something Went Wrong ');
        } else {
          history.push('/profile');
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    },
    [dispatch, history],
  );

  const renderForm = () => (
    <div className={styles.formWrapper}>
      {messages.length > 0 ? messages.map((message) => <Alert type={message.type} value={t(message.data)} />) : null}
      {error ? <Alert type={'alert-danger'} value={error} /> : null}
      <div className={`${styles.form} ${'loginForm'}`}>
        <Form
          className={styles.formInputs}
          onFieldsChange={handleFormChange}
          onFinish={submit}
          form={form}
          error={error}
          layout="vertical"
        >
          <InputForm
            label={t('email')}
            name="email"
            placeholder="Example@example.com"
            rules={[
              { type: 'email', message: t('email-validation-message') },
              { required: true, message: t('email-required') },
            ]}
          />
          <InputForm
            label={t('password')}
            name="password"
            placeholder={t('password')}
            type="password"
            rules={[{ required: true, message: t('password-required') }]}
          />
          <div className={styles.forgetPassword}>
            <Link to="/forget-password" className={styles.forgetPasswordLink}>
              {t('sign-in-forget-password-link')}
            </Link>
          </div>
          <Form.Item>
            <div className={styles.signinBtn}>
              <CtaButton
                type={'fill-secondary'}
                text={t('log-in')}
                customStyle={{
                  minWidth: mediumScreen ? '180px' : '230px',
                  minHeight: mediumScreen ? '46px' : '59px',
                  fontSize: mediumScreen ? '1.063rem' : '1.375rem',
                  fontWeight: 'normal',
                }}
                loading={loading ? antIcon : ''}
                disabled={disabled}
              />
            </div>
          </Form.Item>
        </Form>
        <div className={styles.signinRedirect}>
          <Typography.Text>
            {t('sign-in-dont-have-account')}
            <Link to="/sign-up"> {t('sign-in-dont-have-account-link')} </Link>
          </Typography.Text>
          <Typography.Text>
            {t('sign-in-resend-confirmation')}
            <Link to="/resend-confirmation"> {t('sign-in-resend-confirmation-link')}</Link>
          </Typography.Text>
        </div>
      </div>
    </div>
  );
  return (
    <div className={styles.signinWrapper}>
      <div className={styles.signinImage} />
      <div className={styles.signinForm}>
        <FormCard title={t('log-in')} form={renderForm()} />
      </div>
    </div>
  );
};

export default SignIn;
