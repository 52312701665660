import style from './index.module.scss';
import { useState } from 'react';
import InputForm from '../InputForm';
import { getCountryDropDownTel, getCountryObj_Code, getCountryObj_Dial } from 'utils/country';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { getCountryCode } from 'utils/geoLocation';

const PhoneNumber = ({ onChange, value, placeholder }) => {
  const [countryCodeValue, setCountryCodeValue] = useState('');
  const [phoneNumberValue, setPhoneNumberValue] = useState('');

  const [wrapperWidth, setWrapperWidth] = useState();

  const handleCountryCodeChange = (code_dial) => {
    setCountryCodeValue(code_dial);
    if (phoneNumberValue)
      onChange({
        code: code_dial,
        number: phoneNumberValue,
      });
  };

  const handlePhoneNumberChange = (phone) => {
    setPhoneNumberValue(phone.target.value);
    if (!phone.target.value) onChange('');
    else
      onChange({
        code: countryCodeValue,
        number: phone.target.value,
      });
  };

  const setCurrentCountry = async () => {
    let code = await getCountryCode();
    let currentCountry = getCountryObj_Code(code);
    if (currentCountry) handleCountryCodeChange(currentCountry.dial_code);
  };

  const getDropdownWidth = useCallback(() => {
    let _wrapperWidth = document.getElementById('phoneNumberWrapper')?.offsetWidth;
    setWrapperWidth(_wrapperWidth);
  }, [document]);

  useEffect(() => {
    if (!countryCodeValue) {
      if (value) {
        setCountryCodeValue(value.code);
        setPhoneNumberValue(value.number);
      } else {
        setCurrentCountry();
      }
    }
    getDropdownWidth();
    window.addEventListener('resize', getDropdownWidth, false);
  }, [value]);

  const renderDropdown = (
    <InputForm
      type={'select'}
      options={getCountryDropDownTel()}
      dropdownMatchSelectWidth={wrapperWidth}
      onChange={handleCountryCodeChange}
      value={countryCodeValue}
    />
  );

  const renderPhoneInput = (
    <InputForm type="number" placeholder={placeholder} value={phoneNumberValue} onChange={handlePhoneNumberChange} />
  );

  return (
    <div className={style.phoneNumberWrapper} id="phoneNumberWrapper">
      <div className={style.phoneNumberCountry + ' phoneNumberCountry'}>{renderDropdown}</div>
      <div className={style.phoneNumberMask}>
        <div className={'phoneNumberMask'}>{renderPhoneInput}</div>
      </div>
    </div>
  );
};

export default PhoneNumber;
