import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';

import {
  signUp,
  signIn,
  updateProfile,
  forgetPassword,
  resetPassword,
  resendConfirmation,
  completeProfile,
  changePassword,
  updateEmail,
  getProfile,
} from './thunks';
import { addToLocalStorage, loadFromLocalStorage, clearLocalStorage } from 'utils/localStorageHelpers';

export const slice = createSlice({
  name: 'sponsors',
  initialState: adapter.getInitialState({
    meta: {},
    messages: [],
    currentSponsor: loadFromLocalStorage('sponsor'),
  }),
  reducers: {
    logout(state) {
      state.currentSponsor = undefined;
      clearLocalStorage();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      state.messages = [
        {
          type: 'alert-success',
          data: 'thankYouForSigningUpSuccessfully',
        },
        {
          type: 'alert-success',
          data: 'pleaseConfirmYourEmailInOrderToLogIn',
        },
      ];
      if (!data.sponsor) return;
      adapter.addOne(state, data.sponsor);
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      const { payload } = action;
      const { data, authHeaders } = payload;
      if (!data.sponsor) return;
      adapter.addOne(state, data.sponsor);
      state.currentSponsor = data.sponsor;
      state.messages = [];
      addToLocalStorage('authHeaders', authHeaders);
      addToLocalStorage('sponsor', data.sponsor);
    });
    builder.addCase(signIn.rejected, (state) => {
      state.messages = [];
    });
    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.sponsor) return;
      adapter.addOne(state, data.sponsor);
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      state.messages = [
        {
          type: 'alert-success',
          data: 'PleaseLoginInOrderToCompleteYourProcess',
        },
      ];
      if (!data.sponsor) return;
      adapter.addOne(state, data.sponsor);
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.sponsor) return;
      adapter.addOne(state, data.sponsor);
    });
    builder.addCase(resendConfirmation.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.sponsor) return;
      adapter.addOne(state, data.sponsor);
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.sponsor) return;
      adapter.addOne(state, data.sponsor);
    });
    builder.addCase(completeProfile.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      state.messages = [
        {
          type: 'alert-success',
          data: 'PleaseLoginInOrderToCompleteYourProcess',
        },
      ];
      if (!data.sponsor) return;
      adapter.addOne(state, data.sponsor);
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.sponsor) return;
      adapter.upsertOne(state, data.sponsor);
      addToLocalStorage('sponsor', data.sponsor);
      state.currentSponsor = data.sponsor;
    });
    builder.addCase(updateEmail.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.sponsor) return;
      adapter.addOne(state, data.sponsor);
    });
  },
});
const Sponsors = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Sponsors;
