import { createAsyncThunk } from '@reduxjs/toolkit';
import SponsoreesAPI from './services';

export const getSponsoree = createAsyncThunk('sponsorees/getSponsoree', async (id, thunkAPI) => {
  try {
    const response = await SponsoreesAPI.getSponsoree(id);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const getAllSponsorees = createAsyncThunk('sponsorees/getAllSponsorees', async (params, thunkAPI) => {
  try {
    const response = await SponsoreesAPI.getAllSponsorees(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const getFeaturedSponsorees = createAsyncThunk('sponsorees/getFeaturedSponsorees', async (params, thunkAPI) => {
  try {
    const response = await SponsoreesAPI.getAllSponsorees(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
