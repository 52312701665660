import { Row, Col } from 'antd';
import CtaButton from 'components/atoms/ctaButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Sponsor from 'redux/sponsor';
import Cart from 'redux/cart';
import style from './index.module.scss';
import ContentInfo from './sections/ContentInfo';
import Donations from './sections/Donations';
import Header from './sections/Header';
import LateDues from './sections/LateDues';
import NextPayment from './sections/NextPayment';
import Sponsorships from './sections/Sponsorships';

const Dashboard = () => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(Sponsor.slice.actions.logout());
    dispatch(Cart.slice.actions.updateCart());
    history.push('/sign-in');
  };

  return (
    <div className={style.dashboard}>
      <Header />
      <Row className={style.rowSection} gutter={40}>
        <Col xs={24} lg={12}>
          <ContentInfo />
        </Col>
        <Col xs={24} lg={12}>
          <Donations />
          <Sponsorships />
        </Col>
      </Row>
      <Row className={style.rowSection} gutter={40}>
        <Col xs={24} lg={12}>
          <NextPayment />
        </Col>
        <Col xs={24} lg={12}>
          <LateDues />
        </Col>
      </Row>
      <div className={style.logOutButton}>
        <CtaButton
          text={t('logout')}
          onClick={logout}
          type="border-primary"
          customStyle={{
            fontSize: '1rem',
            padding: '10px 60px',
            backgroundColor: 'transparent',
          }}
        />
      </div>
    </div>
  );
};

export default Dashboard;
