const fields = (t) => [
  {
    item: { rules: [], type: 'upload', name: 'image', accessor: 'photo_url' },
    inputProps: { label: t('uploadPhoto'), accept: 'image/jpeg,image/png', canRemove: true },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [{ required: true, message: t('firstNameRequire') }],
      name: 'first_name',
      type: 'default',
    },
    inputProps: { label: t('firstNamelabel'), placeholder: t('firstName') },
    columns: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12, xxl: 12 },
  },
  {
    item: {
      rules: [{ required: true, message: t('lastNameRequire') }],
      name: 'last_name',
      type: 'default',
    },
    inputProps: { label: t('lastNameLabel'), placeholder: t('lastName') },
    columns: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12, xxl: 12 },
  },
  {
    item: {
      rules: [],
      name: 'arabic_full_name',
      type: 'default',
    },
    inputProps: { label: t('arabicFullName'), placeholder: t('arabicFullName') },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
    style: { direction: 'rtl' },
  },
  {
    item: {
      rules: [
        { required: true, message: t('emailRequire') },
        { type: 'email', message: t('emailValid') },
      ],
      name: 'email',
      type: 'default',
    },
    inputProps: { label: t('emailLabel'), placeholder: t('email') },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [
        { required: true, message: t('passwordRequire') },
        { pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{6,}$/, message: t('passwordValidation') },
      ],
      name: 'password',
      type: 'password',
    },
    inputProps: { label: t('passwordLabel'), placeholder: t('password') },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [passwordMatchValidation(t)],
      name: 'confirmPassword',
      type: 'password',
    },
    inputProps: {
      label: t('confirmPasswordLabel'),
      placeholder: t('confirmPassword'),
      dependencies: ['password'],
    },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
];
export default fields;

const passwordMatchValidation =
  (t) =>
  ({ getFieldValue }) => ({
    validator(_, value) {
      let password = getFieldValue('password');
      if (password === value) return Promise.resolve();
      else return Promise.reject(t('confirmPasswordValidation'));
    },
  });
