import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const villageSelectors = {
  ...adapter.getSelectors((state) => state.villages),
};

export const getMeta = createSelector(
  (state) => state?.villages,
  (villages) => villages?.meta,
);
