import { Table as AntTable } from 'antd';
import LoadingList from 'components/molecules/LoadingList';
import EmptyTable from './Empty';
import style from './index.module.scss';

const Table = ({ columns, dataSource, loading, EmptyTablePath = '' }) => {
  return (
    <div className={style.table}>
      <div>
        <AntTable
          locale={{
            emptyText:
              !loading && dataSource.length === 0 ? (
                <EmptyTable customePath={EmptyTablePath} />
              ) : (
                <div className={style.tableLoading}></div>
              ),
          }}
          loading={
            loading
              ? {
                  indicator: (
                    <div className={style.tableLoading}>
                      <LoadingList />
                    </div>
                  ),
                }
              : false
          }
          dataSource={!loading && dataSource}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Table;
