import axios from '../../utils/requestHelper';

const signUp = (data) => axios.post(`/sponsors`, data);
const signIn = (data) => axios.post('/sponsors/sign_in', data);
const updateProfile = (data) => axios.put('sponsors', data);
const forgetPassword = (data) => axios.post('/sponsors/password', data);
const resetPassword = (data) => axios.put('/sponsors/password', data);
const resendConfirmation = (data) => axios.post('/sponsors/confirmation', data);
const changePassword = (data) => axios.put('/sponsors', data);
const completeProfile = (data) => axios.put('/sponsors/acquire_access', data);
const updateEmail = (data) => axios.put('/sponsors/email', data);
const getProfile = (params) => axios.get('/sponsors/self', { params: { ...params, includes: 'community,balance' } });

const SponsorAPI = {
  signUp,
  signIn,
  updateProfile,
  forgetPassword,
  resetPassword,
  resendConfirmation,
  changePassword,
  completeProfile,
  updateEmail,
  getProfile,
};

export default SponsorAPI;
