import { createAsyncThunk } from '@reduxjs/toolkit';
import PurposesAPI from './services';

export const getPurpose = createAsyncThunk('purposes/getPurpose', async (id, thunkAPI) => {
  try {
    const response = await PurposesAPI.getPurpose(id);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const getAllPurposes = createAsyncThunk('purposes/getAllPurposes', async (params, thunkAPI) => {
  try {
    const response = await PurposesAPI.getAllPurposes(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
