import style from './index.module.scss';

const InfoTable = ({ title, rows = [] }) =>
  rows.length > 0 && (
    <div className={style.childFamily}>
      <div className={style.childTitle}>
        <div>
          <img src="/images/Line-blue.svg" />
        </div>
        <div>{title}</div>
      </div>
      <div className={style.childFamilyDetails}>
        {rows.map((row) => (
          <div className={style.childFamilyMember}>
            <span>{row.key}</span>
            {row.value}
          </div>
        ))}
      </div>
    </div>
  );

export default InfoTable;
