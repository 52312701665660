import style from './index.module.scss';
import CartRedux from 'redux/cart';
import { useSelector } from 'react-redux';
import PriceText from 'components/atoms/PriceText';
import { useTranslation } from 'react-i18next';
import Sponsors from 'redux/sponsor';

const CartSummary = () => {
  const { t } = useTranslation('cart');
  const children = useSelector(CartRedux.selectors.getCartChildren);
  const gifts = useSelector(CartRedux.selectors.getCartGift);
  const freq = useSelector(CartRedux.selectors.getCartFreq);
  const sponsor = useSelector(Sponsors.selectors.getCurrentSponsor);

  const calculateTotal = () =>
    gifts
      .map(({ count, gift }) => count * gift.price)
      .concat(children.map(({ price }) => (freq || 1) * price))
      .reduce((acc, price) => acc + price, 0);

  const calculateMonthly = () =>
    children.map(({ price }) => price).reduce((acc, price) => acc + (freq || 1) * price, 0);

  const sponsorBalance = () => sponsor.balance;

  const calculateDeductedBalance = () =>
    children.length > 0 ? (sponsorBalance() > calculateTotal() ? calculateTotal() : sponsorBalance()) : 0;

  const renderItemRow = (name, price, subName, count = 1) => (
    <>
      <div className={style.itemRow}>
        <div className={style.itemRowName}>
          {count > 1 && `${count}x `}
          {name}
        </div>
        <div className={style.itemRowInfo}>
          <div className={style.itemRowInfoPrice}>{price}</div>
        </div>
      </div>
      <div className={style.itemRowInfoSubName}>{subName}</div>
    </>
  );

  return (
    <div className={style.cartSummary}>
      <div className={style.cartSummaryItems}>
        {children.map(({ full_name, price }) =>
          renderItemRow(
            full_name,
            <PriceText price={(freq || 1) * price} currency={sponsor.payment_currency} />,
            t(
              freq == 12
                ? 'Billed annually'
                : freq == 6
                ? 'Billed semiannual'
                : freq == 3
                ? 'Billed quarterly'
                : 'Billed monthly',
            ),
          ),
        )}
        {gifts.map(({ gift, count }) =>
          renderItemRow(
            gift.title,
            <PriceText price={count * gift.price} currency={sponsor.payment_currency} />,
            <>
              <PriceText price={gift.price} currency={sponsor.payment_currency} /> <span>{t('each')}</span>{' '}
            </>,
            count,
          ),
        )}
      </div>
      <div className={style.cartSummaryTotal}>
        <div className={style.cartSummaryTotalTitle}>{t('Total')}</div>
        <div className={style.cartSummaryTotalValue}>
          <PriceText price={calculateTotal().toFixed(2)} currency={sponsor.payment_currency} />
        </div>
      </div>

      <div className={style.cartSummaryTotal}>
        <div className={style.cartSummaryTotalTitle}>
          {t(
            freq === 12
              ? 'totalAnnually'
              : freq === 6
              ? 'totalBiannually'
              : freq === 3
              ? 'totalQuarterly'
              : 'totalMonthly',
          )}
        </div>
        <div className={style.cartSummaryTotalValue}>
          <PriceText price={calculateMonthly().toFixed(2)} currency={sponsor.payment_currency} />
        </div>
      </div>

      {children.length > 0 && (
        <div className={style.cartSummaryTotal}>
          <div className={style.cartSummaryTotalTitle}>{t('deductedFromBalance')}</div>
          <div className={`${style.cartSummaryTotalValue} ${style.cartSummaryTotalValueDanger}`}>
            - <PriceText price={calculateDeductedBalance()} currency={sponsor.payment_currency} />
          </div>
        </div>
      )}

      {(gifts.length > 0 || children.length > 0) && (
        <div className={style.cartSummaryTotal}>
          <div className={style.cartSummaryTotalTitle}>{t('amountDue')}</div>
          <div className={style.cartSummaryTotalValue}>
            <PriceText
              price={(calculateTotal() - calculateDeductedBalance()).toFixed(2)}
              currency={sponsor.payment_currency}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CartSummary;
