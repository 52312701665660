export const countries = [
  {
    name: { en: 'Afghanistan', ar: 'أفغانستان' },
    dial_code: '+93',
    code: 'AF',
  },
  {
    name: { en: 'Aland Islands', ar: 'جزر آلاند' },
    dial_code: '+358',
    code: 'AX',
  },
  {
    name: { en: 'Albania', ar: 'ألبانيا' },
    dial_code: '+355',
    code: 'AL',
  },
  {
    name: { en: 'Algeria', ar: 'الجزائر' },
    dial_code: '+213',
    code: 'DZ',
  },
  {
    name: { en: 'AmericanSamoa', ar: 'ساموا الأمريكية' },
    dial_code: '+1684',
    code: 'AS',
  },
  {
    name: { en: 'Andorra', ar: 'أندورا' },
    dial_code: '+376',
    code: 'AD',
  },
  {
    name: { en: 'Angola', ar: 'أنغولا' },
    dial_code: '+244',
    code: 'AO',
  },
  {
    name: { en: 'Anguilla', ar: 'أنغيلا' },
    dial_code: '+1264',
    code: 'AI',
  },
  {
    name: { en: 'Antarctica', ar: 'أنتاركتيكا' },
    dial_code: '+672',
    code: 'AQ',
  },
  {
    name: { en: 'Antigua and Barbuda', ar: 'أنتيغوا وبربودا' },
    dial_code: '+1268',
    code: 'AG',
  },
  {
    name: { en: 'Argentina', ar: 'الأرجنتين' },
    dial_code: '+54',
    code: 'AR',
  },
  {
    name: { en: 'Armenia', ar: 'أرمينيا' },
    dial_code: '+374',
    code: 'AM',
  },
  {
    name: { en: 'Aruba', ar: 'أروبا' },
    dial_code: '+297',
    code: 'AW',
  },
  {
    name: { en: 'Australia', ar: 'أستراليا' },
    dial_code: '+61',
    code: 'AU',
  },
  {
    name: { en: 'Austria', ar: 'النمسا' },
    dial_code: '+43',
    code: 'AT',
  },
  {
    name: { en: 'Azerbaijan', ar: 'أذربيجان' },
    dial_code: '+994',
    code: 'AZ',
  },
  {
    name: { en: 'Bahamas', ar: 'جزر البهاما' },
    dial_code: '+1242',
    code: 'BS',
  },
  {
    name: { en: 'Bahrain', ar: 'البحرين' },
    dial_code: '+973',
    code: 'BH',
  },
  {
    name: { en: 'Bangladesh', ar: 'بنغلاديش' },
    dial_code: '+880',
    code: 'BD',
  },
  {
    name: { en: 'Barbados', ar: 'بربادوس' },
    dial_code: '+1246',
    code: 'BB',
  },
  {
    name: { en: 'Belarus', ar: 'بيلاروسيا' },
    dial_code: '+375',
    code: 'BY',
  },
  {
    name: { en: 'Belgium', ar: 'بلجيكا' },
    dial_code: '+32',
    code: 'BE',
  },
  {
    name: { en: 'Belize', ar: 'بليز' },
    dial_code: '+501',
    code: 'BZ',
  },
  {
    name: { en: 'Benin', ar: 'بنين' },
    dial_code: '+229',
    code: 'BJ',
  },
  {
    name: { en: 'Bermuda', ar: 'برمودا' },
    dial_code: '+1441',
    code: 'BM',
  },
  {
    name: { en: 'Bhutan', ar: 'بوتان' },
    dial_code: '+975',
    code: 'BT',
  },
  {
    name: { en: 'Bolivia, Plurinational State of', ar: 'بوليفيا ، دولة متعددة القوميات' },
    dial_code: '+591',
    code: 'BO',
  },
  {
    name: { en: 'Bosnia and Herzegovina', ar: 'البوسنة والهرسك' },
    dial_code: '+387',
    code: 'BA',
  },
  {
    name: { en: 'Botswana', ar: 'بوتسوانا' },
    dial_code: '+267',
    code: 'BW',
  },
  {
    name: { en: 'Brazil', ar: 'البرازيل' },
    dial_code: '+55',
    code: 'BR',
  },
  {
    name: { en: 'British Indian Ocean Territory', ar: 'إقليم المحيط البريطاني الهندي' },
    dial_code: '+246',
    code: 'IO',
  },
  {
    name: { en: 'Brunei Darussalam', ar: 'بروناي دار السلام' },
    dial_code: '+673',
    code: 'BN',
  },
  {
    name: { en: 'Bulgaria', ar: 'بلغاريا' },
    dial_code: '+359',
    code: 'BG',
  },
  {
    name: { en: 'Burkina Faso', ar: 'بوركينا فاسو' },
    dial_code: '+226',
    code: 'BF',
  },
  {
    name: { en: 'Burundi', ar: 'بوروندي' },
    dial_code: '+257',
    code: 'BI',
  },
  {
    name: { en: 'Cambodia', ar: 'كمبوديا' },
    dial_code: '+855',
    code: 'KH',
  },
  {
    name: { en: 'Cameroon', ar: 'الكاميرون' },
    dial_code: '+237',
    code: 'CM',
  },
  {
    name: { en: 'Canada', ar: 'كندا' },
    dial_code: '+1',
    code: 'CA',
  },
  {
    name: { en: 'Cape Verde', ar: 'الرأس الأخضر' },
    dial_code: '+238',
    code: 'CV',
  },
  {
    name: { en: 'Cayman Islands', ar: 'جزر كايمان' },
    dial_code: '+ 345',
    code: 'KY',
  },
  {
    name: { en: 'Central African Republic', ar: 'جمهورية افريقيا الوسطى' },
    dial_code: '+236',
    code: 'CF',
  },
  {
    name: { en: 'Chad', ar: 'تشاد' },
    dial_code: '+235',
    code: 'TD',
  },
  {
    name: { en: 'Chile', ar: 'تشيلي' },
    dial_code: '+56',
    code: 'CL',
  },
  {
    name: { en: 'China', ar: 'الصين' },
    dial_code: '+86',
    code: 'CN',
  },
  {
    name: { en: 'Christmas Island', ar: 'جزيرة الكريسماس' },
    dial_code: '+61',
    code: 'CX',
  },
  {
    name: { en: 'Cocos (Keeling) Islands', ar: 'جزر كوكوس (كيلينغ)' },
    dial_code: '+61',
    code: 'CC',
  },
  {
    name: { en: 'Colombia', ar: 'كولومبيا' },
    dial_code: '+57',
    code: 'CO',
  },
  {
    name: { en: 'Comoros', ar: 'جزر القمر' },
    dial_code: '+269',
    code: 'KM',
  },
  {
    name: { en: 'Congo', ar: 'الكونغو' },
    dial_code: '+242',
    code: 'CG',
  },
  {
    name: { en: 'Congo, The Democratic Republic of the Congo', ar: 'الكونغو ، جمهورية الكونغو الديمقراطية' },
    dial_code: '+243',
    code: 'CD',
  },
  {
    name: { en: 'Cook Islands', ar: 'جزر كوك' },
    dial_code: '+682',
    code: 'CK',
  },
  {
    name: { en: 'Costa Rica', ar: 'كوستا ريكا' },
    dial_code: '+506',
    code: 'CR',
  },
  {
    name: { en: "Cote d'Ivoire", ar: 'كوت ديفوار' },
    dial_code: '+225',
    code: 'CI',
  },
  {
    name: { en: 'Croatia', ar: 'كرواتيا' },
    dial_code: '+385',
    code: 'HR',
  },
  {
    name: { en: 'Cuba', ar: 'كوبا' },
    dial_code: '+53',
    code: 'CU',
  },
  {
    name: { en: 'Cyprus', ar: 'قبرص' },
    dial_code: '+357',
    code: 'CY',
  },
  {
    name: { en: 'Czech Republic', ar: 'الجمهورية التشيكية' },
    dial_code: '+420',
    code: 'CZ',
  },
  {
    name: { en: 'Denmark', ar: 'الدنمارك' },
    dial_code: '+45',
    code: 'DK',
  },
  {
    name: { en: 'Djibouti', ar: 'جيبوتي' },
    dial_code: '+253',
    code: 'DJ',
  },
  {
    name: { en: 'Dominica', ar: 'دومينيكا' },
    dial_code: '+1767',
    code: 'DM',
  },
  {
    name: { en: 'Dominican Republic', ar: 'جمهورية الدومينيكان' },
    dial_code: '+1849',
    code: 'DO',
  },
  {
    name: { en: 'Ecuador', ar: 'الاكوادور' },
    dial_code: '+593',
    code: 'EC',
  },
  {
    name: { en: 'Egypt', ar: 'مصر' },
    dial_code: '+20',
    code: 'EG',
  },
  {
    name: { en: 'El Salvador', ar: 'السلفادور' },
    dial_code: '+503',
    code: 'SV',
  },
  {
    name: { en: 'Equatorial Guinea', ar: 'غينيا الإستوائية' },
    dial_code: '+240',
    code: 'GQ',
  },
  {
    name: { en: 'Eritrea', ar: 'إريتريا' },
    dial_code: '+291',
    code: 'ER',
  },
  {
    name: { en: 'Estonia', ar: 'إستونيا' },
    dial_code: '+372',
    code: 'EE',
  },
  {
    name: { en: 'Ethiopia', ar: 'أثيوبيا' },
    dial_code: '+251',
    code: 'ET',
  },
  {
    name: { en: 'Falkland Islands (Malvinas)', ar: 'جزر فوكلاند (مالفيناس)' },
    dial_code: '+500',
    code: 'FK',
  },
  {
    name: { en: 'Faroe Islands', ar: 'جزر فاروس' },
    dial_code: '+298',
    code: 'FO',
  },
  {
    name: { en: 'Fiji', ar: 'فيجي' },
    dial_code: '+679',
    code: 'FJ',
  },
  {
    name: { en: 'Finland', ar: 'فنلندا' },
    dial_code: '+358',
    code: 'FI',
  },
  {
    name: { en: 'France', ar: 'فرنسا' },
    dial_code: '+33',
    code: 'FR',
  },
  {
    name: { en: 'French Guiana', ar: 'غيانا الفرنسية' },
    dial_code: '+594',
    code: 'GF',
  },
  {
    name: { en: 'French Polynesia', ar: 'بولينيزيا الفرنسية' },
    dial_code: '+689',
    code: 'PF',
  },
  {
    name: { en: 'Gabon', ar: 'الجابون' },
    dial_code: '+241',
    code: 'GA',
  },
  {
    name: { en: 'Gambia', ar: 'غامبيا' },
    dial_code: '+220',
    code: 'GM',
  },
  {
    name: { en: 'Georgia', ar: 'جورجيا' },
    dial_code: '+995',
    code: 'GE',
  },
  {
    name: { en: 'Germany', ar: 'ألمانيا' },
    dial_code: '+49',
    code: 'DE',
  },
  {
    name: { en: 'Ghana', ar: 'غانا' },
    dial_code: '+233',
    code: 'GH',
  },
  {
    name: { en: 'Gibraltar', ar: 'جبل طارق' },
    dial_code: '+350',
    code: 'GI',
  },
  {
    name: { en: 'Greece', ar: 'اليونان' },
    dial_code: '+30',
    code: 'GR',
  },
  {
    name: { en: 'Greenland', ar: 'الأرض الخضراء' },
    dial_code: '+299',
    code: 'GL',
  },
  {
    name: { en: 'Grenada', ar: 'غرينادا' },
    dial_code: '+1473',
    code: 'GD',
  },
  {
    name: { en: 'Guadeloupe', ar: 'جوادلوب' },
    dial_code: '+590',
    code: 'GP',
  },
  {
    name: { en: 'Guam', ar: 'غوام' },
    dial_code: '+1671',
    code: 'GU',
  },
  {
    name: { en: 'Guatemala', ar: 'غواتيمالا' },
    dial_code: '+502',
    code: 'GT',
  },
  {
    name: { en: 'Guernsey', ar: 'غيرنسي' },
    dial_code: '+44',
    code: 'GG',
  },
  {
    name: { en: 'Guinea', ar: 'غينيا' },
    dial_code: '+224',
    code: 'GN',
  },
  {
    name: { en: 'Guinea-Bissau', ar: 'غينيا بيساو' },
    dial_code: '+245',
    code: 'GW',
  },
  {
    name: { en: 'Guyana', ar: 'غيانا' },
    dial_code: '+595',
    code: 'GY',
  },
  {
    name: { en: 'Haiti', ar: 'هايتي' },
    dial_code: '+509',
    code: 'HT',
  },
  {
    name: { en: 'Holy See (Vatican City State)', ar: 'الكرسي الرسولي (دولة الفاتيكان)' },
    dial_code: '+379',
    code: 'VA',
  },
  {
    name: { en: 'Honduras', ar: 'هندوراس' },
    dial_code: '+504',
    code: 'HN',
  },
  {
    name: { en: 'Hong Kong', ar: 'هونج كونج' },
    dial_code: '+852',
    code: 'HK',
  },
  {
    name: { en: 'Hungary', ar: 'هنغاريا' },
    dial_code: '+36',
    code: 'HU',
  },
  {
    name: { en: 'Iceland', ar: 'أيسلندا' },
    dial_code: '+354',
    code: 'IS',
  },
  {
    name: { en: 'India', ar: 'الهند' },
    dial_code: '+91',
    code: 'IN',
  },
  {
    name: { en: 'Indonesia', ar: 'إندونيسيا' },
    dial_code: '+62',
    code: 'ID',
  },
  {
    name: { en: 'Iran, Islamic Republic of Persian Gulf', ar: 'إيران ، جمهورية الخليج الإسلامية' },
    dial_code: '+98',
    code: 'IR',
  },
  {
    name: { en: 'Iraq', ar: 'العراق' },
    dial_code: '+964',
    code: 'IQ',
  },
  {
    name: { en: 'Ireland', ar: 'أيرلندا' },
    dial_code: '+353',
    code: 'IE',
  },
  {
    name: { en: 'Isle of Man', ar: 'جزيرة آيل أوف مان' },
    dial_code: '+44',
    code: 'IM',
  },
  {
    name: { en: 'Israel', ar: 'إسرائيل' },
    dial_code: '+972',
    code: 'IL',
  },
  {
    name: { en: 'Italy', ar: 'إيطاليا' },
    dial_code: '+39',
    code: 'IT',
  },
  {
    name: { en: 'Jamaica', ar: 'جامايكا' },
    dial_code: '+1876',
    code: 'JM',
  },
  {
    name: { en: 'Japan', ar: 'اليابان' },
    dial_code: '+81',
    code: 'JP',
  },
  {
    name: { en: 'Jersey', ar: 'جيرسي' },
    dial_code: '+44',
    code: 'JE',
  },
  {
    name: { en: 'Jordan', ar: 'الأردن' },
    dial_code: '+962',
    code: 'JO',
  },
  {
    name: { en: 'Kazakhstan', ar: 'كازاخستان' },
    dial_code: '+77',
    code: 'KZ',
  },
  {
    name: { en: 'Kenya', ar: 'كينيا' },
    dial_code: '+254',
    code: 'KE',
  },
  {
    name: { en: 'Kiribati', ar: 'كيريباتي' },
    dial_code: '+686',
    code: 'KI',
  },
  {
    name: { en: "Korea, Democratic People's Republic of Korea", ar: 'كوريا ، جمهورية كوريا الشعبية الديمقراطية' },
    dial_code: '+850',
    code: 'KP',
  },
  {
    name: { en: 'Korea, Republic of South Korea', ar: 'كوريا ، جمهورية كوريا الجنوبية' },
    dial_code: '+82',
    code: 'KR',
  },
  {
    name: { en: 'Kuwait', ar: 'الكويت' },
    dial_code: '+965',
    code: 'KW',
  },
  {
    name: { en: 'Kyrgyzstan', ar: 'قيرغيزستان' },
    dial_code: '+996',
    code: 'KG',
  },
  {
    name: { en: 'Laos', ar: 'لاوس' },
    dial_code: '+856',
    code: 'LA',
  },
  {
    name: { en: 'Latvia', ar: 'لاتفيا' },
    dial_code: '+371',
    code: 'LV',
  },
  {
    name: { en: 'Lebanon', ar: 'لبنان' },
    dial_code: '+961',
    code: 'LB',
  },
  {
    name: { en: 'Lesotho', ar: 'ليسوتو' },
    dial_code: '+266',
    code: 'LS',
  },
  {
    name: { en: 'Liberia', ar: 'ليبيريا' },
    dial_code: '+231',
    code: 'LR',
  },
  {
    name: { en: 'Libyan Arab Jamahiriya', ar: 'الجماهيرية العربية الليبية' },
    dial_code: '+218',
    code: 'LY',
  },
  {
    name: { en: 'Liechtenstein', ar: 'ليختنشتاين' },
    dial_code: '+423',
    code: 'LI',
  },
  {
    name: { en: 'Lithuania', ar: 'ليتوانيا' },
    dial_code: '+370',
    code: 'LT',
  },
  {
    name: { en: 'Luxembourg', ar: 'لوكسمبورغ' },
    dial_code: '+352',
    code: 'LU',
  },
  {
    name: { en: 'Macao', ar: 'ماكاو' },
    dial_code: '+853',
    code: 'MO',
  },
  {
    name: { en: 'Macedonia', ar: 'مقدونيا' },
    dial_code: '+389',
    code: 'MK',
  },
  {
    name: { en: 'Madagascar', ar: 'مدغشقر' },
    dial_code: '+261',
    code: 'MG',
  },
  {
    name: { en: 'Malawi', ar: 'ملاوي' },
    dial_code: '+265',
    code: 'MW',
  },
  {
    name: { en: 'Malaysia', ar: 'ماليزيا' },
    dial_code: '+60',
    code: 'MY',
  },
  {
    name: { en: 'Maldives', ar: 'جزر المالديف' },
    dial_code: '+960',
    code: 'MV',
  },
  {
    name: { en: 'Mali', ar: 'مالي' },
    dial_code: '+223',
    code: 'ML',
  },
  {
    name: { en: 'Malta', ar: 'مالطا' },
    dial_code: '+356',
    code: 'MT',
  },
  {
    name: { en: 'Marshall Islands', ar: 'جزر مارشال' },
    dial_code: '+692',
    code: 'MH',
  },
  {
    name: { en: 'Martinique', ar: 'مارتينيك' },
    dial_code: '+596',
    code: 'MQ',
  },
  {
    name: { en: 'Mauritania', ar: 'موريتانيا' },
    dial_code: '+222',
    code: 'MR',
  },
  {
    name: { en: 'Mauritius', ar: 'موريشيوس' },
    dial_code: '+230',
    code: 'MU',
  },
  {
    name: { en: 'Mayotte', ar: 'مايوت' },
    dial_code: '+262',
    code: 'YT',
  },
  {
    name: { en: 'Mexico', ar: 'المكسيك' },
    dial_code: '+52',
    code: 'MX',
  },
  {
    name: { en: 'Micronesia, Federated States of Micronesia', ar: 'ولايات ميكرونيزيا الموحدة' },
    dial_code: '+691',
    code: 'FM',
  },
  {
    name: { en: 'Moldova', ar: 'مولدوفا' },
    dial_code: '+373',
    code: 'MD',
  },
  {
    name: { en: 'Monaco', ar: 'موناكو' },
    dial_code: '+377',
    code: 'MC',
  },
  {
    name: { en: 'Mongolia', ar: 'منغوليا' },
    dial_code: '+976',
    code: 'MN',
  },
  {
    name: { en: 'Montenegro', ar: 'الجبل الأسود' },
    dial_code: '+382',
    code: 'ME',
  },
  {
    name: { en: 'Montserrat', ar: 'مونتسيرات' },
    dial_code: '+1664',
    code: 'MS',
  },
  {
    name: { en: 'Morocco', ar: 'المغرب' },
    dial_code: '+212',
    code: 'MA',
  },
  {
    name: { en: 'Mozambique', ar: 'موزمبيق' },
    dial_code: '+258',
    code: 'MZ',
  },
  {
    name: { en: 'Myanmar', ar: 'ميانمار' },
    dial_code: '+95',
    code: 'MM',
  },
  {
    name: { en: 'Namibia', ar: 'ناميبيا' },
    dial_code: '+264',
    code: 'NA',
  },
  {
    name: { en: 'Nauru', ar: 'ناورو' },
    dial_code: '+674',
    code: 'NR',
  },
  {
    name: { en: 'Nepal', ar: 'نيبال' },
    dial_code: '+977',
    code: 'NP',
  },
  {
    name: { en: 'Netherlands', ar: 'هولندا' },
    dial_code: '+31',
    code: 'NL',
  },
  {
    name: { en: 'Netherlands Antilles', ar: 'جزر الأنتيل الهولندية' },
    dial_code: '+599',
    code: 'AN',
  },
  {
    name: { en: 'New Caledonia', ar: 'كاليدونيا الجديدة' },
    dial_code: '+687',
    code: 'NC',
  },
  {
    name: { en: 'New Zealand', ar: 'نيوزيلندا' },
    dial_code: '+64',
    code: 'NZ',
  },
  {
    name: { en: 'Nicaragua', ar: 'نيكاراغوا' },
    dial_code: '+505',
    code: 'NI',
  },
  {
    name: { en: 'Niger', ar: 'النيجر' },
    dial_code: '+227',
    code: 'NE',
  },
  {
    name: { en: 'Nigeria', ar: 'نيجيريا' },
    dial_code: '+234',
    code: 'NG',
  },
  {
    name: { en: 'Niue', ar: 'نيوي' },
    dial_code: '+683',
    code: 'NU',
  },
  {
    name: { en: 'Norfolk Island', ar: 'جزيرة نورفولك' },
    dial_code: '+672',
    code: 'NF',
  },
  {
    name: { en: 'Northern Mariana Islands', ar: 'جزر مريانا الشمالية' },
    dial_code: '+1670',
    code: 'MP',
  },
  {
    name: { en: 'Norway', ar: 'النرويج' },
    dial_code: '+47',
    code: 'NO',
  },
  {
    name: { en: 'Oman', ar: 'سلطنة عمان' },
    dial_code: '+968',
    code: 'OM',
  },
  {
    name: { en: 'Pakistan', ar: 'باكستان' },
    dial_code: '+92',
    code: 'PK',
  },
  {
    name: { en: 'Palau', ar: 'بالاو' },
    dial_code: '+680',
    code: 'PW',
  },
  {
    name: { en: 'Palestinian Territory, Occupied', ar: 'الأراضي الفلسطينية المحتلة' },
    dial_code: '+970',
    code: 'PS',
  },
  {
    name: { en: 'Panama', ar: 'بنما' },
    dial_code: '+507',
    code: 'PA',
  },
  {
    name: { en: 'Papua New Guinea', ar: 'بابوا غينيا الجديدة' },
    dial_code: '+675',
    code: 'PG',
  },
  {
    name: { en: 'Paraguay', ar: 'باراغواي' },
    dial_code: '+595',
    code: 'PY',
  },
  {
    name: { en: 'Peru', ar: 'بيرو' },
    dial_code: '+51',
    code: 'PE',
  },
  {
    name: { en: 'Philippines', ar: 'فيلبيني' },
    dial_code: '+63',
    code: 'PH',
  },
  {
    name: { en: 'Pitcairn', ar: 'بيتكيرن' },
    dial_code: '+872',
    code: 'PN',
  },
  {
    name: { en: 'Poland', ar: 'بولندا' },
    dial_code: '+48',
    code: 'PL',
  },
  {
    name: { en: 'Portugal', ar: 'البرتغال' },
    dial_code: '+351',
    code: 'PT',
  },
  {
    name: { en: 'Puerto Rico', ar: 'بورتوريكو' },
    dial_code: '+1939',
    code: 'PR',
  },
  {
    name: { en: 'Qatar', ar: 'دولة قطر' },
    dial_code: '+974',
    code: 'QA',
  },
  {
    name: { en: 'Romania', ar: 'رومانيا' },
    dial_code: '+40',
    code: 'RO',
  },
  {
    name: { en: 'Russia', ar: 'روسيا' },
    dial_code: '+7',
    code: 'RU',
  },
  {
    name: { en: 'Rwanda', ar: 'رواندا' },
    dial_code: '+250',
    code: 'RW',
  },
  {
    name: { en: 'Reunion', ar: 'جمع شمل' },
    dial_code: '+262',
    code: 'RE',
  },
  {
    name: { en: 'Saint Barthelemy', ar: 'سانت بارتيليمي' },
    dial_code: '+590',
    code: 'BL',
  },
  {
    name: { en: 'Saint Helena, Ascension and Tristan Da Cunha', ar: 'سانت هيلانة وأسنسيون وتريستان دا كونها' },
    dial_code: '+290',
    code: 'SH',
  },
  {
    name: { en: 'Saint Kitts and Nevis', ar: 'سانت كيتس ونيفيس' },
    dial_code: '+1869',
    code: 'KN',
  },
  {
    name: { en: 'Saint Lucia', ar: 'القديسة لوسيا' },
    dial_code: '+1758',
    code: 'LC',
  },
  {
    name: { en: 'Saint Martin', ar: 'القديس مارتن' },
    dial_code: '+590',
    code: 'MF',
  },
  {
    name: { en: 'Saint Pierre and Miquelon', ar: 'سانت بيير وميكلون' },
    dial_code: '+508',
    code: 'PM',
  },
  {
    name: { en: 'Saint Vincent and the Grenadines', ar: 'سانت فنسنت وجزر غرينادين' },
    dial_code: '+1784',
    code: 'VC',
  },
  {
    name: { en: 'Samoa', ar: 'ساموا' },
    dial_code: '+685',
    code: 'WS',
  },
  {
    name: { en: 'San Marino', ar: 'سان مارينو' },
    dial_code: '+378',
    code: 'SM',
  },
  {
    name: { en: 'Sao Tome and Principe', ar: 'ساو تومي وبرينسيبي' },
    dial_code: '+239',
    code: 'ST',
  },
  {
    name: { en: 'Saudi Arabia', ar: 'المملكة العربية السعودية' },
    dial_code: '+966',
    code: 'SA',
  },
  {
    name: { en: 'Senegal', ar: 'السنغال' },
    dial_code: '+221',
    code: 'SN',
  },
  {
    name: { en: 'Serbia', ar: 'صربيا' },
    dial_code: '+381',
    code: 'RS',
  },
  {
    name: { en: 'Seychelles', ar: 'سيشيل' },
    dial_code: '+248',
    code: 'SC',
  },
  {
    name: { en: 'Sierra Leone', ar: 'سيرا ليون' },
    dial_code: '+232',
    code: 'SL',
  },
  {
    name: { en: 'Singapore', ar: 'سنغافورة' },
    dial_code: '+65',
    code: 'SG',
  },
  {
    name: { en: 'Slovakia', ar: 'سلوفاكيا' },
    dial_code: '+421',
    code: 'SK',
  },
  {
    name: { en: 'Slovenia', ar: 'سلوفينيا' },
    dial_code: '+386',
    code: 'SI',
  },
  {
    name: { en: 'Solomon Islands', ar: 'جزر سليمان' },
    dial_code: '+677',
    code: 'SB',
  },
  {
    name: { en: 'Somalia', ar: 'الصومال' },
    dial_code: '+252',
    code: 'SO',
  },
  {
    name: { en: 'South Africa', ar: 'جنوب أفريقيا' },
    dial_code: '+27',
    code: 'ZA',
  },
  {
    name: { en: 'South Sudan', ar: 'جنوب السودان' },
    dial_code: '+211',
    code: 'SS',
  },
  {
    name: { en: 'South Georgia and the South Sandwich Islands', ar: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية' },
    dial_code: '+500',
    code: 'GS',
  },
  {
    name: { en: 'Spain', ar: 'إسبانيا' },
    dial_code: '+34',
    code: 'ES',
  },
  {
    name: { en: 'Sri Lanka', ar: 'سيريلانكا' },
    dial_code: '+94',
    code: 'LK',
  },
  {
    name: { en: 'Sudan', ar: 'السودان' },
    dial_code: '+249',
    code: 'SD',
  },
  {
    name: { en: 'Suriname', ar: 'سورينام' },
    dial_code: '+597',
    code: 'SR',
  },
  {
    name: { en: 'Svalbard and Jan Mayen', ar: 'سفالبارد وجان ماين' },
    dial_code: '+47',
    code: 'SJ',
  },
  {
    name: { en: 'Swaziland', ar: 'سوازيلاند' },
    dial_code: '+268',
    code: 'SZ',
  },
  {
    name: { en: 'Sweden', ar: 'السويد' },
    dial_code: '+46',
    code: 'SE',
  },
  {
    name: { en: 'Switzerland', ar: 'سويسرا' },
    dial_code: '+41',
    code: 'CH',
  },
  {
    name: { en: 'Syrian Arab Republic', ar: 'الجمهورية العربية السورية' },
    dial_code: '+963',
    code: 'SY',
  },
  {
    name: { en: 'Taiwan', ar: 'تايوان' },
    dial_code: '+886',
    code: 'TW',
  },
  {
    name: { en: 'Tajikistan', ar: 'طاجيكستان' },
    dial_code: '+992',
    code: 'TJ',
  },
  {
    name: { en: 'Tanzania, United Republic of Tanzania', ar: 'تنزانيا ، جمهورية تنزانيا المتحدة' },
    dial_code: '+255',
    code: 'TZ',
  },
  {
    name: { en: 'Thailand', ar: 'تايلاند' },
    dial_code: '+66',
    code: 'TH',
  },
  {
    name: { en: 'Timor-Leste', ar: 'تيمور ليشتي' },
    dial_code: '+670',
    code: 'TL',
  },
  {
    name: { en: 'Togo', ar: 'توجو' },
    dial_code: '+228',
    code: 'TG',
  },
  {
    name: { en: 'Tokelau', ar: 'توكيلاو' },
    dial_code: '+690',
    code: 'TK',
  },
  {
    name: { en: 'Tonga', ar: 'تونغا' },
    dial_code: '+676',
    code: 'TO',
  },
  {
    name: { en: 'Trinidad and Tobago', ar: 'ترينداد وتوباغو' },
    dial_code: '+1868',
    code: 'TT',
  },
  {
    name: { en: 'Tunisia', ar: 'تونس' },
    dial_code: '+216',
    code: 'TN',
  },
  {
    name: { en: 'Turkey', ar: 'ديك رومى' },
    dial_code: '+90',
    code: 'TR',
  },
  {
    name: { en: 'Turkmenistan', ar: 'تركمانستان' },
    dial_code: '+993',
    code: 'TM',
  },
  {
    name: { en: 'Turks and Caicos Islands', ar: 'جزر تركس وكايكوس' },
    dial_code: '+1649',
    code: 'TC',
  },
  {
    name: { en: 'Tuvalu', ar: 'توفالو' },
    dial_code: '+688',
    code: 'TV',
  },
  {
    name: { en: 'Uganda', ar: 'أوغندا' },
    dial_code: '+256',
    code: 'UG',
  },
  {
    name: { en: 'Ukraine', ar: 'أوكرانيا' },
    dial_code: '+380',
    code: 'UA',
  },
  {
    name: { en: 'United Arab Emirates', ar: 'الإمارات العربية المتحدة' },
    dial_code: '+971',
    code: 'AE',
  },
  {
    name: { en: 'United Kingdom', ar: 'المملكة المتحدة' },
    dial_code: '+44',
    code: 'GB',
  },
  {
    name: { en: 'United States', ar: 'الولايات المتحدة' },
    dial_code: '+1',
    code: 'US',
  },
  {
    name: { en: 'Uruguay', ar: 'أوروغواي' },
    dial_code: '+598',
    code: 'UY',
  },
  {
    name: { en: 'Uzbekistan', ar: 'أوزبكستان' },
    dial_code: '+998',
    code: 'UZ',
  },
  {
    name: { en: 'Vanuatu', ar: 'فانواتو' },
    dial_code: '+678',
    code: 'VU',
  },
  {
    name: { en: 'Venezuela, Bolivarian Republic of Venezuela', ar: 'فنزويلا ، جمهورية فنزويلا البوليفارية' },
    dial_code: '+58',
    code: 'VE',
  },
  {
    name: { en: 'Vietnam', ar: 'فيتنام' },
    dial_code: '+84',
    code: 'VN',
  },
  {
    name: { en: 'Virgin Islands, British', ar: 'جزر العذراء البريطانية' },
    dial_code: '+1284',
    code: 'VG',
  },
  {
    name: { en: 'Virgin Islands, U.S.', ar: 'جزر فيرجن الأمريكية' },
    dial_code: '+1340',
    code: 'VI',
  },
  {
    name: { en: 'Wallis and Futuna', ar: 'واليس وفوتونا' },
    dial_code: '+681',
    code: 'WF',
  },
  {
    name: { en: 'Yemen', ar: 'اليمن' },
    dial_code: '+967',
    code: 'YE',
  },
  {
    name: { en: 'Zambia', ar: 'زامبيا' },
    dial_code: '+260',
    code: 'ZM',
  },
  {
    name: { en: 'Zimbabwe', ar: 'زيمبابوي' },
    dial_code: '+263',
    code: 'ZW',
  },
];

export const getCountryDropDown = () => {
  let i18nextLng = localStorage.getItem('i18nextLng');
  return countries.map((el) => {
    return {
      label: el.name[i18nextLng || 'en'],
      value: el.code.toLocaleUpperCase(),
    };
  });
};

export const getCountryDropDownTel = () =>
  [...new Set(countries.map((el) => el.dial_code))].sort().map((el) => {
    return {
      label: el,
      value: el,
    };
  });

export const getCountryObj_Code = (code) => {
  return countries.find((el) => el.code.toLocaleUpperCase() === code.toLocaleUpperCase());
};

export const getCountryObj_Dial = (dial_code) => {
  return countries.find((el) => el.dial_code === dial_code);
};
