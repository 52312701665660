import { useTranslation } from 'react-i18next';
import { Collapse, Divider } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import SectionHeader from 'components/atoms/SectionHeader';
import Pagination from 'components/atoms/Pagination';
import LoadingList from 'components/molecules/LoadingList';
import EmptyList from 'components/molecules/EmptyList';
import style from './index.module.scss';
import { ReactComponent as Arrow } from 'assets/images/arrow-dark.svg';
import HelmetTag from 'components/atoms/Helmet';

const { Panel } = Collapse;

const FAQs = () => {
  const { t, i18n } = useTranslation('FAQs');
  const [loading, SetLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(undefined);
  const [FAQsData, setFAQsData] = useState([]);

  useEffect(() => {
    SetLoading(true);
    getFAQs(1);
  }, []);

  const getFAQs = (page = 1) => {
    axios.get(`/guests/faqs`, { params: { per_page: 10, page } }).then((res) => {
      setPaginationData(res.data.meta);
      setFAQsData(res.data.faqs);
      setTimeout(() => {
        SetLoading(false);
      }, 1000);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
  };

  return (
    <div className={style.FAQsWrapper}>
      <HelmetTag
        title="FAQs"
        description="Healing Grace FAQs"
        url="faqs"
        image_url="https://company-assets.fra1.cdn.digitaloceanspaces.com/healing-grace/logo.png"
      />
      <SectionHeader text={t('FAQs')} />
      <div className={style.FAQsCollapse}>
        {!loading && FAQsData.length > 0 && (
          <Collapse
            accordion
            expandIcon={({ isActive }) =>
              !isActive ? (
                i18n.language === 'ar' ? (
                  <Arrow style={{ transform: 'rotate(180deg)' }} />
                ) : (
                  <Arrow />
                )
              ) : (
                <Arrow style={{ transform: 'rotate(90deg)' }} />
              )
            }
          >
            {FAQsData.map((item) => (
              <Panel
                expandIconPosition="right"
                header={i18n.language === 'ar' ? item.question_ar : item.question_en}
                key={item.id}
                className={style.FAQsCollapseItem}
              >
                <Divider className={style.antDivider} />
                <div className={style.FAQsAnswer}>
                  <p>{i18n.language === 'ar' ? parse(item.answer_ar) : parse(item.answer_en)}</p>
                </div>
              </Panel>
            ))}
          </Collapse>
        )}
        {!loading && FAQsData.length == 0 && <EmptyList />}

        {loading && <LoadingList />}
      </div>
      {!loading && (
        <div className={style.FAQsPagination}>
          {paginationData && (
            <Pagination
              total={paginationData?.total_entries}
              current={paginationData?.current_page}
              pageSize={10}
              onChange={getFAQs}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FAQs;
