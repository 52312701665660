import { createAsyncThunk } from '@reduxjs/toolkit';
import CartAPI from './services';
import CartSummary from 'screens/Admin/Cart/sections/Summary';

export const addGiftToCart = createAsyncThunk('cart/addGiftToCart', async (data, thunkAPI) => {
  try {
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const addChildToCart = createAsyncThunk('cart/addChildToCart', async (data, thunkAPI) => {
  try {
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const changeGiftCounter = createAsyncThunk('cart/changeGiftCounter', async (data, thunkAPI) => {
  try {
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const removeGift = createAsyncThunk('cart/removeGift', async (data, thunkAPI) => {
  try {
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const assignChild = createAsyncThunk('cart/assignChild', async (data, thunkAPI) => {
  try {
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const removeChild = createAsyncThunk('cart/removeChild', async (data, thunkAPI) => {
  try {
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const changeFreq = createAsyncThunk('cart/changeFreq', async (data, thunkAPI) => {
  try {
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const changeBehalfName = createAsyncThunk('cart/changeBehalfName', async (data, thunkAPI) => {
  try {
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const checkout = createAsyncThunk('cart/checkout', async (data, thunkAPI) => {
  try {
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const submit = createAsyncThunk('cart/submit', async (data, thunkAPI) => {
  try {
    const response = await CartAPI.submitCart(data);
    return { data: response.data.url };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
