export const Partners = [
  {
    icon: '/images/lakePointePartner.png',
    link: 'https://lakepointe.church/',
  },
  {
    icon: '/images/extraSaucePartner.png',
    link: 'https://extrasauce.me/',
  },
  {
    icon: '/images/calvaryChapelPartner.png',
    link: 'https://www.northcoastcalvary.org/',
  },
  {
    icon: '/images/awanaClubsPartner.png',
    link: 'https://www.awana.org',
  },
  {
    icon: '/images/coveChurchPartner.png',
    link: 'https://www.covechurch.org/',
  },
  {
    icon: '/images/kidsAroundTheWorldPartner.png',
    link: 'https://www.kidsaroundtheworld.com/',
  },
  {
    icon: '/images/heliopolisEvangelicalChurchPartner.png',
    link: 'https://www.hechurch.org/',
  },
  {
    icon: '/images/generosityPathPartner.png',
    link: 'https://www.generositypath.org/',
  },
  {
    icon: '/images/rollingHillsChurchPartner.png',
    link: 'https://rollinghillscovenant.com/',
  },
  {
    icon: '/images/MIIPartner.png',
    link: 'https://www.mii.global/',
  },

  {
    icon: '/images/betterLifePartner.png',
    link: 'https://www.betterlifeteam.com/',
  },
];
