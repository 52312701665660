import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import englishTranslations from './en.json';
import arabicTranslations from './ar.json';
// CHANGE LANGE MUST UNDEO
const resources = {
  en: englishTranslations,
  // ar: arabicTranslations,
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    ns: ['common'],
    defaultNS: 'common',
    languages: Object.keys(resources),
    fallbackLng: 'en',
    load: 'currentOnly',
    whitelist: Object.keys(resources),
  });
export default i18n;
