import SponsorshipRedux from 'redux/sponsorships';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LettersRedux from 'redux/letters';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CtaButton from 'components/atoms/ctaButton';
import { useMedia } from 'react-use';
import { LoadingOutlined } from '@ant-design/icons';
import AttachmentUploader from './sections/AttachmentUploader';
import InputForm from 'components/atoms/InputForm';
import { Form } from 'antd';
import Alert from 'components/atoms/Alert';
import style from './index.module.scss';
import AttachmentButton from './sections/AttachmentButton';

const MAX_ATTACHMENTS_NUM = 2;

const SendLetters = () => {
  const { t, i18n } = useTranslation('letters');
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const mediumScreen = useMedia(`(max-width: 768px)`);

  const [attachmentError, setAttachmentError] = useState('');

  const [attachments, setAttachments] = useState([]);
  const [text, setText] = useState('');

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const sponsorship = useSelector(
    (state) => SponsorshipRedux.selectors.sponsorshipsSelectors.selectById(state, id) || {},
  );

  useEffect(() => {
    dispatch(SponsorshipRedux.thunks.getSponsorship({ id, includes: 'sponsoree' }));
  }, [i18n.language]);

  const handleFormChange = () => {
    const isFormValid = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(isFormValid);
  };

  const redirect = (path) => {
    history.push(path);
  };

  const addAttachment = (uploadedFile) => {
    if (attachments.length >= MAX_ATTACHMENTS_NUM) return;
    const { file } = uploadedFile;
    delete file.uid;
    setAttachments([...attachments, file]);
  };

  const clearAttachemnt = (index) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  const renderHeader = () => (
    <div className={style.lettersHeader}>
      <div className={style.lettersHeaderStart}>
        <div
          className={style.lettersHeaderStartArrow}
          onClick={() => {
            redirect(`/letters/${id}`);
          }}
        >
          <img src="/images/back-arrow.svg" />
        </div>
        <div className={style.lettersHeaderStartTitle}>{t('inbox')}</div>
      </div>
    </div>
  );

  const renderChildName = () => (
    <div className={style.lettersChildSenderWrapper}>
      <div className={style.lettersChildSender}>
        <div>{t('to')}</div>
        <div className={style.lettersChildSenderName}>
          <span>{sponsorship?.sponsoree?.code}</span>
          {sponsorship?.sponsoree?.full_name}
        </div>
      </div>

      <div className={style.lettersChildAttacment}>
        {attachments.map((attachment, index) => {
          return (
            <AttachmentButton
              attachment={attachment}
              setAttachment={() => {
                clearAttachemnt(index);
              }}
            />
          );
        })}
        {attachments.length != MAX_ATTACHMENTS_NUM && <AttachmentUploader onUpload={addAttachment} />}
      </div>
    </div>
  );

  const renderForm = () => (
    <Form form={form} onFieldsChange={handleFormChange}>
      <div className={style.letterText}>
        <InputForm
          name="text"
          type="text"
          rows={9}
          rules={[{ required: true, message: t('text-message-required') }]}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        <div className={`${style.triangleGray} ${disabled && style.triangleGrayError}`}></div>
      </div>
      <Form.Item>
        <div className={style.letterSend}>
          <CtaButton
            text={t('send')}
            type={'fill-primary-500'}
            customTextStyle={{
              fontSize: mediumScreen ? '1.063rem' : '1.375rem',
            }}
            customStyle={{
              padding: mediumScreen ? '10px 84px' : '18px 84px',
            }}
            loading={loading ? antIcon : ''}
            disabled={disabled}
            onClick={() => onsubmit()}
          />
        </div>
      </Form.Item>
    </Form>
  );

  const onsubmit = useCallback(() => {
    setLoading(true);
    var formData = new FormData();
    formData.append('sponsor_letter[text]', text);
    attachments.map((attachment, index) => {
      formData.append(`sponsor_letter[attachment_${index + 1}]`, attachment);
    });
    formData.append('sponsor_letter[sponsorship_id]', id);
    dispatch(LettersRedux.thunks.sendLetter(formData)).then((res) => {
      if (!res.error) redirect(`/letters/${id}`);
      else {
        let errors = res.payload.response.data;
        if (errors.attachment_1 || errors.attachment_2) setAttachmentError(t('attachment-error'));
      }
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [text, attachments]);

  return (
    <div className={style.lettersWrapper}>
      {renderHeader()}
      {attachmentError != '' && (
        <div className={style.alertWrapper}>
          <Alert type="alert-danger" value={attachmentError} />
        </div>
      )}
      <div className={style.letters}>
        {renderChildName()}
        {renderForm()}
      </div>
    </div>
  );
};

export default SendLetters;
