import { Route, Switch, useHistory } from 'react-router';

import DefaultLayout from 'layouts/DefaultLayout';
import { useSelector } from 'react-redux';
import Sponsors from 'redux/sponsor';

const AuthRoute = ({ path, component: Component }) => {
  const history = useHistory();
  const sponsor = useSelector(Sponsors.selectors.getCurrentSponsor);
  if (sponsor) history.push('/profile');

  return <DefaultLayout>{!sponsor && <Route exact path={path} component={Component} />}</DefaultLayout>;
};

export default AuthRoute;
