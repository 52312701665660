import PurposesRedux from 'redux/purposes';
import Sponsor from 'redux/sponsor';
import { Form as AntForm } from 'antd';
import InputForm from 'components/atoms/InputForm';
import FormCard from 'components/molecules/FormCard';
import SectionHeader from 'components/atoms/SectionHeader';
import CtaButton from 'components/atoms/ctaButton';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import { LoadingOutlined } from '@ant-design/icons';
import { getCountryCode } from 'utils/geoLocation';
import style from './index.module.scss';
import HelmetTag from 'components/atoms/Helmet';
import axios from '../../../utils/requestHelper';
import Alert from 'components/atoms/Alert';

const Donation = () => {
  const { t, i18n } = useTranslation('donation');
  const [form] = AntForm.useForm();
  const dispatch = useDispatch();

  const mediumScreen = useMedia(`(max-width: 768px)`);
  const smallScreen = useMedia(`(max-width: 576px)`);

  const purposesSelector = useSelector(PurposesRedux.selectors.purposesSelectors.selectAll);
  const sponsor = useSelector(Sponsor.selectors.getCurrentSponsor);

  const [amount, setAmount] = useState(0);
  const [transactionFees, setTransactionFees] = useState(0);
  const [currentCountryCode, setCurrentCountryCode] = useState('');

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [errors, serErrors] = useState([]);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const handleFormChange = () => {
    const isFormValid = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(isFormValid);
  };

  const getCurrentCountry = async () => {
    let code = await getCountryCode();
    setCurrentCountryCode(code);
  };

  useEffect(() => {
    dispatch(PurposesRedux.thunks.getAllPurposes());
    getCurrentCountry();
  }, []);

  const onSubmit = (values) => {
    setLoading(true);
    let _values = {
      base_amount: parseFloat(amount),
      fees_included: !!values.transaction_fees,
      remind_me: values.reminder,
      purpose_id: values.purpose,
    };
    if (!sponsor)
      _values = {
        ..._values,
        ...{
          full_name: values.name,
          email: values.email,
          phone_number: values.phone_number,
        },
      };
    axios
      .post(`/${sponsor ? 'sponsors' : 'guests'}/general_donations`, _values)
      .then((res) => {
        serErrors([]);
        window.open(res.data.url, '_self');
      })
      .catch((err) => {
        serErrors(err.response.data.messages);
      });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const parsePurposesData = () =>
    purposesSelector.map((item) => ({
      label: i18n.language == 'en' ? item.name_en : item.name_ar,
      value: item.id,
    }));

  const addTransaction = () => {
    setChecked(!checked);
    setTransactionFees(parseFloat(0.03) * parseFloat(amount));
  };

  const handleAmountChange = (value) => {
    if (value.target.value !== '') {
      setAmount(value.target.value);
      setTransactionFees((parseFloat(0.03) * parseFloat(value.target.value)).toFixed(2));
    } else {
      setAmount(0);
      setTransactionFees(0);
    }
  };

  const renderErrors = () =>
    !!errors.length && (
      <div className={style.alertWrapper}>
        <Alert type={'alert-danger'} value={errors.join('\r\n')} />
      </div>
    );

  const getCurrency = () =>
    sponsor?.payment_currency
      ? sponsor?.payment_currency === 'egp'
        ? t('egp')
        : t('usd')
      : currentCountryCode === 'EG'
      ? t('egp')
      : t('usd');

  const getCurrencSymbol = () =>
    sponsor?.payment_currency ? (
      sponsor?.payment_currency === 'egp' ? (
        <span>{t('le')}</span>
      ) : (
        <span className="dollarSymbol">{t('$')}</span>
      )
    ) : currentCountryCode === 'EG' ? (
      <span>{t('le')}</span>
    ) : (
      <span className="dollarSymbol">{t('$')}</span>
    );

  const renderDonationInfoForm = () => (
    <>
      {renderErrors()}
      <div className={style.donationForm}>
        <InputForm
          label={t('purpose')}
          name="purpose"
          type="select"
          errorMessage=""
          options={parsePurposesData()}
          placeholder={t('general-donation')}
          rules={[{ required: true, message: t('purpose-required') }]}
        />
        <div className={`${style.amountWrapper} amountWrapper`}>
          <div className={style.currency}>
            <p>{getCurrencSymbol()}</p>
          </div>
          <InputForm
            className={'donationInputNumber'}
            customStyle={{
              padding: smallScreen
                ? i18n.language == 'en'
                  ? '15px 0 15px 55px'
                  : '15px 55px 15px 0'
                : i18n.language == 'en'
                ? '15px 50px 15px 85px'
                : '15px 85px 15px 50px',
              fontSize: smallScreen ? '1.188rem' : '1.813rem',
              color: '#6bc4e8',
            }}
            label={t('amount')}
            type="number"
            min={0}
            name="amount"
            onChange={handleAmountChange}
            rules={[{ required: true, message: t('amount-required') }]}
          />
          <div className={style.currencyWrapper}>
            <p>{getCurrency()}</p>
          </div>
        </div>
        <InputForm
          name="transaction_fees"
          type="checkbox"
          title={t('add-transaction-fees')}
          onChange={addTransaction}
          checked={checked}
          rules={[{ required: false }]}
        />

        <div className={style.transactionTotalAmount}>
          <div className={style.transactionFees}>
            <label>{t('transaction-fees')}</label>
            <div className={style.transactionFeesValue}>
              <div>
                <span>{getCurrencSymbol()}</span>
              </div>
              <div>
                <p>{checked ? transactionFees : 0}</p>
              </div>
            </div>
          </div>
          <div className={style.totalAmount}>
            <label>{t('total-amount')}</label>
            <div className={style.totalAmountValue}>
              <div>
                <span>{getCurrencSymbol()}</span>
              </div>
              <div>
                <p>{checked ? parseFloat(transactionFees) + parseFloat(amount) : parseFloat(amount)}</p>
              </div>
              <div className={style.currencyWrapper}>
                <p>{getCurrency()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const renderPersonalInfoForm = () => (
    <div className={style.personalForm}>
      <InputForm
        label={t('name')}
        name="name"
        placeholder={t('name')}
        rules={[{ required: true, message: t('name-required') }]}
      />
      <InputForm
        label={t('phone-number')}
        name="phone_number"
        placeholder={t('phone-number')}
        rules={[{ required: true, message: t('phone-number-required') }]}
      />
      <InputForm
        label={t('email')}
        name="email"
        placeholder="Example@example.com"
        rules={[
          { type: 'email', message: t('email-validation-message') },
          { required: true, message: t('email-required') },
        ]}
      />

      <InputForm name="reminder" type="checkbox" title={t('reminder')} rules={[{ required: false }]} />
    </div>
  );

  return (
    <div className={style.donationWrapper}>
      <HelmetTag
        title="General Donation"
        description="Healing Grace General Donation"
        url="donate"
        image_url="https://company-assets.fra1.cdn.digitaloceanspaces.com/healing-grace/logo.png"
      />
      <div className={style.donationTitle}>
        <SectionHeader text={t('donations')} />
      </div>
      <div className={style.donation}>
        <div className={style.donationInfo}>
          <img alt="" src="/images/donate-image.png" />
          <div className={style.donationInfoText}>
            <div className={style.donationIcon}>
              <img alt="" src="/images/donation-icon.svg" />
            </div>
            <div className={style.donationText1}>
              <p>{t('donation-desc1')}</p>
            </div>
            <div className={style.donationText2}>
              <p>
                <span>
                  {t('donation-desc2')} <br />
                </span>
                {t('donation-desc3')}
              </p>
            </div>
          </div>
        </div>
        <div className={style.donationFormsWrapper}>
          <AntForm
            name="form"
            layout="vertical"
            form={form}
            onFieldsChange={handleFormChange}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            autoComplete="off"
            className={style.donationForms}
          >
            <FormCard title={t('donation-information')} form={renderDonationInfoForm()} />
            {!sponsor && <FormCard title={t('personal-information')} form={renderPersonalInfoForm()} />}
            <div className={style.submitBtn}>
              <CtaButton
                type={'fill-secondary'}
                onClickType="submit"
                full={true}
                text={t('submit')}
                customStyle={{
                  minWidth: mediumScreen ? '220px' : '275px',
                  minHeight: mediumScreen ? '64px' : '80px',
                  fontSize: mediumScreen ? '1.188rem' : '1.563rem',
                  fontWeight: 'bold',
                }}
                loading={loading ? antIcon : ''}
                disabled={disabled}
              />
            </div>
          </AntForm>
        </div>
      </div>
    </div>
  );
};

export default Donation;
