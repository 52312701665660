export const Protection = [
  {
    header: {
      en: 'PROTECTING THE MOST VULNERABLE',
      ar: 'حماية الأكثر ضعفًا',
    },
    para: {
      en: `
We believe all children should be known, loved and protected. We also believe that child protection is central to God’s heart. So we consider the love, care, and protection of children as our top priorities.

Children in poverty are at a higher risk of abuse. As an organization working to release children from poverty in Jesus' name, Healing Grace is serious about keeping children safe
            `,
      ar: `
نؤمن أن جميع الأطفال يجب أن يكونوا معروفين ومحبوبين ومحميين. نعتقد أيضًا أن حماية الطفل هي جوهر قلب الله. لذلك نعتبر محبة الأطفال ورعايتهم وحمايتهم من أولوياتنا العليا.

الأطفال الذين يعيشون في حالة فقر يتعرضون لمخاطر أكبر للإساءة. باعتبارها منظمة تعمل على تحرير الأطفال من الفقر باسم يسوع، تعتبر مؤسسة هيلينج جريس الأمر جديًا عندما يتعلق الأمر بحماية الأطفال.
            `,
    },
  },
  {
    header: {
      en: `
"Defend the weak and the fatherless; uphold the cause of the poor and the oppressed. Rescue the weak and the needy; deliver them from the hand of the wicked" (Psalm 82:3 - 4, NIV).
            `,
      ar: `
"ادفعوا الضعفاء والأيتام واحفظوا حق الفقراء والمسحوقين. انقذوا الضعفاء والمحتاجين، خلصوهم من يد الأشرار" (مزمور 82: 3-4، العهد الجديد).
            `,
    },
  },
  {
    header: {
      en: 'OUR KEY BELIEFS ON CHILD PROTECTION',
      ar: 'معتقداتنا الرئيسية في حماية الطفل',
    },
    para: {
      en: `
Our approach to and philosophy for child protection is based on the following strongly held convictions.

- No violence against children is justifiable, and all violence against children is preventable.
- Children are valuable to God, are uniquely created individuals and are worthy of dignity and respect.
- It is everyone’s job to protect children.
- Everyone involved in the protection of children should always act in the best interests of children and be held accountable if they do not.
- Parents and caregivers hold first and primary responsibility for the care and protection of children. However, we recognize that because of individual life circumstances, not every parent is equipped to effectively protect their children, and all parents need support in child protection.
- Children are both vulnerable and strong. We view children as active contributors to their own protection. Children must be empowered to fully engage in the work of child protection — their own protection and the protection of their peers. At the same time, children must be protected and cared for by the adults in their lives.
- Sponsors have a responsibility to protect children, as does anyone in a relationship with a child.
            `,
      ar: `
يستند نهجنا وفلسفتنا في حماية الطفل إلى الاعتقادات الراسخة التالية.

- لا يمكن تبرير العنف ضد الأطفال، وجميع أشكال العنف ضد الأطفال يمكن منعها.
- الأطفال ذوو قيمة لدى الله، وهم فرادى فريدون من نوعهم ويستحقون الكرامة والاحترام.
- إن حماية الأطفال هي واجب الجميع.
- يجب على جميع الأشخاص المتورطين في حماية الأطفال أن يتصرفوا دائمًا في مصلحة الأطفال وأن يكونوا مسؤولين إذا لم يفعلوا ذلك.
- يحمل الآباء ومقدمو الرعاية المسؤولية الأولى والأساسية عن رعاية وحماية الأطفال. ومع ذلك، ندرك أنه بسبب ظروف الحياة الفردية، ليس كل والد مجهزًا لحماية أطفاله بفعالية، ويحتاج جميع الآباء إلى دعم في حماية الأطفال.
- الأطفال هم مؤلفون من الضعف والقوة. نرى الأطفال كمساهمين نشطاء في حماية أنفسهم. يجب أن يكون للأطفال القوة للانخراط بشكل كامل في عمل حماية الطفل - حماية أنفسهم وحماية أقرانهم. في الوقت نفسه، يجب حماية الأطفال والاعتناء بهم من قبل البالغين في حياتهم.
- للرعاة مسؤولية تجاه حماية الأطفال، وكذلك أي شخص في علاقة مع الطفل.
            `,
    },
  },
  {
    header: {
      en: 'HOW DOES HEALING GRACE PROTECT CHILDREN?',
      ar: 'كيف تحمي هيلينج جريس الأطفال؟',
    },
    para: {
      en: `
Our approach to child protection begins with preventing abuse whenever possible. When there is reported child abuse, we intervene quickly. We seek to provide restoration and healing. We also work to empower children to be active in their own protection

We enact our approach through:
- Training for care providers in protecting children and preventing child abuse.
- Training for staff members, leaders and volunteers at our partner churches. They are trained in child protection, safe practices, and responding quickly and appropriately to reported child abuse.
- Training and accountability through our global Code of Conduct regarding children.
- Child protection training for all Healing Grace staff.

We immediately terminate the child sponsorship of anyone found on a sex offender registry. We also terminate the sponsorships of anyone living at the same address as a registered sex offender
            `,
      ar: `
يبدأ نهجنا في حماية الطفل بالوقاية من الإساءة كلما أمكن ذلك. عندما يتم الإبلاغ عن إساءة للطفل، نتدخل بسرعة. نسعى لتقديم الاستعادة والشفاء. نعمل أيضًا على تمكين الأطفال للانخراط بنشاط في حماية أنفسهم.

ننفذ نهجنا من خلال:
- تدريب مقدمي الرعاية في حماية الأطفال ومنع الإساءة للأطفال.
- تدريب أعضاء الفريق والقادة والمتطوعين في كنائسنا الشريكة. يتم تدريبهم في حماية الطفل، والممارسات الآمنة، والاستجابة بسرعة وبشكل مناسب للإبلاغ عن إساءة للأطفال.
- التدريب والمساءلة من خلال مدونة السلوك العالمية لدينا بشأن الأطفال.
- تدريب حماية الطفل لجميع موظفي هيلينج جريس.

ننهي فورًا كفالة الطفل لأي شخص يُدرج في سجل المتحرشين جنسيًا. ننهي أيضًا الكفالات لأي شخص يعيش في نفس عنوان مسجل لديهم جريمة جنسية.
            `,
    },
  },
  {
    header: {
      en: `WHAT IS A SPONSOR'S ROLE IN PROTECTING CHILDREN?`,
      ar: 'ما هو دور الكفيل في حماية الأطفال؟',
    },
    para: {
      en: `
You, your child’s family, our church partners and our staff work together to form a safety net for your sponsored child

As a sponsor, you are part of Healing Grace and part of a movement to protect children. You belong to a community of child advocates who believe in the example Jesus set.

Jesus demanded that His disciples protect children. In Matthew 18:6, the Bible is unwaveringly clear in Christ's mandate to protect children.
            `,
      ar: `
أنت، وعائلة طفلك، وشركاء الكنيسة، وموظفينا تعملون معًا لتشكيل شبكة أمان لطفلك المكفول.

ككفيل، أنت جزء من هيلينج جريس وجزء من حركة لحماية الأطفال. أنت تنتمي إلى مجتمع من المؤيدين لحقوق الطفل الذين يؤمنون بالمثال الذي وضعه يسوع.

طلب يسوع من تلاميذه حماية الأطفال. في متى 18:6، تكون الكتاب المقدس واضحًا تمامًا في ولاية المسيح بحماية الأطفال.
            `,
    },
  },
  {
    header: {
      en: `"Whoever causes one of these little ones who believe in me to sin, it would be better for him to have a huge millstone hung around his neck and to be drowned in the depths of the sea."`,
      ar: `"مَنْ يُعَثِّرْ أَحَدَ هَؤُلَاءِ الصِّغَارِ الَّذِينَ يُؤْمِنُونَ بِي، فَخَيْرٌ لَهُ أَنْ يُعَلَّقَ حَجَرُ رَحْمَةٍ حَوْلَ عُنُقِهِ وَيُغْرَقَ فِي عُمُقِ الْبَحْرِ"`,
    },
    para: {
      en: `
If your child says something in a letter that makes you feel abuse is happening or likely to happen, please <a href = "mailto: communications@hgegypt.org" style="color: #6bc4e8">contact us via email</a>. Your child, and each child we serve, is surrounded by loving Christian adults trained in child protection. They know the warning signs of child abuse and know what to do when they see them.
            `,
      ar: `
إذا كن طفلك يقول شيئًا في رسالة يجعلك تشعر بأن هناك إساءة تحدث أو من المحتمل أن تحدث، يُرجى الاتصال بنا عبر البريد الإلكتروني. فطفلك، وكل طفل نخدمه، محاط ببالغين مسيحيين محبين ومدربين على حماية الطفل. يعرفون علامات التحذير من إساءة الأطفال ويعرفون ما يجب فعله عند رؤيتها.
            `,
    },
  },
  {
    header: {
      en: `COMMUNICATING WITH YOUR SPONSORED CHILD`,
      ar: 'التواصل مع الطفل المكفول',
    },
    para: {
      en: `
Being involved in the sponsor - child communication process allows us to help protect your privacy and your sponsored child's privacy.

It also allows us to help you navigate cross - cultural sensitivities. For example, we can help you avoid unknowingly writing something inappropriate or offensive.

Communication Guidelines

- We will not deliver any letters to or from the sponsored child that contain personal contact information (e.g., address, phone, email, etc.).
- Any letters, photos or materials deemed out of line with the shared Christian values of our church partners and our statement of faith will not be given to the children.
- With more social media and internet access in low - and middle - income countries, we realize that sponsors and sponsored children may be able to connect online. The convenience this offers comes with increased risks to privacy and safety. So we do not facilitate such correspondence.
- Please do not try to contact your sponsored child via social media, even if you see that he or she has an account.
- If you are contacted by your sponsored child outside of our correspondence process (e.g., by phone, email, Facebook, Twitter, etc.), please don’t respond — even to say, "I’m sorry but I can’t talk with you in this manner." And please let us know about the contact by calling sending an email to communications@hgegypt.org
- Please do not use your full name (first and last) in letters with your sponsored child. This protects your privacy and your sponsored child’s privacy as well. Sponsored children and their families are prohibited from direct communication as a condition of participation in our programs. But it does happen from time to time. By letting us know when it does, you allow us to work with your sponsored child’s family to reinforce our communication policies.
            `,
      ar: `
الانخراط في عملية التواصل بين الكفيل والطفل يسمح لنا بحماية خصوصيتك وخصوصية الطفل المكفول.

كما يسمح لنا أيضًا بمساعدتك في التنقل بين الحساسيات الثقافية. على سبيل المثال، يمكننا مساعدتك في تجنب كتابة شيء غير مناسب أو مسيء دون أن تدرك.

إرشادات التواصل

- لن نقوم بتوصيل أي رسائل للطفل المكفول أو منه خاصة تحتوي على معلومات شخصية للاتصال (مثل العنوان، الهاتف، البريد الإلكتروني، إلخ).
- أي رسائل أو صور أو مواد تعتبر غير مناسبة مع القيم المسيحية المشتركة لشركائنا الكنسيين وبيان إيماننا لن يتم تسليمها للأطفال.
- ندرك أن هناك المزيد من وسائل التواصل الاجتماعي والوصول إلى الإنترنت في البلدان ذات الدخل المنخفض والمتوسط، ومن الممكن أن يتمكن الكفلاء والأطفال المكفولون من الاتصال عبر الإنترنت. إن الراحة التي توفرها هذه الوسيلة يرتبط ارتباطًا مباشرًا بزيادة المخاطر على الخصوصية والسلامة. لذلك لا نسهل مراسلات مثل هذه.
- يُرجى عدم محاولة الاتصال بطفلك المكفول عبر وسائل التواصل الاجتماعي، حتى إذا رأيت أن لديه حسابًا هناك.
- إذا تم الاتصال بك من قبل طفلك المكفول خارج عملية المراسلات الخاصة بنا (عبر الهاتف، البريد الإلكتروني، فيسبوك، تويتر، إلخ)، يُرجى عدم الرد، حتى تقول "أنا آسف، لا أستطيع التحدث معك بهذه الطريقة". ويرجى إبلاغنا بالاتصال عن طريق إرسال بريد إلكتروني إلى communications@hgegypt.org
- يُرجى عدم استخدام اسمك الكامل (الأول والأخير) في رسائلك مع طفلك المكفول. هذا يحمي خصوصيتك وخصوصية الطفل المكفول أيضًا. يحظر على الأطفال المكفولين وعائلاتهم التواصل المباشر كشرط للمشاركة في برامجنا. لكن ذلك يحدث من وقت لآخر. عن طريق إبلاغنا عندما يحدث ذلك، تسمح لنا بالعمل مع عائلة طفلك المكفول لتعزيز سياساتنا في التواصل.
            `,
    },
  },
  {
    header: {
      en: `VISITING YOUR SPONSORED CHILD`,
      ar: 'زيارة الطفل المكفول',
    },
    para: {
      en: `
Planning Your Visit

You may visit your sponsored child in one of two ways:
    1. A Healing Grace - organized group Sponsor Trip.
    2. A Custom Visit that has been approved and arranged through our Healing Grace office in Cairo, Egypt.

Please keep these important points in mind:
    - You have to register for your visit with Healing Grace.
    - Our National Offices and child development centers DO NOT accept unapproved visitors.
- We reserve the right to deny a visit request that would not be in the best interest of the child.

During Your Visit

While you are in - country, please adhere to these guidelines, taken from the Code of Conduct:
    - Always engage with children in open, visible areas and be sure a church or Healing Grace staff member is present.
    - Never take a child into a hotel room or other private area.
    - If you witness child abuse or think a child might be in danger, report it to your host/translator, your trip leader or your Custom Visit specialist.
    - Be sure that your appearance, language and behavior demonstrate respect for the children, their families and their culture.
    - Engage with children in age - appropriate ways, and refrain from any behaviors that might be considered inappropriate or flirtatious. For example, while asking/allowing a young child to sit on your lap might be appropriate, asking/allowing a teenage girl to do the same would not be appropriate
    - You are not allowed to visit a Healing Grace center or a child’s home outside your set itinerary. Any attempt to visit a child and/or family on your own may result in dismissal from the trip/visit and termination of your sponsorship account.
    - Do not give cash directly to any child, family or Healing Grace staff member.
    - Do not exchange any personal contact information (including email address, phone number, physical address or social media contact information) with Healing Grace - assisted children or their families
            `,
      ar: `
تخطيط زيارتك

يمكنك زيارة طفلك المكفول بإحدى الطرقين:
    1. رحلة راعي منظمة من هيلينج جريس.
    2. زيارة مخصصة وقد تم الموافقة عليها وترتيبها من خلال مكتب هيلينج جريس في القاهرة، مصر.

يرجى الأخذ في الاعتبار هذه النقاط الهامة:
    - يجب أن تسجل لزيارتك مع هيلينج جريس.
    - لا تقبل المكاتب الوطنية ومراكز تطوير الطفل الزوار غير الموافق عليهم.
    - نحتفظ بالحق في رفض طلب الزيارة إذا لم يكن في مصلحة الطفل.

أثناء زيارتك

أثناء وجودك في البلاد، يُرجى الالتزام بتلك الإرشادات، المأخوذة من مدونة السلوك:
    - تواصل دائمًا مع الأطفال في أماكن مفتوحة ومرئية، وتأكد من وجود كاهن أو موظف من هيلينج جريس موجودًا.
    - لا تأخذ أي طفل إلى غرفة فندق أو منطقة خاصة أخرى.
    - إذا شهدت إساءة للطفل أو اعتقدت أن طفلًا قد يكون في خطر، قم بالإبلاغ عن ذلك لمضيفك / مترجمك، قائد الرحلة أو مختص زيارة مخصصة.
    - تأكد من أن مظهرك ولغتك وسلوكك يظهرون الاحترام للأطفال وعائلاتهم وثقافتهم.
    - تواصل مع الأطفال بطرق مناسبة لعمرهم، وامتنع عن أي سلوك قد يُعتبر غير مناسب أو مغازلة. على سبيل المثال، قد يكون مناسبًا أن تطلب من طفل صغير أن يجلس على ركبتك، ولكن يكون غير مناسب أن تطلب من فتاة مراهقة فعل نفس الشيء.
    - لا يُسمح لك بزيارة مركز هيلينج جريس أو منزل طفل خارج جدول الزيارة المحدد لك. قد يؤدي أي محاولة لزيارة طفل و/أو عائلة بمفردك إلى فصلك من الرحلة/الزيارة وإنهاء حساب الكفالة الخاص بك.
    - لا تعط مبالغ نقدية مباشرة لأي طفل أو عائلة أو موظف من هيلينج جريس.
    - لا تبادل أي معلومات شخصية للاتصال (بما في ذلك عنوان البريد الإلكتروني، رقم الهاتف، العنوان البدني أو معلومات التواصل عبر وسائل الإعلام الاجتماعية) مع الأطفال المساعدة من قبل هيلينج جريس أو عائلاتهم.
            `,
    },
  },
  {
    header: {
      en: 'Thank you for following these important guidelines. They are meant not only to protect the children we serve, but also to protect you from wrongful allegations of misconduct.',
      ar: 'شكرا لك على اتباع هذه الإرشادات الهامة. إنها تهدف لحماية الأطفال الذين نخدمهم، وأيضا لحمايتك من اتهامات زائفة بالسلوك غير اللائق.',
    },
  },
];
