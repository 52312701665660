import ProjectsRedux from 'redux/projects';
import { useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Card from '../../../components/atoms/Card';
import { Col, Row } from 'antd';
import SectionHeader from 'components/atoms/SectionHeader';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import Pagination from 'components/atoms/Pagination';
import EmptyList from 'components/molecules/EmptyList';
import LoadingList from 'components/molecules/LoadingList';
import i18n from 'translation/i18n';
import HelmetTag from 'components/atoms/Helmet';
import style from './index.module.scss';

const pageSize = 4;
const Projects = () => {
  const { t } = useTranslation('projects');
  const dispatch = useDispatch();

  const mediumScreen = useMedia(`(max-width: 768px)`);

  const [loading, SetLoading] = useState(false);
  const [paramAttributes, setParamAttributes] = useState({ page: 1, per_page: pageSize });

  const projectsSelector = useSelector(ProjectsRedux.selectors.projectSelectors.selectAll);

  const projectsCount = useSelector(ProjectsRedux.selectors.totalEntries);
  const currenPage = useSelector(ProjectsRedux.selectors.currentPage);

  useEffect(() => {
    SetLoading(true);
    dispatch(ProjectsRedux.thunks.getAllProjects(paramAttributes));
    setTimeout(() => {
      SetLoading(false);
    }, 1000);
  }, [paramAttributes, i18n.language]);

  const onPageChange = useCallback((page, pageSize) => {
    setParamAttributes({ page: page, per_page: pageSize });
    SetLoading(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const projectList = () =>
    projectsSelector.map((el, index) => (
      <Col key={index} xl={6} lg={12} md={12} sm={24} xs={24} className={style.projectList}>
        <Card
          key={el.id}
          title={el.title}
          image={el.featured_image_url}
          textLink={t('viewProject')}
          link={`/projects/${el.id}`}
          customCardStyle={{ height: mediumScreen ? '252px' : '300px' }}
        />
      </Col>
    ));

  return (
    <div className={style.projectListWrapper}>
      <HelmetTag
        title="Projects"
        description="Healing Grace Projects"
        url="projects"
        image_url="https://company-assets.fra1.cdn.digitaloceanspaces.com/healing-grace/logo.png"
      />

      <div className={style.projectList}>
        <div className={style.projectListHeader}>
          <SectionHeader text={t('projects')} />
        </div>
        <div className={style.projectListCards}>
          <Row className={style.projectListCardsRow} gutter={[{ xl: 40, lg: 40, md: 40, sm: 0, xs: 0 }, 40]}>
            {!loading && projectsSelector.length > 0 && projectList()}
            {!loading && projectsSelector.length == 0 && <EmptyList />}
            {loading && <LoadingList />}
          </Row>
          {!loading && (
            <div className={style.projectsPagination}>
              <Pagination total={projectsCount} pageSize={pageSize} current={currenPage} onChange={onPageChange} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Projects;
