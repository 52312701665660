import { useTranslation } from 'react-i18next';
import EmptyTable from '../Table/Empty';
import style from './index.module.scss';

const DonationsCard = ({ dataSource, EmptyTablePath = '' }) => {
  const { t } = useTranslation('myTransactions');

  return (
    <div className={style.donationsCardWrapper}>
      {dataSource.length !== 0 ? (
        <div className={style.donationsCard}>
          {dataSource.map((item, index) => (
            <div key={index} className={style.card}>
              <div className={style.cardRow}>
                <div className={style.cardHeader}>{t('date')} </div>
                <div className={style.cardContent}>{item.date}</div>
              </div>
              {item.status && (
                <div className={style.cardRow}>
                  <div className={style.cardHeader}>{t('date')} </div>
                  <div className={style.cardContent}>{item.status}</div>
                </div>
              )}
              <div className={style.cardRow}>
                <div className={style.cardHeader}>{t('amount')}</div>
                <div className={`${style.cardContent} cardContent`}> {item.total_donated}</div>
              </div>
              <div className={style.cardRow}>
                <div className={style.cardHeader}>{t('purpose')}</div>
                <div className={style.cardContent}>{item.purpose}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyTable customePath={EmptyTablePath} />
      )}
    </div>
  );
};

export default DonationsCard;
