import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { getAllCards, createCard, updateDefaultCard, removeCard } from './thunks';

export const slice = createSlice({
  name: 'cards',
  initialState: adapter.getInitialState({ meta: {} }),
  extraReducers: (builder) => {
    builder.addCase(getAllCards.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.payment_methods) return;
      state.default_payment_method = payload.data.default_payment_method;
      adapter.setAll(state, data.payment_methods);
    });
    builder.addCase(createCard.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.payment_methods) return;
      adapter.addOne(state, data.payment_methods);
    });
    builder.addCase(updateDefaultCard.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.payment_methods) return;
      adapter.setAll(state, data.payment_methods);
    });
    builder.addCase(removeCard.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.payment_methods) return;
      adapter.setAll(state, data.payment_methods);
    });
  },
});
const Cards = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Cards;
