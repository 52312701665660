import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { getChild, getAllChildren } from './thunks';

export const slice = createSlice({
  name: 'children',
  initialState: adapter.getInitialState({ meta: {} }),
  extraReducers: (builder) => {
    builder.addCase(getChild.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.sponsorees) return;
      adapter.setOne(state, data.sponsorees);
    });
    builder.addCase(getChild.rejected, (state, action) => {
      let { status } = action.payload.response;
      if (status === 404) window.location.href = '/404';
    });
    builder.addCase(getAllChildren.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.sponsorees) return;
      const { arg } = meta;
      state.meta = payload.data.meta;
      if (arg?.refresh) adapter.setAll(state, data.sponsorees);
      else adapter.upsertMany(state, data.sponsorees);
    });
  },
});
const Children = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Children;
