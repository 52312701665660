import { DatePicker, Form as AntForm, Input, InputNumber } from 'antd';
import Checkbox from '../Checkbox';
import FileUploader from '../FileUploader';
import Mask from '../Mask';
import Radio from '../Radio';
import Select from '../Select';
import PhoneNumber from '../PhoneNumber';
import style from './index.module.scss';
import CommunityInput from '../CommunityInput';
import { useEffect, useState } from 'react';

const InputForm = ({
  rules = [],
  label,
  type,
  name,
  error,
  placeholder,
  customStyle,
  onChange,
  clearError,
  dependencies,
  ...props
}) => {
  const [validateStatus, setValidateStatus] = useState(error ? 'error' : undefined);
  const [displayInputError, setDisplayInputError] = useState({ enable: false, helpText: undefined });

  useEffect(() => {
    setValidateStatus(displayInputError.enable ? 'error' : undefined);
  }, [displayInputError]);

  useEffect(() => {
    if (error) setDisplayInputError({ enable: true, helpText: error });
  }, [error]);

  const renderInput = () => {
    switch (type) {
      case 'number':
        return <InputNumber style={customStyle} placeholder={placeholder} {...props} />;
      case 'password':
        return <Input.Password placeholder={placeholder} {...props} />;
      case 'text':
        return <Input.TextArea placeholder={placeholder} {...props} />;
      case 'select':
        return <Select placeholder={placeholder} onChange={onChangeWrapper} customStyle={customStyle} {...props} />;
      case 'checkbox':
        return <Checkbox {...props} />;
      case 'date':
        return <DatePicker placeholder={placeholder} {...props} />;
      case 'upload':
        return <FileUploader {...props} />;
      case 'radio':
        return <Radio {...props} />;
      case 'mask':
        return <Mask {...props} />;
      case 'community':
        return <CommunityInput placeholder={placeholder} {...props} />;
      case 'phoneNumber':
        return <PhoneNumber placeholder={placeholder} {...props} />;
      default:
        return <Input style={customStyle} placeholder={placeholder} {...props} />;
    }
  };

  const getType = () => {
    return type === 'upload' ? '' : label;
  };

  let onChangeWrapper = (value) => {
    setDisplayInputError({ enable: false, helpText: undefined });
    if (clearError) clearError(name);
    onChange && onChange(value);
  };

  return (
    <AntForm.Item
      className={style.inputForm}
      help={displayInputError.helpText}
      validateStatus={validateStatus}
      dependencies={dependencies}
      rules={[
        ...rules,
        () => ({
          validator() {
            if (displayInputError.enable) {
              return Promise.reject(displayInputError.helpText);
            }
            return Promise.resolve();
          },
        }),
      ]}
      label={getType()}
      name={name}
      onChange={onChangeWrapper}
    >
      {renderInput()}
    </AntForm.Item>
  );
};

export default InputForm;
