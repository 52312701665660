export const statementOfFaith = [
  {
    para: {
      en: 'We adhere to the statement of faith established by the World Evangelical Alliance.',
      ar: 'نحن نلتزم ببيان الإيمان المؤسس من قِبل الاتحاد الإنجيلي العالمي.',
    },
  },
  {
    header: {
      en: 'We believe in:',
      ar: 'نحن نؤمن بـ:',
    },
  },
  {
    para: {
      en: '<b>The Holy Scriptures</b> as originally given by God, divinely inspired, infallible, entirely trustworthy; and the supreme authority in all matters of faith and conduct.',
      ar: '<b>الكتب المقدسة</b> كما أُعطيت أصلاً من قِبل الله، مُلهَمة إلهياً، لا تُخطئ، تُعتبر موثوقة تمامًا؛ وهي السلطة العليا في جميع المسائل المتعلقة بالإيمان والسلوك.',
    },
  },
  {
    para: {
      en: '<b>One God,</b> eternally existent in three persons, Father, Son and Holy Spirit',
      ar: '<b>إله واحد</b> قائم أزلياً في ثلاثة أشخاص، الآب  والابن والروح القدس.',
    },
  },
  {
    para: {
      en: '<b>Our Lord Jesus Christ,</b> God manifest in the flesh, His virgin birth, His sinless human life, His divine miracles, His vicarious and atoning death, His bodily resurrection, His ascension, His mediatorial work, and his personal return in power and glory.',
      ar: '<b>ربنا يسوع المسيح،</b> الله المظهر في الجسد، ولادته البكر، حياته الإنسانية بلا خطيئة، معجزاته الإلهية، وفاته البديلة والكفارة، قيامته الجسدية، صعوده، عمله الوسيط، وعودته الشخصية بقوة ومجد.',
    },
  },
  {
    para: {
      en: '<b>The Salvation </b>of lost and sinful man through the shed blood of the Lord Jesus Christ by faith apart from works, and regeneration by the Holy Spirit.',
      ar: '<b>الخلاص</b> للإنسان الضال والخاطئ من خلال دم الرب يسوع المسيح بالإيمان دون الأعمال، والتجدد بالروح القدس.',
    },
  },
  {
    para: {
      en: '<b>The Holy Spirit</b> by whose indwelling the believer is enabled to live a holy life, to witness and work for the Lord Jesus Christ',
      ar: '<b>الروح القدس</b> الذي بسكنه في المؤمن يمكّنه من أن يحيا حياة مقدّسة وأن يكون شاهدًا ويعمل لربنا يسوع المسيح.',
    },
  },
  {
    para: {
      en: '<b>The Unity of the Spirit</b> of all true believers, the Church, the Body of Christ.',
      ar: '<b>وحدة الروح</b> بين جميع المؤمنين الحقيقيين، الكنيسة، جسد المسيح.',
    },
  },
  {
    para: {
      en: '<b>The Resurrection</b> of both the saved and the lost; they that are saved unto the resurrection of life, they that are lost unto the resurrection of damnation.',
      ar: '<b>القيامة</b> للمخلصين والمفقودين؛ أولئك الذين يخلصون إلى قيامة الحياة، وأولئك الذين يفقدون إلى قيامة الدانية.',
    },
  },
];
