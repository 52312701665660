import style from './index.module.scss';
import { Carousel as AntCarousel } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';

const Carousel = ({ pageSize = 1, children, customStyle = false }) => {
  const [data, SetData] = useState([]);

  useEffect(() => {
    SetData([]);
    for (let i = 0; i < Math.ceil(children?.length / pageSize); i++) {
      SetData((prevData) => [...prevData, ...[children.slice(i * pageSize, i * pageSize + pageSize)]]);
    }
  }, [pageSize, children]);

  return (
    <div className={style.carousel}>
      <AntCarousel>
        {data.length &&
          data.map((element, index) => {
            return (
              <div key={index} className={`carouselSingleSlide ${customStyle && 'customCarouselSingleSlide'}`}>
                {element}
              </div>
            );
          })}
      </AntCarousel>
    </div>
  );
};

export default Carousel;
