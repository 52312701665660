import { useTranslation } from 'react-i18next';
import Table from 'components/atoms/Table';
import sponsorships from 'redux/sponsorships';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { frequency, parseDate } from 'utils/date';
import { useMedia } from 'react-use';
import SponsershipCard from 'components/atoms/SponsershipCard';
import LoadingList from 'components/molecules/LoadingList';
import PriceText from 'components/atoms/PriceText';
import style from './index.module.scss';

const initParams = {
  status: 'active',
  includes: 'sponsor,sponsoree',
  per_page: 2,
};

const Sponsorships = () => {
  const { t, i18n } = useTranslation('dashboard');
  const { language } = i18n;
  const history = useHistory();
  const dispatch = useDispatch();
  const ships = useSelector(sponsorships.selectors.sponsorshipsSelectors.selectAll);
  const [filterParams, setFilterParams] = useState(initParams);
  const [loading, setLoading] = useState(false);
  const mediumScreen = useMedia(`(max-width: 768px)`);

  const redirect = (path) => {
    history.push(path);
  };

  const columnsActive = [
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
    },
    {
      title: t('ID NAME'),
      dataIndex: 'id_name',
      key: 'id_name',
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: t('Sponsored since'),
      dataIndex: 'sponsoredSince',
      key: 'sponsoredSince',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
    },
  ];

  const parsePriceCurrency = (price, currency, _frequency) => (
    <>
      {currency === 'usd' ? (
        <>
          <span>
            <PriceText price={(price * frequency[_frequency].value).toFixed(2)} currency={currency} />
          </span>{' '}
          /{frequency[_frequency].label[i18n.language]}
        </>
      ) : (
        <>
          <span>
            <PriceText price={(price * frequency[_frequency].value).toFixed(2)} currency={currency} />
          </span>{' '}
          /{frequency[_frequency].label[i18n.language]}
        </>
      )}
    </>
  );

  const getDataSourceActive = () =>
    ships
      .filter((el) => el.status === 'active')
      .map((el, index) => {
        return {
          key: index,
          image: <img className={style.childImage} src={el.sponsoree.image_url} />,
          id_name: (
            <div className={style.childIdName}>
              <span>{el.sponsoree.code}</span>
              {el.sponsoree.full_name}
            </div>
          ),
          amount: (
            <div className={style.childPrice}>
              {parsePriceCurrency(el.sponsoree.price, el.payment_currency, el.frequency)}
            </div>
          ),
          sponsoredSince: (
            <div className={style.date}>{parseDate(el.subscription_start_date ?? el.started_at, i18n.language)}</div>
          ),
          actions: (
            <div className={`${style.actions} actions`}>
              <div
                onClick={() => {
                  redirect(`/sponsorships/${el.id}`);
                }}
              >
                <img src="/images/info-blue-circle.svg" />
              </div>
            </div>
          ),
        };
      });

  useEffect(() => {
    setLoading(true);
    dispatch(sponsorships.thunks.getAllSponsorships(filterParams));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [filterParams, language]);

  return (
    <div className={style.sponsorshipsWrapper}>
      <div className={style.mySponsorshipTitle}>
        <p>{t('mySponsorships')}</p>
        <p
          className={style.mySponsorshipTitleView}
          onClick={() => {
            redirect('/sponsorships');
          }}
        >
          {t('viewAll')} <img src="/images/arrow-bold.svg" />
        </p>
      </div>
      <div className={`${style.sponsorshipsTitle} sponsorshipsTitle`}>
        {!mediumScreen ? (
          <div className="dashboardSponsorships">
            <Table
              columns={columnsActive}
              dataSource={getDataSourceActive()}
              loading={loading}
              EmptyTablePath="/dashboard-donations"
            />
          </div>
        ) : !loading ? (
          <SponsershipCard dataSource={getDataSourceActive()} EmptyTablePath="/dashboard-donations" />
        ) : (
          <LoadingList />
        )}
      </div>
    </div>
  );
};

export default Sponsorships;
