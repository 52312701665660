import axios from '../../utils/requestHelper';

const getVillage = (id) => axios.get(`/guests/villages/${id}`);

const getAllVillages = (params) => axios.get(`/guests/villages`, { params });

const VillagesAPI = {
  getVillage,
  getAllVillages,
};

export default VillagesAPI;
