import Error from 'components/molecules/ErrorComponent';
import { useTranslation } from 'react-i18next';
import style from './index.module.scss';

const NotFound = () => {
  const { t } = useTranslation('error');

  return <Error title={t('404-title')} subtitle1={t('404-subtitle-1')} subtitle2={t('404-subtitle-2')} />;
};

export default NotFound;
