import HelmetTag from 'components/atoms/Helmet';
import SectionHeader from 'components/atoms/SectionHeader';
import TextSection from 'components/atoms/TextSection';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { Terms } from 'utils/data/terms';
import style from './index.module.scss';

const TermsConditions = () => {
  const { t, i18n } = useTranslation('terms-conditions');
  const smallScreen = useMedia(`(max-width: 576px)`);

  return (
    <div className={style.termsConditionsWrapper}>
      <HelmetTag
        title="Terms and Conditions"
        description="Healing Grace Terms and Conditions"
        url="terms-conditions"
        image_url="https://company-assets.fra1.cdn.digitaloceanspaces.com/healing-grace/logo.png"
      />
      <SectionHeader text={t('main-title')} />
      {Terms.length > 0 &&
        Terms.map((term, index) => (
          <TextSection
            text={term.header?.[i18n.language]}
            value={term.para?.[i18n.language]}
            customStyle={{ marginTop: smallScreen ? '-45px' : '-30px', whiteSpace: 'break-spaces' }}
          />
        ))}
    </div>
  );
};

export default TermsConditions;
