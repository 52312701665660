import style from './index.module.scss';
import { Pagination as AntPagination } from 'antd';

const Pagination = ({ defaultCurrent, total, pageSize, current, onChange }) => {
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a>
          <img className={style.arrows} src="/images/prev-arrow.svg" />
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a>
          <img className={style.arrows} src="/images/next-arrow.svg" />
        </a>
      );
    }
    return originalElement;
  };
  return (
    <div className={style.pagination}>
      <AntPagination
        current={current}
        defaultCurrent={defaultCurrent}
        total={total}
        pageSize={pageSize}
        itemRender={itemRender}
        defaultPageSize={4}
        onChange={onChange}
        responsive={true}
        showLessItems={true}
        size={'small'}
        hideOnSinglePage={true}
      />
    </div>
  );
};

export default Pagination;
