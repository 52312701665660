import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { sendLetter, getSponsorshipLetters } from './thunks';

export const slice = createSlice({
  name: 'letters',
  initialState: adapter.getInitialState({ meta: {} }),
  extraReducers: (builder) => {
    builder.addCase(sendLetter.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.sponsor_letter) return;
      adapter.addOne(state, data.sponsor_letter);
    });

    builder.addCase(getSponsorshipLetters.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.sponsorship_letters) return;
      const { arg } = meta;
      state.meta = payload.data.meta;
      if (arg?.refresh) adapter.setAll(state, data.sponsorship_letters);
      else adapter.upsertMany(state, data.sponsorship_letters);
    });
  },
});
const Letters = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Letters;
