import Sponsor from 'redux/sponsor';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import FormCard from 'components/molecules/FormCard';
import InputForm from 'components/atoms/InputForm';
import CtaButton from 'components/atoms/ctaButton';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './index.module.scss';

const ResendConfirmation = () => {
  const { t } = useTranslation('resendConfirmation');
  const mediumScreen = useMedia(`(max-width: 768px)`);

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const handleFormChange = () => {
    const isFormValid = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(isFormValid);
  };

  const backToLogin = () => {
    setLoading(true);
    setTimeout(() => {
      history.push('/sign-in');
      setLoading(false);
    }, 1000);
  };
  const onSubmit = useCallback(
    (values) => {
      setLoading(true);
      dispatch(Sponsor.thunks.resendConfirmation(values)).then((res) => {
        setTimeout(() => {
          setEmail(res.meta.arg.email);
          setLoading(false);
        }, 1000);
      });
    },
    [dispatch, history],
  );

  const renderForm = () => (
    <div className={styles.formWrapper}>
      <div className={styles.form}>
        <Form onFinish={onSubmit} onFieldsChange={handleFormChange} form={form} layout="vertical">
          <InputForm
            label={t('email')}
            name="email"
            placeholder="Example@example.com"
            rules={[
              { type: 'email', message: t('email-validation-message') },
              { required: true, message: t('email-required-message') },
            ]}
          />
          <Form.Item>
            <div className={styles.sendBtn}>
              <CtaButton
                type={'fill-primary-500'}
                text={t('send')}
                customStyle={{
                  minWidth: mediumScreen ? '180px' : '230px',
                  minHeight: mediumScreen ? '46px' : '59px',
                  fontSize: mediumScreen ? '1.063rem' : '1.375rem',
                  fontWeight: 'normal',
                }}
                loading={loading ? antIcon : ''}
                disabled={disabled}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );

  const receiveEmail = () => (
    <div className={styles.emailWrapper}>
      <div className={styles.emailText}>
        <p>{t('email-desc')}</p>
      </div>
      <div className={styles.email}>
        <p>{email}</p>
      </div>
      <div className={styles.backToLoginBtn}>
        <CtaButton
          type={'fill-secondary'}
          onClickType="submit"
          full={true}
          text={t('back-to-login')}
          customStyle={{
            minWidth: mediumScreen ? '180px' : '230px',
            minHeight: mediumScreen ? '46px' : '59px',
            fontSize: mediumScreen ? '1.063rem' : '1.375rem',
            fontWeight: 'normal',
          }}
          onClick={backToLogin}
          loading={loading ? antIcon : ''}
        />
      </div>
    </div>
  );

  return (
    <div className={styles.resendConfirmationWrapper}>
      <FormCard
        title={email ? '' : t('resend-confirmation-text')}
        form={email ? receiveEmail() : renderForm()}
        backRoute={email ? '' : 'profile'}
        type={'full-white-color'}
        // customTitleStyle={{ fontSize: email ? '1.25rem' : '' }}
      />
    </div>
  );
};

export default ResendConfirmation;
