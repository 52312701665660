import { useTranslation } from 'react-i18next';
import EmptyTable from '../Table/Empty';
import style from './index.module.scss';

const SponsershipCard = ({ dataSource, EmptyTablePath = '' }) => {
  const { t } = useTranslation('sponsorships');

  return (
    <div className={style.donationsCardWrapper}>
      {dataSource.length !== 0 ? (
        <div className={style.donationsCard}>
          {dataSource.map((item, index) => (
            <div key={index} className={style.card}>
              {item.image && (
                <div className={style.cardRow}>
                  <div className={style.cardImageRow}>{item.image}</div>
                </div>
              )}
              {item.id_name && (
                <div className={`${style.cardRow} ${style.cardRowDataName}`}>
                  <div className={style.cardIdNameRow}>{item.id_name}</div>
                </div>
              )}
              {item.amount && (
                <div className={`${style.cardRow} ${style.cardRowData}`}>
                  <div className={style.cardHeader}>{t('Amount')} </div>
                  <div className={style.cardContent}>{item.amount}</div>
                </div>
              )}
              {item.sponsoredSince && (
                <div className={`${style.cardRow} ${style.cardRowData}`}>
                  <div className={style.cardHeader}>{t('Sponsored since')}</div>
                  <div className={style.cardContent}> {item.sponsoredSince}</div>
                </div>
              )}
              {item.nextDue && (
                <div className={`${style.cardRow} ${style.cardRowData}`}>
                  <div className={style.cardHeader}>{t('Next due')}</div>
                  <div className={style.cardContent}>{item.nextDue}</div>
                </div>
              )}
              {item.exitDate && (
                <div className={`${style.cardRow} ${style.cardRowData}`}>
                  <div className={style.cardHeader}>{t('exitDate')}</div>
                  <div className={style.cardContent}>{item.exitDate}</div>
                </div>
              )}
              {item.exitReason && (
                <div className={`${style.cardRow} ${style.cardRowData}`}>
                  <div className={style.cardHeader}>{t('exitReason')}</div>
                  <div className={style.cardContent}>{item.exitReason}</div>
                </div>
              )}
              <div className={style.cardRow}>
                <div className={style.cardActionRow}>{item.actions}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyTable customePath={EmptyTablePath} />
      )}
    </div>
  );
};

export default SponsershipCard;
