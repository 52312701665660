import { Tabs as AntdTabs } from 'antd';
import style from './index.module.scss';

const Tabs = ({ title, items, onChange }) => (
  <div className={style.tabsSection}>
    {title && <p className={style.tabsSectionTitle}>{title}</p>}
    <AntdTabs onChange={onChange}>
      {items.map((item) => (
        <AntdTabs.TabPane tab={item.label} key={item.key}>
          {item.children}
        </AntdTabs.TabPane>
      ))}
    </AntdTabs>
  </div>
);

export default Tabs;
