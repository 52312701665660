import CtaButton from 'components/atoms/ctaButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import styles from './index.module.scss';

const Hero = () => {
  const { t, i18n } = useTranslation('home');
  const history = useHistory();

  const xxlargeScreen = useMedia(`(min-width: 1200px) and (max-width: 1400px)`);
  const xlargeScreen = useMedia(`(max-width: 1200px)`);
  const largeScreen = useMedia(`(max-width: 992px)`);
  const smallScreen = useMedia(`(max-width: 576px)`);
  const xxsmall = useMedia(`(max-width: 360px)`);

  const [childGender, SetChildGender] = useState('BOTH');
  const [childLevel, SetChildLevel] = useState('');

  const onSearchExecute = () => {
    history.push({
      pathname: '/children',
      search: `?gender=${childGender.toLowerCase()}&level=${childLevel.toLowerCase()}`,
    });
  };
  return (
    <div>
      <div className={styles.heroSection}>
        <div className={styles.hero}>
          <div
            className={styles.heroBG}
            style={xlargeScreen ? { marginLeft: i18n.language == 'ar' ? '500px' : '-500px' } : {}}
          >
            <img
              src="/images/home-hero-bg.webp"
              style={i18n.language == 'ar' ? { transform: 'rotateY(180deg)' } : {}}
            />
          </div>
          <div className={styles.heroContent}>
            <div className={styles.heroContentText}>
              <div className={styles.heroContentTextHeader}>
                <h1>
                  {t('heroHeader1')}
                  <br />
                  {t('heroHeader2')}
                </h1>
              </div>
              <div className={styles.heroContentTextPar}>
                <h3>{t('heroSubHeader')}</h3>
              </div>
            </div>
            <div className={styles.heroContentChild}>
              <div className={styles.heroContentChildHeader}>
                <h6>{t('helpChildOut')}</h6>
              </div>
              <div className={styles.heroContentChildFilter}>
                <div className={styles.heroContentChildFilterPara}>
                  <p>{t('I’m interested in sponsoring a')}</p>
                </div>
                <div className={styles.heroContentChildFilterOptions}>
                  <CtaButton
                    text={t('Girl')}
                    type={childGender === 'GIRL' ? 'pill-primary-selected' : 'pill-primary'}
                    onClick={() => {
                      SetChildGender('GIRL');
                    }}
                    customStyle={
                      xxsmall
                        ? { width: '135px' }
                        : xxlargeScreen || smallScreen
                        ? { padding: '10px 22px' }
                        : { padding: '8px 35px' }
                    }
                  />
                  <CtaButton
                    text={t('Boy')}
                    type={childGender === 'BOY' ? 'pill-primary-selected' : 'pill-primary'}
                    onClick={() => {
                      SetChildGender('BOY');
                    }}
                    customStyle={
                      xxsmall
                        ? { width: '135px' }
                        : xxlargeScreen || smallScreen
                        ? { padding: '10px 22px' }
                        : { padding: '8px 35px' }
                    }
                  />
                  <CtaButton
                    text={t('Both')}
                    type={childGender === 'BOTH' ? 'pill-primary-selected' : 'pill-primary'}
                    onClick={() => {
                      SetChildGender('BOTH');
                    }}
                    customStyle={
                      xxsmall
                        ? { width: '135px' }
                        : xxlargeScreen || smallScreen
                        ? { padding: '10px 22px' }
                        : { padding: '8px 35px' }
                    }
                  />
                </div>
                <div className={`${styles.heroContentChildFilterOptions} ${styles.heroContentChildLevel}`}>
                  <div className={styles.heroContentChildFilterPara}>
                    <span>{t('In')}</span>
                  </div>
                  <div className={styles.heroContentChildLevelBtns}>
                    <CtaButton
                      text={t('School')}
                      type={childLevel === 'SCHOOL' ? 'pill-primary-selected' : 'pill-primary'}
                      onClick={() => {
                        SetChildLevel('SCHOOL');
                      }}
                      customStyle={{
                        padding: xxlargeScreen ? '10px 22px' : largeScreen ? '' : '8px 35px',
                        minWidth:
                          i18n.language === 'en'
                            ? xxsmall
                              ? '135px'
                              : ''
                            : xxlargeScreen || smallScreen
                            ? '135px'
                            : '165px',
                      }}
                    />
                    <CtaButton
                      text={t('University')}
                      type={childLevel === 'UNIVERSITY' ? 'pill-primary-selected' : 'pill-primary'}
                      onClick={() => {
                        SetChildLevel('UNIVERSITY');
                      }}
                      customStyle={{
                        padding: xxlargeScreen ? '10px 22px' : largeScreen ? '' : '8px 35px',
                        minWidth:
                          i18n.language === 'en'
                            ? xxsmall
                              ? '135px'
                              : ''
                            : xxlargeScreen || smallScreen
                            ? '135px'
                            : '165px',
                      }}
                    />
                  </div>
                </div>
                <div className={styles.heroContentChildFilterOptions}>
                  <CtaButton
                    text={t('Search')}
                    type={'fill-secondary'}
                    customTextStyle={{ marginTop: i18n.language === 'en' ? '5px' : '' }}
                    customStyle={{
                      width:
                        xxlargeScreen || xxsmall
                          ? i18n.language == 'en'
                            ? '120px'
                            : '130px'
                          : largeScreen
                          ? '180px'
                          : '220px',
                      marginTop: smallScreen ? '10px' : '',
                      fontSize: smallScreen ? '1.063rem' : xxlargeScreen ? '1.25rem' : ' 1.375rem',
                    }}
                    onClick={onSearchExecute}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
