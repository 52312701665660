import { useEffect, useState } from 'react';
import Message from 'components/atoms/Message';

const PaymentConfirmatioin = () => {
  const [isSuccess, setIsSuccess] = useState(null);

  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);

    const success = queryParams.get('payment_confirmation_success');

    if (success === 'true') {
      setIsSuccess(true);
    } else if (success === 'false') {
      setIsSuccess(false);
    }
  }, []);

  return <Message isSuccess={isSuccess} redirectUrl="/cart" translation="payment-confirmation" />;
};

export default PaymentConfirmatioin;
