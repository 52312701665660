import { Radio as AntRadio } from 'antd';
import style from './index.module.scss';

const Radio = ({ options, value, onChange, isFilter = false }) => {
  const handleChagne = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={`${style.RadioWrapper} ${isFilter ? 'RadioWrapperFilter' : 'RadioWrapperForm'}`}>
      <AntRadio.Group onChange={handleChagne} value={value}>
        {options.map(({ label, value }) => {
          return (
            <>
              <AntRadio value={value}>{label}</AntRadio>
            </>
          );
        })}
      </AntRadio.Group>
    </div>
  );
};

export default Radio;
