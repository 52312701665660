import { useState } from 'react';
import style from './index.module.scss';

const Counter = ({ value = 0, onChange, min, max }) => {
  const [counter, setCounter] = useState(value);

  const validateChange = (_value) => {
    if (min && min > _value) return false;
    if (max && max < _value) return false;
    return true;
  };

  const handleChange = (_value) => {
    if (!validateChange(_value)) return;
    setCounter(_value);
    onChange(_value);
  };

  return (
    <div className={style.counter}>
      <div
        className={style.counterDec}
        onClick={() => {
          handleChange(counter - 1);
        }}
      >
        <span>-</span>
      </div>
      <div className={style.number}>{counter}</div>
      <div
        className={style.counterIec}
        onClick={() => {
          handleChange(counter + 1);
        }}
      >
        <span>+</span>
      </div>
    </div>
  );
};

export default Counter;
