import { Checkbox as AntCheckbox } from 'antd';
import { useEffect, useState } from 'react';
import style from './index.module.scss';

const Checkbox = ({ onChange, value, name, title, disabled }) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(value);
  }, [value]);
  const handleChange = () => {
    onChange(!checked, name);
    setChecked(!checked);
  };
  return (
    <div>
      <AntCheckbox className={style.checkboxWrapper} checked={checked} disabled={disabled} onChange={handleChange}>
        {title}
      </AntCheckbox>
    </div>
  );
};

export default Checkbox;
