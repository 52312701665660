export const months = [
  { en: 'January', ar: 'يناير' },
  { en: 'February', ar: 'فبراير' },
  { en: 'March', ar: 'مارس' },
  { en: 'April', ar: 'أبريل' },
  { en: 'May', ar: 'مايو' },
  { en: 'June', ar: 'يونيو' },
  { en: 'July', ar: 'يوليو' },
  { en: 'August', ar: 'أغسطس' },
  { en: 'September', ar: 'سبتمبر' },
  { en: 'October', ar: 'اكتوبر' },
  { en: 'November', ar: 'نوفمبر' },
  { en: 'December', ar: 'ديسمبر' },
];

export const frequency = {
  monthly: {
    label: { en: 'monthly', ar: 'شهريا' },
    value: 1,
  },
  quarterly: {
    label: { en: 'quarterly', ar: 'ربع سنوى' },
    value: 3,
  },
  semi_annually: {
    label: { en: 'semi-annually', ar: 'نصف سنوى' },
    value: 6,
  },
  annually: {
    label: { en: 'annually', ar: 'سنويا' },
    value: 12,
  },
};

export const getFreqData = (locale) =>
  Object.keys(frequency)
    .map((key) => frequency[key])
    .map((el) => {
      return {
        ...el,
        ...{ label: el.label[locale] },
      };
    });

export const getFreqKey = (value) =>
  Object.keys(frequency).filter((key) => {
    return frequency[key].value == value;
  })[0];

export const parseDate = (date, locale) => {
  let _date = new Date(date);
  let day = _date.getDate() > 9 ? _date.getDate() : `0${_date.getDate()}`;
  let month = months[_date.getMonth()]?.[locale || 'en'];
  let year = _date.getFullYear();
  return `${day} ${month} ${year}`;
};
