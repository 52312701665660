import axios from '../../utils/requestHelper';

const getSponsorship = ({ id, params }) => axios.get(`/sponsors/sponsorships/${id}`, { params });

const getAllSponsorships = (params) => axios.get(`/sponsors/sponsorships`, { params });

const cancelSponsorship = (data, id) => axios.patch(`/sponsors/sponsorships/statuses/${id}`, data);

const SponsorshipsAPI = {
  getSponsorship,
  getAllSponsorships,
  cancelSponsorship,
};

export default SponsorshipsAPI;
