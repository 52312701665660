import { Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router';
import style from './index.module.scss';
import CtaButton from 'components/atoms/ctaButton';
import { useMedia } from 'react-use';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Sponsor from 'redux/sponsor';
import { useSelector } from 'react-redux';
import Cart from 'redux/cart';

const pathNamesBGWhite = ['/children/:id'];

const TopHeader = () => {
  const location = useLocation();
  const history = useHistory();
  const sponsor = useSelector(Sponsor.selectors.getCurrentSponsor);
  const totalEntries = useSelector(Cart.selectors.getCartItemCount);
  const { t, i18n } = useTranslation('header');
  const xxlargeScreen = useMedia(`(max-width: 1470px)`);
  const xlargeScreen = useMedia(`(max-width: 1239px)`);
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const [openDrawer, SetOpenDrawer] = useState(false);
  const [openAccountTab, setOpenAccountTab] = useState(false);
  const IsHomePage = matchPath(location.pathname, '/').isExact;

  const [IsBackgroundWhite, setIsBackgroundWhite] = useState(false);

  useEffect(() => {
    const data = pathNamesBGWhite.reduce(function (accumulator, currentValue) {
      return matchPath(location.pathname, currentValue)?.isExact || accumulator;
    }, false);
    setIsBackgroundWhite(data);
  }, [location.pathname]);

  useEffect(() => {
    if (!openDrawer) setOpenAccountTab(false);
  }, [openDrawer]);

  const MenuItem = [
    {
      link: '/who-we-are',
      name: t('who-we-are'),
    },
    {
      link: '/projects',
      name: t('projects'),
    },
    {
      link: '/donate',
      name: t('donate'),
    },

    {
      link: '/contact-us',
      name: t('contact-us'),
    },
  ];

  const subMenuItem = [
    {
      link: '/dashboard',
      name: t('dashboard'),
    },
    {
      link: '/profile',
      name: t('profile'),
    },
    {
      link: '/sponsorships',
      name: t('mySponsorships'),
    },
    {
      link: '/my-transactions',
      name: t('myTransactions'),
    },
    {
      link: '/my-cards',
      name: t('myCards'),
    },
    {
      link: '/gift',
      name: t('buyGift'),
    },
  ];

  const showSubMenuItem = [
    '/update-profile',
    '/update-email',
    '/change-password',
    '/sponsorships/:id',
    '/letters/:id',
    '/send-letters/:id',
    '/cart',
    '/late-dues',
    '/letters/:id',
    '/send-letters/:id',
  ];

  const ChangeLanguage = () => {
    i18n.changeLanguage(i18n.language == 'ar' ? 'en' : 'ar');
  };
  const redirectToPage = (path) => {
    history.push(path);
    SetOpenDrawer(false);
  };

  const renderHeaderInfo = () => (
    <div className={style.headerInfo}>
      <div className={style.headerInfoLeft}>
        <div className={style.headerInfoLeftImage}>
          <img src={IsHomePage && !mediumScreen ? '/images/email.svg' : '/images/email-black.svg'} />
        </div>
        <div
          className={`${style.headerInfoLeftEmail} ${IsHomePage && !mediumScreen && style.headerInfoLeftEmailWhite}`}
        >
          <a href="mailto:communications@hgegypt.org">communications@hgegypt.org</a>
        </div>
      </div>
      <div className={style.headerInfoRight}>
        <div className={style.headerInfoRightSocial}>
          <a href="https://www.facebook.com/HG.Egypt" target="_blank" rel="noreferrer">
            <img src={IsHomePage && !mediumScreen ? '/images/facebook.svg' : '/images/facebook-black.svg'} />
          </a>
        </div>
        <div className={style.headerInfoRightSocial}>
          <a
            href="https://www.instagram.com/healing_grace_egypt/?fbclid=IwAR0buvU5F9TMCxPV1KOSV6NlqjJAEz_DCU4eS8rQ7MhSMRKep7wlQ6X98h4"
            target="_blank"
            rel="noreferrer"
          >
            <img src={IsHomePage && !mediumScreen ? '/images/instgram.svg' : '/images/instgram-black.svg'} />
          </a>
        </div>
        <div className={style.headerInfoRightSocial}>
          <a href="https://wa.me/+201211966868" target="_blank" rel="noreferrer">
            <img src={IsHomePage && !mediumScreen ? '/images/whatsapp-white.svg' : '/images/whatsapp-black.svg'} />
          </a>
        </div>
      </div>
    </div>
  );

  const renderHeaderContentLinks = (mode = 'horizontal') => (
    <div className={style.headerContentLinks}>
      <Menu mode={mode} selectedKeys={[location.pathname]}>
        {MenuItem.map((el, index) => (
          <Menu.Item
            key={el.link}
            onClick={() => {
              SetOpenDrawer(false);
            }}
          >
            <Link to={el.link}> {el.name} </Link>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );

  const renderSubHeaderContentLinksMobile = () => (
    <div className={style.headerContentLinks}>
      <Menu defaultSelectedKeys={[location.pathname]}>
        {subMenuItem.map((el, index) => (
          <Menu.Item
            key={index}
            onClick={() => {
              SetOpenDrawer(false);
            }}
          >
            <Link to={el.link}> {el.name} </Link>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );

  const checkRenderSubHeader = () => {
    return (
      !!sponsor &&
      [...showSubMenuItem, ...subMenuItem.map((el) => el.link)]
        .map((link) => matchPath(location.pathname, link)?.isExact)
        .reduce((el, acc) => el || acc, false)
    );
  };

  const renderSubHeaderContentLinks = () => (
    <div className={style.subHeaderContentLinks}>
      {subMenuItem.map((el, index) => (
        <div key={index} className={el.link === location.pathname && style.subHeaderActive}>
          <Link to={el.link}> {el.name} </Link>
        </div>
      ))}
    </div>
  );

  const renderHeaderContentButtons = ({ bottom = true, top = true }) => (
    <div
      className={`${style.headerContentButtons} ${
        !mediumScreen && xlargeScreen ? style.headerContentButtonsMobile : ''
      }  ${mediumScreen ? style.headerContentButtonsMobileNav : ''} ${
        bottom ? style.headerContentButtonsMobileNavBottom : ''
      }`}
    >
      {bottom && (
        <CtaButton
          type={'fill-primary-500'}
          full={true}
          text={t('sponsor-now')}
          customStyle={{ minWidth: '155px', fontSize: xxlargeScreen ? '' : '1.25rem' }}
          onClick={() => {
            redirectToPage('/children');
          }}
        />
      )}
      {bottom && (!xlargeScreen || !sponsor) && (
        <CtaButton
          type={'border-primary-500'}
          text={sponsor ? t('dashboard') : t('login')}
          customTextStyle={{ marginTop: i18n.language == 'en' ? '3px' : '' }}
          customStyle={{
            minWidth: '155px',
            fontSize: xxlargeScreen ? '' : '1.25rem',
            lineHeight: i18n.language == 'en' ? '1.30' : '',
          }}
          onClick={() => {
            sponsor ? redirectToPage('/dashboard') : redirectToPage('/sign-in');
          }}
        />
      )}
      {top && (
        <CtaButton
          type={'border-primary-500'}
          icon="/images/heart.svg"
          customIconStyle={{ width: openDrawer ? '18px' : '' }}
          customStyle={{ padding: openDrawer ? '5px 10px' : '' }}
          counter={totalEntries}
          onClick={() => {
            redirectToPage('/cart');
          }}
        />
      )}
      {/* CHANGE LANGE MUST UNDEO */}
      {/* {top && (
        <CtaButton
          type={'border-primary-500'}
          text={i18n.language == 'ar' ? 'En' : 'ع'}
          onClick={ChangeLanguage}
          customTextStyle={{ marginTop: i18n.language === 'en' ? (openDrawer ? '-5px' : '-8px') : '5px' }}
          customStyle={{
            fontFamily: i18n.language == 'ar' ? 'Univers' : 'NotoSansArabic',
            width: i18n.language == 'ar' ? '' : openDrawer ? '40px' : '52px',
            fontSize: openDrawer ? '0.875rem' : '',
            fontWeight: 'bold',
            lineHeight: 'normal',
            padding: openDrawer ? '7px 11px' : '',
          }}
        />
      )} */}
    </div>
  );

  const renderBurgerMenu = () =>
    !openDrawer ? (
      <div className={style.headerDrawer} onClick={() => SetOpenDrawer((prev) => !prev)}>
        <div />
        <div />
        <div />
      </div>
    ) : (
      <div className={style.headerDrawer} onClick={() => SetOpenDrawer((prev) => !prev)}>
        <img src="/images/close.svg" />
      </div>
    );

  return (
    <div
      className={style.headerContainer}
      style={
        IsHomePage || (!mediumScreen && IsBackgroundWhite)
          ? { position: 'absolute', width: '100%', background: 'transparent' }
          : { background: IsBackgroundWhite ? (mediumScreen ? '#f6f8f9' : '#fff') : '#f6f8f9' }
      }
    >
      <div className={style.header}>
        {/* facebook and email */}
        {!mediumScreen && renderHeaderInfo()}

        {/* Desktop */}
        {!xlargeScreen && (
          <div className={style.headerContent}>
            <div className={style.headerContentLogo}>
              <a href="/">
                <img src="/logo.svg" />
              </a>
            </div>
            {renderHeaderContentLinks()}

            {renderHeaderContentButtons({})}
          </div>
        )}

        {/* Sub Header */}
        {!xlargeScreen && checkRenderSubHeader() && (
          <div className={style.subHeaderContent}>{renderSubHeaderContentLinks()}</div>
        )}

        {/* Mobile */}
        {xlargeScreen && (
          <div
            className={`${style.headerContent} ${style.headerContentMobile} ${
              openDrawer ? style.headerContentMobileOpen : ''
            }`}
          >
            <div className={style.headerContentMobileTop}>
              <div className={style.headerContentLogo}>
                <a href="/">
                  <img src="/logo.svg" />
                </a>
              </div>
              {!mediumScreen && renderHeaderContentButtons({})}
              {renderBurgerMenu()}
            </div>
            {mediumScreen && openDrawer && renderHeaderContentButtons({ bottom: false })}

            {openDrawer && sponsor && (
              <div
                className={style.subHeaderOpener}
                onClick={() => {
                  setOpenAccountTab(!openAccountTab);
                }}
              >
                {!openAccountTab ? (
                  <div className={style.subHeaderOpenerAccount}>
                    <div className={style.subHeaderOpenerAccountContent}>
                      <img src="/images/account.svg" />
                      <span>{t('account')}</span>
                    </div>
                    <img className={style.subHeaderOpenerAccountArrow} src="/images/next-arrow.svg" />
                  </div>
                ) : (
                  <div className={style.subHeaderOpenerBack}>
                    <img src="/images/back-arrow.svg" />
                  </div>
                )}
              </div>
            )}

            <div
              className={`${style.linkListContainer} ${sponsor ? style.linkListContainerMulti : ''} ${
                openAccountTab ? style.linkListContainerOpen : ''
              }`}
            >
              <div className={style.linkListContainerMenu}>
                {openDrawer && renderHeaderContentLinks('vertical')}
                {mediumScreen && openDrawer && renderHeaderContentButtons({ top: false })}
              </div>
              {sponsor && (
                <div className={`${style.linkListContainerMenu} ${style.linkListContainerMenuSecond}`}>
                  {openDrawer && renderSubHeaderContentLinksMobile()}
                </div>
              )}
            </div>

            {openDrawer && mediumScreen && renderHeaderInfo()}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopHeader;
