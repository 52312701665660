export const Privacy = [
  {
    para: {
      en: 'Last updated: February 28, 2022',
      ar: 'آخر تحديث: 28 فبراير 2022',
    },
  },
  {
    para: {
      en: 'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
      ar: 'تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا المتعلقة بجمع معلوماتك واستخدامها والكشف عنها عند استخدامك للخدمة وتخبرك بحقوق الخصوصية الخاصة بك وكيف يحميك القانون.',
    },
  },
  {
    para: {
      en: 'We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Template.',
      ar: 'نستخدم بياناتك الشخصية لتوفير الخدمة وتحسينها. باستخدام الخدمة ، فإنك توافق على جمع واستخدام المعلومات وفقًا لسياسة الخصوصية هذه. تم إنشاء سياسة الخصوصية هذه بمساعدة نموذج سياسة الخصوصية.',
    },
  },
  {
    header: {
      en: 'Interpretation and Definitions',
      ar: 'التفسير والتعريفات',
    },
    para: {
      en: 'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
      ar: 'الكلمات التي يتم كتابة الحرف الأول بها معاني محددة وفقًا للشروط التالية. يجب أن يكون للتعريفات التالية نفس المعنى بغض النظر عما إذا كانت تظهر بصيغة المفرد أو الجمع.',
    },
  },
  {
    header: {
      en: 'Definitions',
      ar: 'تعريفات',
    },
    para: {
      en: `
For the purposes of this Privacy Policy:
Account means a unique account created for You to access our Service or parts of our Service.
Company (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Healing Grace.
Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
Country refers to: Egypt
Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
Personal Data is any information that relates to an identified or identifiable individual.
Service refers to the Website.
Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
Website refers to WebOps, accessible from https://hgegypt.com/
You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
            `,
      ar: `
لأغراض سياسة الخصوصية هذه:
الحساب يعني حسابًا فريدًا تم إنشاؤه لك للوصول إلى خدمتنا أو أجزاء من خدمتنا.
تشير الشركة (المشار إليها باسم "الشركة" أو "نحن" أو "نحن" أو "خاصتنا" في هذه الاتفاقية) إلى Healing Grace.
ملفات تعريف الارتباط هي ملفات صغيرة يتم وضعها على جهاز الكمبيوتر الخاص بك أو جهازك المحمول أو أي جهاز آخر بواسطة موقع ويب ، وتحتوي على تفاصيل محفوظات الاستعراض الخاصة بك على هذا الموقع من بين استخداماته العديدة.
يشير البلد إلى: مصر
الجهاز يعني أي جهاز يمكنه الوصول إلى الخدمة مثل جهاز كمبيوتر أو هاتف محمول أو جهاز لوحي رقمي.
البيانات الشخصية هي أي معلومات تتعلق بفرد محدد أو يمكن التعرف عليه.
تشير الخدمة إلى موقع الويب.
مزود الخدمة يعني أي شخص طبيعي أو اعتباري يقوم بمعالجة البيانات نيابة عن الشركة. يشير إلى شركات أو أفراد تابعين لجهات خارجية توظفهم الشركة لتسهيل الخدمة ، لتقديم الخدمة نيابة عن الشركة ، لأداء الخدمات المتعلقة بالخدمة أو لمساعدة الشركة في تحليل كيفية استخدام الخدمة.
تشير بيانات الاستخدام إلى البيانات التي يتم جمعها تلقائيًا ، إما الناتجة عن استخدام الخدمة أو من البنية التحتية للخدمة نفسها (على سبيل المثال ، مدة زيارة الصفحة).
يشير موقع الويب إلى WebOps ، ويمكن الوصول إليه من https://hgegypt.com/
أنت تعني الفرد الذي يصل إلى الخدمة أو يستخدمها ، أو الشركة ، أو الكيان القانوني الآخر الذي يقوم هذا الفرد بالوصول إلى الخدمة أو استخدامها نيابة عنه ، حسب الاقتضاء.
            `,
    },
  },
  {
    header: {
      en: 'Collecting and Using Your Personal Data',
      ar: 'جمع واستخدام بياناتك الشخصية',
    },
    para: {
      en: `
While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to: Email address, First name and last name, Phone number, Usage Data
            `,
      ar: 'أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا بمعلومات تعريف شخصية معينة يمكن استخدامها للاتصال بك أو التعرف عليك. قد تتضمن معلومات التعريف الشخصية ، على سبيل المثال لا الحصر: عنوان البريد الإلكتروني والاسم الأول واسم العائلة ورقم الهاتف وبيانات الاستخدام',
    },
  },
  {
    header: {
      en: 'Usage Data',
      ar: 'بيانات الاستخدام',
    },
    para: {
      en: `
Usage Data is collected automatically when using the Service.
Usage Data may include information such as Your Device’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
            `,
      ar: `
يتم جمع بيانات الاستخدام تلقائيًا عند استخدام الخدمة.
قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت الخاص بجهازك (مثل عنوان IP) ، ونوع المتصفح ، وإصدار المتصفح ، وصفحات الخدمة التي تزورها ، ووقت وتاريخ زيارتك ، والوقت الذي تقضيه في تلك الصفحات ، والجهاز الفريد المعرفات والبيانات التشخيصية الأخرى.
عندما تصل إلى الخدمة عن طريق أو من خلال جهاز محمول ، يجوز لنا جمع معلومات معينة تلقائيًا ، بما في ذلك ، على سبيل المثال لا الحصر ، نوع الجهاز المحمول الذي تستخدمه ، والمعرف الفريد لجهازك المحمول ، وعنوان IP لجهازك المحمول ، وهاتفك المحمول نظام التشغيل ، ونوع متصفح الإنترنت على الهاتف المحمول الذي تستخدمه ، ومعرفات الجهاز الفريدة وبيانات التشخيص الأخرى.
قد نقوم أيضًا بجمع المعلومات التي يرسلها متصفحك عندما تزور خدمتنا أو عندما تصل إلى الخدمة عن طريق أو من خلال جهاز محمول.
            `,
    },
  },
  {
    header: {
      en: 'Tracking Technologies and Cookies',
      ar: 'تقنيات التتبع وملفات تعريف الارتباط',
    },
    para: {
      en: `
We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:
Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
Flash Cookies. Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read “Where can I change the settings for disabling, or deleting local shared objects?” available at https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: Use of Cookies by Free Privacy Policy.
We use both Session and Persistent Cookies for the purposes set out below:
Necessary / Essential Cookies
Type: Session Cookies
Administered by: Us
Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
Cookies Policy / Notice Acceptance Cookies
Type: Persistent Cookies
Administered by: Us
Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
Functionality Cookies
Type: Persistent Cookies
Administered by: Us
Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.
For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.
            `,
      ar: `
نحن نستخدم ملفات تعريف الارتباط وتقنيات التتبع المماثلة لتتبع النشاط على خدمتنا وتخزين معلومات معينة. تقنيات التتبع المستخدمة هي إشارات وعلامات ونصوص لجمع المعلومات وتتبعها ولتحسين خدمتنا وتحليلها. قد تشمل التقنيات التي نستخدمها ما يلي:
ملفات تعريف الارتباط أو ملفات تعريف الارتباط للمتصفح. ملف تعريف الارتباط هو ملف صغير يوضع على جهازك. يمكنك توجيه متصفحك لرفض جميع ملفات تعريف الارتباط أو للإشارة إلى وقت إرسال ملف تعريف الارتباط. ومع ذلك ، إذا كنت لا تقبل ملفات تعريف الارتباط ، فقد لا تتمكن من استخدام بعض أجزاء من خدمتنا. ما لم تقم بتعديل إعدادات المستعرض الخاص بك بحيث يرفض ملفات تعريف الارتباط ، فقد تستخدم خدمتنا ملفات تعريف الارتباط.
ملفات تعريف الارتباط الفلاش. قد تستخدم بعض ميزات خدمتنا كائنات محلية مخزنة (أو ملفات تعريف ارتباط Flash) لجمع وتخزين معلومات حول تفضيلاتك أو نشاطك على خدمتنا. لا تتم إدارة ملفات تعريف ارتباط الفلاش من خلال نفس إعدادات المتصفح مثل تلك المستخدمة لملفات تعريف الارتباط الخاصة بالمتصفح. لمزيد من المعلومات حول كيفية حذف ملفات تعريف الارتباط Flash ، يرجى قراءة "أين يمكنني تغيير الإعدادات لتعطيل أو حذف الكائنات المشتركة المحلية؟" متاح على https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabled__or_deleting_local_shared_objects_
إشارات الويب. قد تحتوي أقسام معينة من خدمتنا ورسائل البريد الإلكتروني الخاصة بنا على ملفات إلكترونية صغيرة تُعرف باسم إشارات الويب (يشار إليها أيضًا باسم gifs الواضحة وعلامات البكسل وصور gif أحادية البكسل) التي تسمح للشركة ، على سبيل المثال ، بإحصاء المستخدمين الذين زاروا تلك الصفحات أو فتح بريدًا إلكترونيًا ولإحصائيات مواقع الويب الأخرى ذات الصلة (على سبيل المثال ، تسجيل شعبية قسم معين والتحقق من سلامة النظام والخادم).
يمكن أن تكون ملفات تعريف الارتباط "ثابتة" أو "جلسة". تظل ملفات تعريف الارتباط الدائمة على جهاز الكمبيوتر الشخصي أو الجهاز المحمول الخاص بك عندما تكون غير متصل بالإنترنت ، بينما يتم حذف ملفات تعريف ارتباط الجلسة بمجرد إغلاق متصفح الويب الخاص بك. تعرف على المزيد حول ملفات تعريف الارتباط: استخدام ملفات تعريف الارتباط من خلال سياسة الخصوصية المجانية.
نستخدم كلاً من ملفات تعريف الارتباط الخاصة بالجلسة وملفات تعريف الارتباط الدائمة للأغراض الموضحة أدناه:
ملفات تعريف الارتباط الضرورية / الأساسية
النوع: ملفات تعريف الارتباط للجلسة
بإدارة: Us
الغرض: ملفات تعريف الارتباط هذه ضرورية لتزويدك بالخدمات المتاحة من خلال الموقع ولتمكينك من استخدام بعض ميزاته. إنها تساعد في مصادقة المستخدمين ومنع الاستخدام الاحتيالي لحسابات المستخدمين. بدون ملفات تعريف الارتباط هذه ، لا يمكن توفير الخدمات التي طلبتها ، ونحن نستخدم ملفات تعريف الارتباط هذه فقط لتزويدك بهذه الخدمات.
سياسة ملفات تعريف الارتباط / ملفات تعريف الارتباط بقبول الإشعار
النوع: ملفات تعريف الارتباط الدائمة
بإدارة: Us
الغرض: تحدد ملفات تعريف الارتباط هذه ما إذا كان المستخدمون قد وافقوا على استخدام ملفات تعريف الارتباط على موقع الويب.
ملفات تعريف الارتباط الوظيفية
النوع: ملفات تعريف الارتباط الدائمة
بإدارة: Us
الغرض: تسمح لنا ملفات تعريف الارتباط هذه بتذكر الاختيارات التي تقوم بها عند استخدام موقع الويب ، مثل تذكر تفاصيل تسجيل الدخول أو تفضيلات اللغة. الغرض من ملفات تعريف الارتباط هذه هو تزويدك بتجربة شخصية أكثر وتجنب الاضطرار إلى إعادة إدخال تفضيلاتك في كل مرة تستخدم فيها الموقع.
لمزيد من المعلومات حول ملفات تعريف الارتباط التي نستخدمها وخياراتك فيما يتعلق بملفات تعريف الارتباط ، يرجى زيارة سياسة ملفات تعريف الارتباط أو قسم ملفات تعريف الارتباط في سياسة الخصوصية الخاصة بنا.
            `,
    },
  },
  {
    header: {
      en: 'Use of Your Personal Data',
      ar: 'استخدام بياناتك الشخصية',
    },
    para: {
      en: `
The Company may use Personal Data for the following purposes:

To provide and maintain our Service, including to monitor the usage of our Service.
To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
To manage Your requests: To attend and manage Your requests to Us.
For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
We may share Your personal information in the following situations:

With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.
For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.
With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.
With Your consent: We may disclose Your personal information for any other purpose with Your consent.
            `,
      ar: `
يجوز للشركة استخدام البيانات الشخصية للأغراض التالية:

لتوفير وصيانة خدمتنا ، بما في ذلك مراقبة استخدام خدمتنا.
لإدارة حسابك: لإدارة تسجيلك كمستخدم للخدمة. يمكن أن تمنحك البيانات الشخصية التي تقدمها إمكانية الوصول إلى الوظائف المختلفة للخدمة المتاحة لك كمستخدم مسجل.
لتنفيذ العقد: التطوير والامتثال والتعهد بعقد الشراء للمنتجات أو الأصناف أو الخدمات التي اشتريتها أو أي عقد آخر معنا من خلال الخدمة.
للاتصال بك: للاتصال بك عن طريق البريد الإلكتروني أو المكالمات الهاتفية أو الرسائل النصية القصيرة أو غيرها من أشكال الاتصالات الإلكترونية المماثلة ، مثل الإخطارات الفورية لتطبيقات الهاتف المحمول فيما يتعلق بالتحديثات أو الاتصالات الإعلامية المتعلقة بالوظائف أو المنتجات أو الخدمات المتعاقد عليها ، بما في ذلك التحديثات الأمنية ، عند الضرورة أو المعقول لتنفيذها.
لتزويدك بالأخبار والعروض الخاصة والمعلومات العامة حول السلع والخدمات والأحداث الأخرى التي نقدمها والتي تشبه تلك التي اشتريتها بالفعل أو استفسرت عنها ما لم تكن قد اخترت عدم تلقي هذه المعلومات.
لإدارة طلباتك: لحضور وإدارة طلباتك إلينا.
بالنسبة لعمليات نقل الأعمال: قد نستخدم معلوماتك لتقييم أو إجراء عملية اندماج أو تصفية أو إعادة هيكلة أو إعادة تنظيم أو حل أو بيع أو نقل آخر لبعض أو كل أصولنا ، سواء كان ذلك كمنشأة مستمرة أو كجزء من الإفلاس أو التصفية ، أو إجراء مشابه ، حيث تكون البيانات الشخصية التي نحتفظ بها حول مستخدمي خدمتنا من بين الأصول المنقولة.
لأغراض أخرى: قد نستخدم معلوماتك لأغراض أخرى ، مثل تحليل البيانات ، وتحديد اتجاهات الاستخدام ، وتحديد فعالية حملاتنا الترويجية ولتقييم وتحسين خدماتنا ومنتجاتنا وخدماتنا وتسويقنا وتجربتك.
قد نشارك معلوماتك الشخصية في المواقف التالية:

مع مزودي الخدمة: قد نشارك معلوماتك الشخصية مع مزودي الخدمة لمراقبة وتحليل استخدام خدمتنا ، للتواصل معك.
لنقل الأعمال: يجوز لنا مشاركة معلوماتك الشخصية أو نقلها فيما يتعلق أو أثناء المفاوضات بشأن أي اندماج أو بيع أصول الشركة أو تمويل أو الاستحواذ على كل أو جزء من أعمالنا إلى شركة أخرى.
مع الشركات التابعة: قد نشارك معلوماتك مع الشركات التابعة لنا ، وفي هذه الحالة سنطلب من تلك الشركات التابعة احترام سياسة الخصوصية هذه. تشمل الشركات التابعة شركتنا الأم وأي شركات فرعية أخرى أو شركاء في المشاريع المشتركة أو شركات أخرى نسيطر عليها أو تخضع لسيطرة مشتركة معنا.
مع شركاء الأعمال: قد نشارك معلوماتك مع شركائنا في العمل لنقدم لك منتجات أو خدمات أو عروض ترويجية معينة.
مع مستخدمين آخرين: عندما تشارك معلومات شخصية أو تتفاعل بطريقة أخرى في المناطق العامة مع مستخدمين آخرين ، فقد يتم عرض هذه المعلومات من قبل جميع المستخدمين ويمكن توزيعها علنًا في الخارج.
بموافقتك: يجوز لنا الكشف عن معلوماتك الشخصية لأي غرض آخر بموافقتك.
            `,
    },
  },
  {
    header: {
      en: 'Retention of Your Personal Data',
      ar: 'الاحتفاظ ببياناتك الشخصية',
    },
    para: {
      en: `
The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            `,
      ar: `
ستحتفظ الشركة ببياناتك الشخصية فقط طالما كان ذلك ضروريًا للأغراض المنصوص عليها في سياسة الخصوصية هذه. سنحتفظ ببياناتك الشخصية ونستخدمها بالقدر اللازم للامتثال لالتزاماتنا القانونية (على سبيل المثال ، إذا كنا مطالبين بالاحتفاظ ببياناتك للامتثال للقوانين المعمول بها) ، وحل النزاعات ، وفرض اتفاقياتنا وسياساتنا القانونية.
ستحتفظ الشركة أيضًا ببيانات الاستخدام لأغراض التحليل الداخلي. يتم الاحتفاظ ببيانات الاستخدام عمومًا لفترة زمنية أقصر ، إلا في حالة استخدام هذه البيانات لتعزيز الأمن أو لتحسين وظائف خدمتنا ، أو نحن ملزمون قانونًا بالاحتفاظ بهذه البيانات لفترات زمنية أطول.
            `,
    },
  },
  {
    header: {
      en: 'Retention of Your Personal Data',
      ar: 'الاحتفاظ ببياناتك الشخصية',
    },
    para: {
      en: `
The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            `,
      ar: `
ستحتفظ الشركة ببياناتك الشخصية فقط طالما كان ذلك ضروريًا للأغراض المنصوص عليها في سياسة الخصوصية هذه. سنحتفظ ببياناتك الشخصية ونستخدمها بالقدر اللازم للامتثال لالتزاماتنا القانونية (على سبيل المثال ، إذا كنا مطالبين بالاحتفاظ ببياناتك للامتثال للقوانين المعمول بها) ، وحل النزاعات ، وفرض اتفاقياتنا وسياساتنا القانونية.
ستحتفظ الشركة أيضًا ببيانات الاستخدام لأغراض التحليل الداخلي. يتم الاحتفاظ ببيانات الاستخدام عمومًا لفترة زمنية أقصر ، إلا في حالة استخدام هذه البيانات لتعزيز الأمن أو لتحسين وظائف خدمتنا ، أو نحن ملزمون قانونًا بالاحتفاظ بهذه البيانات لفترات زمنية أطول.
            `,
    },
  },
  {
    header: {
      en: 'Transfer of Your Personal Data',
      ar: 'نقل بياناتك الشخصية',
    },
    para: {
      en: `
Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
            `,
      ar: `
تتم معالجة معلوماتك ، بما في ذلك البيانات الشخصية ، في مكاتب تشغيل الشركة وفي أي أماكن أخرى حيث توجد الأطراف المشاركة في المعالجة. وهذا يعني أنه قد يتم نقل هذه المعلومات إلى - والاحتفاظ بها - على أجهزة الكمبيوتر الموجودة خارج ولايتك أو مقاطعتك أو بلدك أو أي ولاية قضائية حكومية أخرى حيث قد تختلف قوانين حماية البيانات عن تلك الموجودة في ولايتك القضائية.
موافقتك على سياسة الخصوصية هذه متبوعة بتقديمك لهذه المعلومات يمثل موافقتك على هذا النقل.
ستتخذ الشركة جميع الخطوات اللازمة بشكل معقول لضمان التعامل مع بياناتك بشكل آمن ووفقًا لسياسة الخصوصية هذه ولن يتم نقل بياناتك الشخصية إلى منظمة أو دولة ما لم تكن هناك ضوابط كافية في مكانها بما في ذلك أمن بياناتك ومعلوماتك الشخصية الأخرى.
            `,
    },
  },
  {
    header: {
      en: 'Disclosure of Your Personal Data',
      ar: 'الإفصاح عن بياناتك الشخصية',
    },
    para: {
      en: `
Business Transactions
If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
Law enforcement
Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
Other legal requirements
The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
Comply with a legal obligation
Protect and defend the rights or property of the Company
Prevent or investigate possible wrongdoing in connection with the Service
Protect the personal safety of Users of the Service or the public
Protect against legal liability
            `,
      ar: `
المعاملات التجارية
إذا كانت الشركة متورطة في عملية دمج أو استحواذ أو بيع أصول ، فقد يتم نقل بياناتك الشخصية. سنقدم إشعارًا قبل نقل بياناتك الشخصية وإخضاعها لسياسة خصوصية مختلفة.
تطبيق القانون
في ظل ظروف معينة ، قد يُطلب من الشركة الكشف عن بياناتك الشخصية إذا طُلب منها ذلك بموجب القانون أو استجابة لطلبات صحيحة من قبل السلطات العامة (على سبيل المثال ، محكمة أو وكالة حكومية).
المتطلبات القانونية الأخرى
قد تفصح الشركة عن بياناتك الشخصية بحسن نية أن هذا الإجراء ضروري من أجل:
الامتثال لالتزام قانوني
حماية والدفاع عن حقوق أو ممتلكات الشركة
منع أو التحقيق في أي مخالفات محتملة تتعلق بالخدمة
حماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور
الحماية من المسؤولية القانونية
            `,
    },
  },
  {
    header: {
      en: 'Security of Your Personal Data',
      ar: 'أمن بياناتك الشخصية',
    },
    para: {
      en: `
The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
            `,
      ar: `
يعد أمان بياناتك الشخصية أمرًا مهمًا بالنسبة لنا ، ولكن تذكر أنه لا توجد طريقة نقل عبر الإنترنت أو طريقة تخزين إلكتروني آمنة بنسبة 100٪. بينما نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحماية بياناتك الشخصية ، لا يمكننا ضمان أمنها المطلق.
            `,
    },
  },
  {
    header: {
      en: 'Children’s Privacy',
      ar: 'خصوصية الأطفال',
    },
    para: {
      en: `
Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent’s consent before We collect and use that information.
            `,
      ar: `
لا تخاطب خدمتنا أي شخص يقل عمره عن 13 عامًا. نحن لا نجمع عن قصد معلومات تعريف شخصية من أي شخص يقل عمره عن 13 عامًا. إذا كنت أحد الوالدين أو وصيًا وكنت على علم بأن طفلك قد زودنا ببيانات شخصية ، من فضلك اتصل بنا. إذا علمنا أننا جمعنا بيانات شخصية من أي شخص يقل عمره عن 13 عامًا دون التحقق من موافقة الوالدين ، فإننا نتخذ خطوات لإزالة هذه المعلومات من خوادمنا.
إذا احتجنا إلى الاعتماد على الموافقة كأساس قانوني لمعالجة معلوماتك وطلب بلدك موافقة أحد الوالدين ، فقد نطلب موافقة والديك قبل أن نجمع هذه المعلومات ونستخدمها.
            `,
    },
  },
  {
    header: {
      en: 'Links to Other Websites',
      ar: 'روابط لمواقع أخرى',
    },
    para: {
      en: `
Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party’s site. We strongly advise You to review the Privacy Policy of every site You visit.
We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
            `,
      ar: `
قد تحتوي خدمتنا على روابط لمواقع أخرى لا نقوم بتشغيلها. إذا نقرت على رابط جهة خارجية ، فسيتم توجيهك إلى موقع الطرف الثالث. ننصحك بشدة بمراجعة سياسة الخصوصية لكل موقع تزوره.
ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية أو ممارسات أي مواقع أو خدمات خاصة بطرف ثالث.
            `,
    },
  },
  {
    header: {
      en: 'Changes to this Privacy Policy',
      ar: 'التغييرات على سياسة الخصوصية هذه',
    },
    para: {
      en: `
We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the “Last updated” date at the top of this Privacy Policy.
You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            `,
      ar: `
قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. سنخطرك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة.
سنخبرك عبر البريد الإلكتروني و / أو إشعارًا بارزًا على خدمتنا ، قبل أن يصبح التغيير ساريًا وتحديث تاريخ "آخر تحديث" في الجزء العلوي من سياسة الخصوصية هذه.
يُنصح بمراجعة سياسة الخصوصية هذه بشكل دوري لمعرفة أي تغييرات. تسري التغييرات التي تطرأ على سياسة الخصوصية هذه عند نشرها على هذه الصفحة.
            `,
    },
  },
  {
    header: {
      en: 'Contact Us',
      ar: 'اتصل بنا',
    },
    para: {
      en: `
If you have any questions about this Privacy Policy, You can contact us:
By email: communications@hgegypt.org
            `,
      ar: `
إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه ، يمكنك الاتصال بنا: عبر البريد الإلكتروني: communications@hgegypt.org
            `,
    },
  },
];
