import { Col, Row } from 'antd';
import Counter from 'components/atoms/Counter';
import CtaButton from 'components/atoms/ctaButton';
import PriceText from 'components/atoms/PriceText';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import CartRedux from 'redux/cart';
import style from './index.module.scss';
import InputForm from 'components/atoms/InputForm';
import EmptyTable from 'components/atoms/Table/Empty';
import ToolTip from 'components/atoms/toolTip';
import Sponsors from 'redux/sponsor';
import { useEffect } from 'react';
import Sponsorships from 'redux/sponsorships';

const initParams = {
  status: 'active',
  includes: 'sponsoree',
};

const GiftSection = () => {
  const { t } = useTranslation('cart');
  const dispatch = useDispatch();
  const history = useHistory();
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const gifts = useSelector(CartRedux.selectors.getCartGift);
  const sponsor = useSelector(Sponsors.selectors.getCurrentSponsor);
  const ships = useSelector(Sponsorships.selectors.sponsorshipsSelectors.selectAll);
  const cartSubmitted = useSelector(CartRedux.selectors.getCartSubmitted);

  const handleChangeCount = (gift, _count) => {
    dispatch(CartRedux.thunks.changeGiftCounter({ gift, _count }));
  };

  const handleRemoveGift = (gift) => {
    dispatch(CartRedux.thunks.removeGift(gift.giftId));
  };

  const handleAssignChild = (giftId, childId) => {
    dispatch(CartRedux.thunks.assignChild({ giftId, childId }));
  };

  const parsePurposesData = () =>
    ships
      .map((el) => el.sponsoree)
      .map(({ full_name, id }) => {
        return {
          label: full_name,
          value: id,
        };
      });

  useEffect(() => {
    dispatch(Sponsorships.thunks.getAllSponsorships(initParams));
  }, []);

  const giftRow = (gift) => (
    <div className={style.giftRow}>
      <div className={style.giftRowContent}>
        <Row className={style.giftRowContentRow}>
          <Col className={`${style.giftRowContentCol} ${style.giftRowContentColFirst}`} lg={12} xs={24}>
            <Row>
              <Col md={7} xs={10} className={style.cellLabelCol}>
                <div className={style.cellLabel}>{t('gift')}</div>
              </Col>
              <Col md={17} xs={14}>
                <div className={style.cellValue}>{gift.gift.title}</div>
              </Col>
            </Row>
            <Row>
              <Col md={7} xs={10} className={style.cellLabelCol}>
                <div className={style.cellLabel}>{t('to')}</div>
              </Col>
              <Col md={17} xs={14}>
                <div className={`${style.cellValue} cellValue`}>
                  <InputForm
                    name="child"
                    type="select"
                    options={parsePurposesData()}
                    error={cartSubmitted && !gift.childId ? t('errorChooseChildForGift') : null}
                    defaultValue={gift.childId || null}
                    placeholder={t('chooseAChild')}
                    rules={[{ required: true, message: t('needToChooseAChild') }]}
                    onChange={(e) => {
                      handleAssignChild(gift.gift.id, e);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col className={style.giftRowContentCol} lg={12} xs={24}>
            <Row>
              <Col md={12} xs={10} className={style.cellLabelCol}>
                <div className={style.cellLabelColQuantitiy}>
                  <ToolTip data={t('youCanAddAanotherGift')} />
                </div>
                <div className={style.cellLabel}>{t('quantitiy')}</div>
              </Col>
              <Col md={12} xs={14}>
                <div className={style.cellValue}>
                  <Counter
                    value={gift.count}
                    onChange={(count) => {
                      handleChangeCount(gift, count);
                    }}
                    min={1}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={10} className={style.cellLabelCol}>
                <div className={style.cellLabel}>{t('price')}</div>
              </Col>
              <Col md={12} xs={14}>
                <div className={style.cellValue}>
                  <PriceText
                    price={gift.gift[`price_${sponsor.payment_currency}`]}
                    currency={sponsor.payment_currency}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div
        className={style.giftRowDelete}
        onClick={() => {
          handleRemoveGift(gift);
        }}
      >
        <img src={'/images/trash-circle.svg'} />
      </div>
    </div>
  );

  return (
    <div className={style.GiftSection}>
      <div className={style.giftSectionHeader}>
        <ToolTip data={t('giftsAreOneTimePurchase')} />
        <h1>{t('Gifts')}</h1>
      </div>
      {gifts.length > 0 && <div className={style.giftRowWrapper}>{gifts.map((gift) => giftRow(gift))}</div>}
      {gifts.length === 0 && <EmptyTable customePath="/cart-gifts" />}
      {gifts.length > 0 && (
        <div className={style.giftSectionButton}>
          <CtaButton
            text={t('addMoreGifts')}
            type={'border-primary'}
            customStyle={{
              background: 'transparent',
              fontSize: !mediumScreen ? '1rem' : '0.75rem',
            }}
            onClick={() => {
              history.push('/gift');
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GiftSection;
