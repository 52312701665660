import EmptyTable from 'components/atoms/Table/Empty';
import style from './index.module.scss';

const PaymentCard = ({ dataSource, EmptyTablePath = '' }) => {
  return (
    <div className={style.paymentCardWrapper}>
      {dataSource.length !== 0 ? (
        dataSource.map(({ id_name, date, price }) => {
          return (
            <div className={style.paymentCard}>
              <div className={style.paymentCardData}>
                <div> {date} </div>
                <div> {price} </div>
              </div>
              <div className={style.paymentCardName}>{id_name}</div>
            </div>
          );
        })
      ) : (
        <EmptyTable customePath={EmptyTablePath} />
      )}
    </div>
  );
};

export default PaymentCard;
