import ProjectsRedux from 'redux/projects';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import CtaButton from 'components/atoms/ctaButton';
import Carousel from 'components/atoms/ Carousel';
import HelmetTag from 'components/atoms/Helmet';
import style from './index.module.scss';

const SingleProject = () => {
  const { t, i18n } = useTranslation('single-project');
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const history = useHistory();

  const dispatch = useDispatch();
  const { id } = useParams();

  const project = useSelector((state) => ProjectsRedux.selectors.projectSelectors.selectById(state, id) || {});

  const getProjectImages = () => {
    return project.images?.map((el, index) => (
      <div key={index} className={style.projectImage}>
        <img style={{ width: '100%' }} src={el.file_url} />
      </div>
    ));
  };
  useEffect(() => {
    dispatch(ProjectsRedux.thunks.getProject(id));
  }, []);

  const redirectToDonate = () => {
    history.push('/donate');
  };

  return (
    project && (
      <div className={style.singleProjectWrapper}>
        <HelmetTag
          title={project?.title}
          description={project?.description}
          url={project?.id}
          image_url={project?.featured_image_url}
        />
        <div className={style.projectTitleWrapper}>
          <div className={style.projectTitle}>{i18n.language === 'ar' ? project.title_ar : project.title_en}</div>
          <div className={style.projectBtn}>
            <CtaButton
              type={'fill-secondary'}
              text={t('donate-now')}
              onClick={redirectToDonate}
              customStyle={{
                minWidth: mediumScreen ? '220px' : '275px',
                minHeight: mediumScreen ? '64px' : '80px',
                fontSize: mediumScreen ? '1.188rem' : '1.563rem',
                fontWeight: 'bold',
              }}
            />
          </div>
        </div>
        <div className={style.projectContentWrapper}>
          <div className={style.projectImages + ' projectImages'}>
            <Carousel customStyle={true}>{getProjectImages()}</Carousel>
          </div>
          <div
            className={style.projectDesc}
            dangerouslySetInnerHTML={{
              __html: i18n.language === 'ar' ? project.description_ar : project.description_en,
            }}
          />
        </div>
      </div>
    )
  );
};

export default SingleProject;
