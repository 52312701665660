import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const cardsSelectors = {
  ...adapter.getSelectors((state) => state.cards),
};

export const getDefaultPaymentMethod = createSelector(
  (state) => state?.cards,
  (cards) => cards?.default_payment_method,
);
