import { Col, Row } from 'antd';
import Counter from 'components/atoms/Counter';
import CtaButton from 'components/atoms/ctaButton';
import PriceText from 'components/atoms/PriceText';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import CartRedux from 'redux/cart';
import style from './index.module.scss';
import InputForm from 'components/atoms/InputForm';
import EmptyTable from 'components/atoms/Table/Empty';
import ToolTip from 'components/atoms/toolTip';
import Sponsors from 'redux/sponsor';
import Select from 'components/atoms/Select';
import { getFreqData } from 'utils/date';

const ChildrenSection = () => {
  const { t, i18n } = useTranslation('cart');
  const dispatch = useDispatch();
  const history = useHistory();
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const children = useSelector(CartRedux.selectors.getCartChildren);
  const sponsor = useSelector(Sponsors.selectors.getCurrentSponsor);
  const freq = useSelector(CartRedux.selectors.getCartFreq);

  const handleChangeFreq = (freq) => {
    dispatch(CartRedux.thunks.changeFreq(freq));
  };

  const handleChangeBehalfName = (childId, name) => {
    dispatch(CartRedux.thunks.changeBehalfName({ childId, name }));
  };

  const handleRemoveChild = (child) => {
    dispatch(CartRedux.thunks.removeChild(child.id));
  };

  const childRow = (child) => (
    <div className={style.giftRow}>
      <div className={style.giftRowContent}>
        <Row className={style.giftRowContentRow}>
          <Col className={style.giftRowContentCol} lg={12} xs={24}>
            <Row>
              <Col md={7} xs={10} className={style.cellLabelCol}>
                <div className={style.cellLabel}>{t('ID Name')}</div>
              </Col>
              <Col md={17} xs={14}>
                <div className={`${style.cellValue} ${style.cellValueName}`}>
                  <div>{child.code}</div>
                  <div>{child.full_name}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={7} xs={10} className={style.cellLabelCol}>
                <div className={style.cellLabel}>{t('Price')}</div>
              </Col>
              <Col md={17} xs={14}>
                <div className={style.cellValue}>
                  <PriceText price={child[`price_${sponsor.payment_currency}`]} currency={sponsor.payment_currency} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col className={style.giftRowContentCol} lg={12} xs={24}>
            <Row>
              <Col md={12} xs={10} className={`${style.cellLabelCol} ${style.cellLabelColBehalf}`}>
                <div className={style.cellLabelColBehalfToolTip}>
                  <ToolTip
                    data={t('youCanSponsorChild')}
                    dataStyle={{
                      marginTop: '40px',
                    }}
                  />
                </div>
                <div className={style.cellLabel}>
                  <div>{t('OnBehalfOf')}</div>
                  <div className={style.cellLabelOptional}>({t('Optional')})</div>
                </div>
              </Col>
              <Col md={12} xs={14}>
                <div className={`${style.cellValue} cellValue`}>
                  <InputForm
                    name="name"
                    placeholder={t('name')}
                    customStyle={{
                      padding: '20px 50px !important',
                    }}
                    defaultValue={child.behalfName || ''}
                    onChange={(e) => {
                      handleChangeBehalfName(child.id, e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div
        className={style.giftRowDelete}
        onClick={() => {
          handleRemoveChild(child);
        }}
      >
        <img src={'/images/trash-circle.svg'} />
      </div>
    </div>
  );

  return (
    <div className={style.GiftSection}>
      <div className={style.giftSectionHeader}>
        <h1>{t('Children')}</h1>
        {children.length > 0 && (
          <div className={`${style.freqSelect} freqSelect cellValue`}>
            <InputForm
              name="freq"
              type="select"
              defaultValue={freq}
              options={getFreqData(i18n.language)}
              onChange={(value) => {
                handleChangeFreq(value);
              }}
            />
          </div>
        )}
      </div>
      {children.length > 0 && <div className={style.giftRowWrapper}>{children.map((child) => childRow(child))}</div>}
      {children.length === 0 && (
        <div className={style.errorWrapper}>
          <EmptyTable customePath="/cart-children" />
        </div>
      )}
      <div className={style.giftSectionButton}>
        {children.length > 0 && (
          <CtaButton
            text={t('addAnotherChild')}
            type={'border-primary'}
            customStyle={{
              background: 'transparent',
              fontSize: !mediumScreen ? '1rem' : '0.75rem',
            }}
            onClick={() => {
              history.push('/children');
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ChildrenSection;
