import PaymentCards from 'redux/cards';
import CtaButton from 'components/atoms/ctaButton';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { LoadingOutlined } from '@ant-design/icons';
import style from './index.module.scss';

const EmptyTable = ({ customePath = '' }) => {
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation('emptyTable');
  const { pathname } = location;

  const [loading, setLoading] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const addNewCard = useCallback(() => {
    setLoading(true);
    dispatch(PaymentCards.thunks.createCard()).then((res) => {
      window.open(res.payload.data.url, '_self');
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, [dispatch]);

  const data = {
    '/sponsorships': {
      desc1: t('sponsorships-error-desc-1'),
      desc2: t('sponsorships-error-desc-2'),
      buttonText: t('sponsorships-donate-now'),
      buttonAction: () => {
        redirect('/children');
      },
    },
    '/previous-sponsorships': {
      desc1: t('previous-sponsorships-error-desc-1'),
      desc2: t('previous-sponsorships-error-desc-2'),
      buttonText: t('sponsorships-donate-now'),
      buttonAction: () => {
        redirect('/children');
      },
    },
    '/my-transactions': {
      desc1: t('my-Transactions-error-desc-1'),
      desc2: t('my-Transactions-error-desc-2'),
      buttonText: t('my-Transactions-donate-now'),
      buttonAction: () => {
        redirect('/donate');
      },
    },
    '/dashboard-donations': {
      desc1: t('noData'),
    },
    '/cart-children': {
      desc1: t('noItemInYourCart'),
      desc2: t('cart-children-error-desc-2'),
      buttonText: t('sponsorships-donate-now'),
      buttonAction: () => {
        redirect('/children');
      },
    },
    '/cart-gifts': {
      desc1: t('noItemInYourCart'),
      buttonText: t('buyAGift'),
      buttonAction: () => {
        redirect('/gift');
      },
    },
    '/letters': {
      desc1: t('letters-desc-1'),
      desc2: t('letters-desc-2'),
    },
    '/my-cards': {
      desc1: t('my-cards-error-desc-1'),
      desc2: t('my-cards-error-desc-2'),
      buttonText: t('add-new-card'),
      buttonAction: () => {
        addNewCard();
      },
    },
  };

  const objectData = data[customePath || pathname];

  const redirect = (path) => {
    setLoading(true);
    setTimeout(() => {
      history.push(path);
      setLoading(false);
    }, 1000);
  };

  return (
    <div className={style.errorWrapper}>
      <div className={style.errorImage}>
        <img src="/images/no-data-img.svg" />
      </div>
      <div className={style.desc1}>
        <p>{objectData.desc1}</p>
      </div>
      {objectData.desc2 && (
        <div className={style.desc2}>
          <p>{objectData.desc2}</p>
        </div>
      )}
      {objectData.buttonText && (
        <div className={style.donateBtn}>
          <CtaButton
            type={'fill-secondary'}
            text={objectData.buttonText}
            onClick={objectData.buttonAction}
            customStyle={{
              minWidth: mediumScreen ? '220px' : '275px',
              minHeight: mediumScreen ? '64px' : '80px',
              fontSize: mediumScreen ? '1.125rem' : '1.563rem',
              fontWeight: 'bold',
            }}
            loading={loading ? antIcon : ''}
          />
        </div>
      )}
    </div>
  );
};

export default EmptyTable;
