import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const giftSelectors = {
  ...adapter.getSelectors((state) => state.cart),
};

export const getCart = createSelector(
  (state) => state?.cart,
  (cart) => cart.cart,
);

export const getCartItemCount = createSelector(
  (state) => state?.cart,
  (cart) => [...cart.cart.children, ...cart.cart.gifts].flat().length,
);

export const getCartChildren = createSelector(
  (state) => state?.cart,
  (cart) => cart.cart.children,
);

export const getCartGift = createSelector(
  (state) => state?.cart,
  (cart) => cart.cart.gifts,
);

export const getCartFreq = createSelector(
  (state) => state?.cart,
  (cart) => cart.cart.freq,
);

export const getCartSubmitted = createSelector(
  (state) => state?.cart,
  (cart) => cart.cart.submitted,
);

export const getCartErrors = createSelector(
  (state) => state?.cart,
  (cart) => cart.cart.errors || [],
);
