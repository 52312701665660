import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { getAllDues, getLateDues, payLateDues, addIdsToCheckout } from './thunks';

export const slice = createSlice({
  name: 'dues',
  initialState: adapter.getInitialState({ meta: {}, selectedCheckoutIds: [], errors: {} }),
  reducers: {
    clearErrors(state) {
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDues.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.dues) return;
      state.meta = payload.data.meta;
      adapter.setAll(state, data.dues);
    });
    builder.addCase(getLateDues.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.dues) return;
      state.meta = payload.data.meta;
      adapter.setAll(state, data.dues);
    });
    builder.addCase(payLateDues.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.dues) return;
      adapter.addOne(state, data.dues);
    });
    builder.addCase(payLateDues.rejected, (state, action) => {
      let { messages } = action.payload.response.data;
      state.errors = messages;
    });
    builder.addCase(addIdsToCheckout.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      state.selectedCheckoutIds.push(data);
    });
  },
});

const Dues = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Dues;
