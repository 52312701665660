import IMask from 'imask';

export const DateMask = {
  mask: 'dd / mm / yyyy',
  blocks: {
    dd: { mask: IMask.MaskedRange, from: 1, to: 31, placeholderChar: 'd', maxLength: 2 },
    mm: { mask: IMask.MaskedRange, from: 1, to: 12, placeholderChar: 'm', maxLength: 2 },
    yyyy: { mask: IMask.MaskedRange, from: 1900, to: new Date().getFullYear(), placeholderChar: 'y' },
  },
  lazy: false,
  autofix: true,
};

export const PhoneNumberMask = {
  mask: '1xxxxxxxxx',
  blocks: {
    x: { mask: IMask.MaskedRange, from: 0, to: 9, placeholderChar: 'x' },
  },
  lazy: false,
  autofix: true,
};
