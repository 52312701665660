import Error from 'components/molecules/ErrorComponent';
import { useTranslation } from 'react-i18next';
import style from './index.module.scss';

const ServerError = () => {
  const { t } = useTranslation('error');

  return <Error title={t('500-title')} subtitle1={t('500-subtitle-1')} subtitle2={t('500-subtitle-2')} />;
};

export default ServerError;
