import { createAsyncThunk } from '@reduxjs/toolkit';
import ProjectsAPI from './services';

export const getProject = createAsyncThunk('projects/getProject', async (id, thunkAPI) => {
  try {
    const response = await ProjectsAPI.getProject(id);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAllProjects = createAsyncThunk('projects/getAllProjects', async (params, thunkAPI) => {
  try {
    const response = await ProjectsAPI.getAllProjects(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
