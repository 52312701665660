import style from './index.module.scss';

const Card = ({ image, title, subTitle, textLink, link, textButton, onClickButton, customCardStyle, badge }) => {
  return (
    <div className={style.cardWrapper}>
      <div className={style.card}>
        {badge && <div className={style.cardBadge}>{badge}</div>}
        <div className={style.cardImage} style={customCardStyle}>
          <img src={image} />
        </div>
        <div className={`${style.cardInfo} ${!textButton ? style.cardInfoWithSpace : style.cardInfoWithoutSpace}`}>
          {title && <div className={style.cardInfoTitle}>{title}</div>}
          {subTitle && <div className={style.cardInfoSubTitle}>{subTitle}</div>}
          {textLink && link && (
            <div className={style.cardInfoLink}>
              <a href={link}>
                <p>{textLink}</p>
                <img src="/images/arrow-blue-right.svg" />
              </a>
            </div>
          )}
        </div>
        {textButton && (
          <div className={style.cardButton}>
            <button onClick={onClickButton}>{textButton}</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
