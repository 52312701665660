import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const lettersSelectors = {
  ...adapter.getSelectors((state) => state.letters),
};

export const getMeta = createSelector(
  (state) => state?.letters,
  (letters) => letters?.meta,
);

export const totalEntries = createSelector(
  (state) => state?.letters?.meta,
  (meta) => meta?.total_entries,
);

export const currentPage = createSelector(
  (state) => state?.letters?.meta,
  (meta) => meta?.current_page,
);

export const nextPage = createSelector(
  (state) => state?.letters?.meta,
  (meta) => meta?.next_page,
);
