import { useTranslation } from 'react-i18next';
import { Row, Col, Statistic as AntStatistic } from 'antd';
import CountUp from 'react-countup';
import style from './index.module.scss';
import { useEffect, useState } from 'react';
import axios from 'utils/requestHelper';

const Statistic = () => {
  const { t } = useTranslation('home');
  const [sponsoredConter, setSponsoredConter] = useState(0);
  const [loading, setLoading] = useState(false);
  const formatter = (value) => <CountUp end={value} duration={2} delay={1} />;
  const statisticList = [
    {
      name: t('sponsored'),
      number: sponsoredConter,
      icon: '/images/heart-white.svg',
    },
    {
      name: t('villages'),
      number: 31,
      icon: '/images/location.svg',
    },
    {
      name: t('water-filters'),
      number: 34,
      icon: '/images/water.svg',
    },
    {
      name: t('educational-centers'),
      number: 14,
      icon: '/images/education.svg',
    },
  ];

  useEffect(() => {
    setLoading(true);
    axios.get(`/guests/home`).then((res) => {
      setSponsoredConter(res.data.sponsoree_counter);
      setLoading(false);
    });
  }, []);

  return (
    <div className={style.statisticWrapper}>
      <Row className={style.statistic}>
        {!loading &&
          statisticList.map((item, index) => {
            return (
              <Col key={index} xl={6} lg={12} md={12} sm={12} xs={24} className={style.statisticInfo}>
                <div className={style.statisticInfoNumber}>
                  <AntStatistic value={item.number} formatter={formatter} />
                </div>
                <div className={style.statisticInfoName}>
                  <div className={style.statisticIcon}>
                    <img alt="" src={item.icon} />
                  </div>
                  <div className={style.statisticName}>{item.name}</div>
                </div>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default Statistic;
