import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const projectSelectors = {
  ...adapter.getSelectors((state) => state.projects),
};

export const getMeta = createSelector(
  (state) => state?.projects,
  (projects) => projects?.meta,
);

export const totalEntries = createSelector(
  (state) => state?.projects?.meta,
  (meta) => meta?.total_entries,
);

export const currentPage = createSelector(
  (state) => state?.projects?.meta,
  (meta) => meta?.current_page,
);
