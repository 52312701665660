import style from './index.module.scss';

const InfoCard = ({ title, info, customWrapper = '' }) => (
  <div className={`${style.infoCardWrapper} infoCardWrapper ${customWrapper}`}>
    <div className={`${style.infoCard} infoCard`}>
      <div className={style.infoCardTitle}>
        <h1>{title}</h1>
      </div>
      <div className={style.infoCardData}>{info}</div>
    </div>
  </div>
);

export default InfoCard;
