import { Select as AntSelect } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './index.module.scss';

const Select = ({
  value,
  options,
  customStyle,
  placeholder,
  onChange,
  parse,
  defaultValue,
  searchable = false,
  dropdownMatchSelectWidth = true,
}) => {
  const { t } = useTranslation('select');

  const getValue = () => {
    if (parse && value) return parse(value);
    else return value;
  };

  return (
    <div className={style.selectWrapper}>
      <AntSelect
        className={style.select}
        value={getValue()}
        placeholder={placeholder ? placeholder : t('select')}
        style={customStyle}
        onChange={onChange}
        options={options}
        defaultValue={defaultValue}
        showSearch={searchable}
        filterOption={(inputValue, option) => option.label.toLowerCase().includes(inputValue.toLowerCase())}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      />
    </div>
  );
};

export default Select;
