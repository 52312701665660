import style from './index.module.scss';
import { Image, Upload } from 'antd';
import { useEffect, useState } from 'react';

const FileUploader = ({ value, onChange }) => {
  const [fileValue, SetFileValue] = useState(value);

  useEffect(() => {
    if (!value) SetFileValue('');
    else if (typeof value === 'string') SetFileValue(value);
    else {
      getBase64(value, (imageUrl) => {
        SetFileValue(imageUrl);
      });
    }
  }, [value]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const hasValue = () => {
    return !!fileValue;
  };

  const getImageSrc = () => {
    return hasValue() ? fileValue : '/images/profile.svg';
  };

  const removeFile = () => {
    onChange(false);
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    } else {
      getBase64(info.file.originFileObj, (imageUrl) => {
        delete info.file.originFileObj.uid;
        onChange(info.file.originFileObj);
        SetFileValue(imageUrl);
      });
    }
  };

  return (
    <div className={style.uploaderWrapper}>
      <div className={style.uploader}>
        <div className={style.uploaderImage}>
          {hasValue() && <Image src={getImageSrc()} />}
          <Upload onChange={handleChange} showUploadList={false}>
            {!hasValue() && <img className={style.uploaderImageDefault} src="/images/profile.svg" />}

            {!hasValue() && (
              <div className={style.uploaderAdd}>
                <img src={'/images/add-blue.svg'} />
              </div>
            )}
          </Upload>
        </div>
        {hasValue() && (
          <div onClick={removeFile} className={style.uploaderRemove}>
            <img src={'/images/trash-blue.svg'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
