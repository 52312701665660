import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { getAllTransactions } from './thunks';

export const slice = createSlice({
  name: 'transactions',
  initialState: adapter.getInitialState({ meta: {} }),
  extraReducers: (builder) => {
    builder.addCase(getAllTransactions.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.invoiceable_transactions) return;
      state.meta = payload.data.meta;
      adapter.setAll(state, data.invoiceable_transactions);
    });
  },
});
const Transactions = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Transactions;
