import style from './index.module.scss';
import { Upload } from 'antd';
import CtaButton from 'components/atoms/ctaButton';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';

const AttachmentButton = ({ attachment, setAttachment }) => {
  const { t, i18n } = useTranslation('letters');
  const mediumScreen = useMedia(`(max-width: 768px)`);
  return (
    <div className={style.lettersChildAttacmentFile}>
      <CtaButton
        text={attachment.name}
        type={'fill-secondary-500'}
        customTextStyle={{
          display: 'inline',
          padding: '0px 10px',
          fontSize: mediumScreen ? '1rem' : '1.25rem',
        }}
        icon={'/images/trash-gray.svg'}
        customIconStyle={mediumScreen ? { width: '40px' } : { width: '56px' }}
        onClick={() => {
          setAttachment('');
        }}
        customStyle={{
          padding: '3px 0px',
          maxWidth: mediumScreen ? 'auto' : '175px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: mediumScreen ? '100%' : '',
        }}
      />
    </div>
  );
};

export default AttachmentButton;
