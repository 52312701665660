import { createAsyncThunk } from '@reduxjs/toolkit';
import ChildrenAPI from './services';

export const getChild = createAsyncThunk('children/getChild', async (id, thunkAPI) => {
  try {
    const response = await ChildrenAPI.getChild(id);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAllChildren = createAsyncThunk('children/getAllChildren', async (params, thunkAPI) => {
  try {
    const response = await ChildrenAPI.getAllChildren(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
