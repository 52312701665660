import { useEffect, useState } from 'react';
import Message from 'components/atoms/Message';

const CardConfirmation = () => {
  const [isSuccess, setIsSuccess] = useState(null);

  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);

    const success = queryParams.get('card_confirmation_success');

    if (success === 'true') {
      setIsSuccess(true);
    } else {
      setIsSuccess(false);
    }
  }, []);

  return <Message isSuccess={isSuccess} redirectUrl="/my-cards" translation="card-confirmation" />;
};

export default CardConfirmation;
