import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Sponsors from 'redux/sponsor';
import CtaButton from '../ctaButton';
import style from './index.module.scss';

const Message = ({ isSuccess, redirectUrl = '', translation = '' }) => {
  const { t } = useTranslation(translation);
  const mediumScreen = useMedia(`(max-width: 768px)`);

  const history = useHistory();

  const [counter, setCounter] = useState(5);
  const [loading, setLoading] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const redirect = (path) => {
    if (!isSuccess) {
      setLoading(true);
      setTimeout(() => {
        history.push(path);
        setLoading(false);
      }, 1000);
    } else history.push(path);
  };

  useEffect(() => {
    if (isSuccess) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      counter == 0 && setTimeout(() => (Sponsors ? redirect('/dashboard') : redirect('/')), 1000);
    }
  }, [counter, isSuccess]);

  return (
    <div className={style.confirmationWrapper}>
      <div className={style.confirmationIcon}>
        <img src={isSuccess ? 'images/success-confirm.svg' : 'images/failure-confirm.svg'} />
      </div>
      <div className={style.confirmationText}>
        <p>{isSuccess ? t('success-text') : t('failure-text')}</p>
      </div>
      <div className={style.confirmationRedirect}>
        {!isSuccess && redirectUrl && (
          <CtaButton
            text={t('checkout')}
            onClickType="submit"
            type={'fill-primary-500'}
            customStyle={{ minWidth: mediumScreen ? '180px' : '230px', minHeight: mediumScreen ? '46px' : '60px' }}
            customTextStyle={{ fontSize: mediumScreen ? '1.063rem' : '1.375rem' }}
            onClick={() => {
              redirect(redirectUrl);
            }}
            loading={loading ? antIcon : ''}
          />
        )}
        {isSuccess && t('success-redirect')}
        {isSuccess && <span>{counter}</span>}
      </div>
    </div>
  );
};

export default Message;
