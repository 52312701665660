import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { getSponsoree, getAllSponsorees, getFeaturedSponsorees } from './thunks';

export const slice = createSlice({
  name: 'sponsorees',
  initialState: adapter.getInitialState({ meta: {}, featuredSponsorees: [] }),
  extraReducers: (builder) => {
    builder.addCase(getSponsoree.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.sponsoree) return;
      adapter.setOne(state, data.sponsoree);
    });
    builder.addCase(getSponsoree.rejected, (state, action) => {
      let { message } = action.error;
      if (message === 'Rejected') window.location.href = '/404';
    });
    builder.addCase(getFeaturedSponsorees.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.sponsorees) return;
      state.meta = payload.data.meta;
      state.featuredSponsorees = data.sponsorees;
    });
    builder.addCase(getAllSponsorees.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.sponsorees) return;
      state.meta = payload.data.meta;
      adapter.setAll(state, data.sponsorees);
    });
  },
});
const Sponsorees = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Sponsorees;
