import style from './index.module.scss';

const ToolTip = ({ type = 'info', data = '', dataStyle = {} }) => (
  <div className={style.toolTip}>
    <div className={style.toolTipIcon}>
      <img src="/images/info-gray.svg" />
    </div>
    <div className={style.toolTipData} style={dataStyle}>
      <div className={style.toolTipDataBlock}></div>
      <div>
        <p>{data}</p>
      </div>
    </div>
  </div>
);

export default ToolTip;
