import Transactions from 'redux/transactions';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import Table from 'components/atoms/Table';
import Pagination from 'components/atoms/Pagination';
import PriceText from 'components/atoms/PriceText';
import { parseDate } from 'utils/date';
import DonationsCard from 'components/atoms/DonationsCard';
import style from './index.module.scss';

const PER_PAGE = 6;
const MyDonations = () => {
  const { t, i18n } = useTranslation('myTransactions');
  const { language } = i18n;

  const mediumScreen = useMedia(`(max-width: 768px)`);
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    per_page: PER_PAGE,
  });

  const transactions = useSelector(Transactions.selectors.transactionsSelectors.selectAll);
  const transactionsCount = useSelector(Transactions.selectors.totalEntries);
  const currenPage = useSelector(Transactions.selectors.currentPage);

  const columns = [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('total-donated'),
      dataIndex: 'total_donated',
      key: 'total_donated',
    },
    {
      title: t('purpose'),
      dataIndex: 'purpose',
      key: 'purpose',
    },
  ];

  useEffect(() => {
    setLoading(true);
    dispatch(Transactions.thunks.getAllTransactions(params));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [params, language]);

  const getDataSource = () =>
    transactions.map((el, index) => {
      return {
        key: index,
        date: <div className={style.donationDate}>{parseDate(el.received_date, i18n.language)}</div>,
        status: <div className={style.donationStatus}>{t(el.transaction_type)}</div>,
        total_donated: (
          <div className={style.donationTotalPrice}>
            <PriceText price={el.unsigned_amount} currency={el.currency} symbol={false} priceBold={true} />
          </div>
        ),
        purpose: (
          <div className={style.purposeWrapper}>
            {(() => {
              switch (el.mediator_type) {
                case 'PurchasedGift':
                  return (
                    <div className={style.purpose}>
                      <img className={style.purposeIcon} src="/images/purchased-gift.svg" />
                      <div className={style.purposeText}>{t('purchased-gift')}</div>
                    </div>
                  );
                case 'GeneralDonation':
                  return (
                    <div className={style.purpose}>
                      <img className={style.purposeIcon} src="/images/general-donation.svg" />
                      <div className={style.purposeText}>{t('general-donation')}</div>
                    </div>
                  );
                case 'Due':
                  return (
                    <div className={style.purpose}>
                      <img className={style.purposeIcon} src="/images/sponsorship-donation.svg" />
                      <div className={style.purposeText}>{t('sponsorship-donation')}</div>
                    </div>
                  );
                default:
                  return (
                    <div className={style.purpose}>
                      <img className={style.purposeIcon} src="/images/balance-icon.png" />
                      <div className={style.purposeText}>
                        {el.invoice_type === 'balance' ? t('balance') : t('applied-balance')}
                      </div>
                    </div>
                  );
              }
            })()}
          </div>
        ),
      };
    });

  const handlePageChagne = (page) => {
    setParams((prevState) => {
      return {
        ...prevState,
        page: page,
      };
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={style.myDonationsWrapper}>
      <div className={style.myDonationsTitle}>
        <p>{t('my-Transactions')}</p>
      </div>
      {!mediumScreen ? (
        <div className="myDonationTable">
          <Table columns={columns} dataSource={getDataSource()} loading={loading} />
        </div>
      ) : (
        <DonationsCard dataSource={getDataSource()} />
      )}
      <div className={style.myDonationsPagination}>
        <Pagination total={transactionsCount} pageSize={PER_PAGE} current={currenPage} onChange={handlePageChagne} />
      </div>
    </div>
  );
};

export default MyDonations;
