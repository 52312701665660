import { Route } from 'react-router';

import DefaultLayout from 'layouts/DefaultLayout';

const GuestRoute = ({ path, component: Component }) => (
  <DefaultLayout>
    <Route exact path={path} component={Component} />
  </DefaultLayout>
);

export default GuestRoute;
