import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const duesSelectors = {
  ...adapter.getSelectors((state) => state.dues),
};

export const getMeta = createSelector(
  (state) => state?.dues,
  (dues) => dues?.meta,
);

export const totalEntries = createSelector(
  (state) => state?.dues?.meta,
  (meta) => meta?.total_entries,
);

export const currentPage = createSelector(
  (state) => state?.dues?.meta,
  (meta) => meta?.current_page,
);

export const getDuesIds = createSelector(
  (state) => state?.dues,
  (dues) => dues?.selectedCheckoutIds,
);

export const getDuesErrors = createSelector(
  (state) => state?.dues,
  (dues) => dues.errors || [],
);
