import { createAsyncThunk } from '@reduxjs/toolkit';
import VillagesAPI from './services';

export const getVillage = createAsyncThunk('villages/getVillage', async (id, thunkAPI) => {
  try {
    const response = await VillagesAPI.getVillage(id);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const getAllVillages = createAsyncThunk('villages/getAllVillages', async (params, thunkAPI) => {
  try {
    const response = await VillagesAPI.getAllVillages(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
