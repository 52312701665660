import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { useHistory } from 'react-router-dom';
import CtaButton from 'components/atoms/ctaButton';
import SectionTitle from 'components/atoms/SectionTitle';
import SectionHeader from 'components/atoms/SectionHeader';
import { Partners } from 'utils/data/partners';
import style from './index.module.scss';
import HelmetTag from 'components/atoms/Helmet';

const WhoWeAre = () => {
  const { t, i18n } = useTranslation('who-we-are');
  const history = useHistory();

  const xxlargeScreen = useMedia(`(max-width: 1600px)`);
  const largeScreen = useMedia(`(max-width: 992px)`);
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const smallScreen = useMedia(`(max-width: 576px)`);
  const xsmallScreen = useMedia(`(max-width: 480px)`);

  const ValuesData = [
    {
      name: t('compassion'),
      description: <div dangerouslySetInnerHTML={{ __html: t('compassionDescription') }} />,
      icon: '/images/compassion.svg',
    },
    {
      name: t('unity'),
      description: <div dangerouslySetInnerHTML={{ __html: t('unityDescription') }} />,
      icon: '/images/unity.svg',
    },
    {
      name: t('accountability'),
      description: <div dangerouslySetInnerHTML={{ __html: t('accountabilityDescription') }} />,
      icon: '/images/accountability.svg',
    },
    {
      name: t('integrity'),
      description: <div dangerouslySetInnerHTML={{ __html: t('integrityDescription') }} />,
      icon: '/images/integrity.svg',
    },
    {
      name: t('excellence'),
      description: <div dangerouslySetInnerHTML={{ __html: t('excellenceDescription') }} />,
      icon: '/images/excellence.svg',
    },
  ];

  const redirect = (path) => {
    history.push(path);
  };

  const renderHeroSection = () => (
    <div className={style.WhoWeAreHero}>
      <div className={style.WhoWeAreTitle}>
        <SectionHeader text={t('who-we-are')} />
      </div>
      <div className={style.WhoWeAreHeroInfo}>
        <div className={style.heroInfo}>
          <div className={style.heroInfoIcon}>
            <img src="/images/hand-blue.svg" alt="Hero Image" />
          </div>
          <div className={style.heroInfoDesc1}>
            <p>{t('hero-desc1')}</p>
          </div>
          <div className={style.heroInfoDesc2}>
            <p>{t('hero-desc2')}</p>
          </div>
        </div>
        <div className={style.WhoWeAreHeroVedio}>
          <iframe
            title="hero vedio"
            width="100%"
            style={{ borderWidth: 0 }}
            height="480"
            allow="autoplay;muted"
            allowFullScreen
            src="https://player.vimeo.com/video/850100994?autoplay=1&amp;muted=1&amp;badge=0&amp;autoplayer_id=0&amp;app_id=58479"
          ></iframe>
        </div>
      </div>
    </div>
  );

  const renderMissionVisionSection = () => (
    <div className={style.WhoWeAreMissionVision}>
      <div className={style.WhoWeAreMissionVisionBG}>
        <img
          src={mediumScreen ? '/images/background-blue-mobile.png' : '/images/background-blue-web.png'}
          style={i18n.language == 'ar' ? { transform: 'rotateY(180deg)' } : {}}
        />
      </div>
      <div className={style.WhoWeAreMissionVisionContent}>
        <div className={style.WhoWeAreMission}>
          <SectionTitle
            type={'fill-white'}
            text={t('our-mission')}
            iconOne="/images/star-white.svg"
            iconTwo="/images/title-header-line.svg"
            customStyle={{
              justifyContent: 'center',
              alignItems: i18n.language === 'ar' ? (xxlargeScreen && !xsmallScreen ? 'center' : '') : '',
              columnGap: mediumScreen ? '' : i18n.language === 'en' ? '0' : '10px',
            }}
          />
          <div className={style.WhoWeAreMissionDesc}>
            <p>{t('mission-desc')}</p>
          </div>
        </div>
        <div className={style.WhoWeAreVision}>
          <SectionTitle
            type={'fill-white'}
            text={t('our-vision')}
            iconOne="/images/star-white.svg"
            iconTwo="/images/title-header-line.svg"
            customStyle={{
              justifyContent: 'center',
              alignItems: i18n.language === 'ar' ? (xxlargeScreen && !xsmallScreen ? 'center' : '') : '',
              columnGap: mediumScreen ? '' : i18n.language === 'en' ? '0' : '5px',
            }}
          />
          <div className={style.WhoWeAreVisionDesc}>
            <p>{t('vision-desc')}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const renderValuesSection = () => (
    <div className={style.WhoWeAreValues}>
      <SectionTitle
        type={'fill-primary'}
        text={t('our-values')}
        iconOne="/images/star-blue.svg"
        iconTwo="/images/title-header-line.svg"
        customStyle={{
          justifyContent: 'center',
          alignItems: i18n.language === 'ar' ? (xxlargeScreen && !xsmallScreen ? 'center' : '') : '',
          columnGap: mediumScreen ? '' : i18n.language === 'en' ? '0' : '5px',
        }}
      />
      <div className={style.WhoWeAreValuesContent}>
        {ValuesData.map((item, index) => (
          <div key={index} className={style.WhoWeAreValuesItem}>
            <div className={style.valueIcon}>
              <img src={item.icon} alt="Value Item" />
            </div>
            <div className={style.valueName}>{item.name}</div>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const renderVillagesSection = () => (
    <div className={style.WhoWeAreVillages}>
      <div className={style.WhoWeAreVillagesInfo}>
        <SectionTitle type={'fill-primary'} text={t('our-villages')} />
        <div className={style.WhoWeAreVillagesInfoDesc}>
          <p>{t('villages-desc')}</p>
        </div>
      </div>
      <div className={style.WhoWeAreVillagesImage}>
        <img src="/images/villages-picture.png" alt="Villages Image" />
      </div>
    </div>
  );

  const renderHistorySection = () => (
    <div className={style.WhoWeAreHistory}>
      <SectionTitle
        type={'fill-primary'}
        text={t('our-history')}
        iconOne="/images/star-blue.svg"
        customTextStyle={{
          marginTop:
            i18n.language == 'ar' ? (xsmallScreen ? '3px' : largeScreen ? '-10px' : '') : smallScreen ? '3px' : '',
        }}
        customStyle={{ justifyContent: 'center', columnGap: i18n.language === 'ar' ? '8px' : '' }}
      />
      <div className={style.WhoWeAreHistoryDesc}>
        <p>{t('history-desc1')}</p>
        <p>{t('history-desc2')}</p>
      </div>
    </div>
  );

  const renderSponsorSection = () => (
    <div className={style.WhoWeAreSponsor}>
      <div className={style.WhoWeAreSponsorImage}>
        {!smallScreen ? (
          <img src={`${i18n.language == 'ar' ? '/images/sponsor-ar-web.png' : '/images/sponsor-en-web.png'}`} />
        ) : (
          <img src={`${i18n.language == 'ar' ? '/images/sponsor-ar-mobile.png' : '/images/sponsor-en-mobile.png'}`} />
        )}
      </div>
      <div className={style.WhoWeAreSponsorBtn}>
        <CtaButton
          type={'fill-secondary'}
          full={true}
          text={t('sponsor-now')}
          customStyle={{
            minWidth: mediumScreen ? '220px' : '275px',
            minHeight: mediumScreen ? '64px' : '80px',
            fontSize: mediumScreen ? '1.188rem' : '1.563rem',
            fontWeight: 'bold',
          }}
          onClick={() => {
            redirect('/children');
          }}
        />
      </div>
    </div>
  );

  const renderPartnersSection = () => (
    <div className={style.WhoWeArePartners}>
      <SectionHeader text={t('our-partners')} />
      <div className={style.WhoWeArePartnersContent}>
        {Partners.map((item, index) => (
          <div key={index} className={style.WhoWeArePartnersItem}>
            <a href={item.link} target="_blank" rel="noreferrer">
              <img src={item.icon} />
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className={style.WhoWeAreWrapper}>
      <HelmetTag
        title="Who We Are"
        description="Healing Grace Who We Are"
        url="who-we-are"
        image_url="https://company-assets.fra1.cdn.digitaloceanspaces.com/healing-grace/logo.png"
      />

      {renderHeroSection()}
      {renderMissionVisionSection()}
      {renderValuesSection()}
      {renderVillagesSection()}
      {renderSponsorSection()}
      {renderPartnersSection()}
    </div>
  );
};

export default WhoWeAre;
