const fields = (t) => [
  {
    item: { rules: [], type: 'upload', name: 'image_url', accessor: 'photo_url' },
    inputProps: { label: t('uploadPhoto'), accept: 'image/jpeg,image/png', canRemove: true },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
    style: {},
  },
  {
    item: {
      rules: [{ required: true, message: t('firstNameRequire') }],
      name: 'first_name',
      type: 'default',
    },
    inputProps: { label: t('firstNamelabel'), placeholder: t('firstName') },
    columns: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12, xxl: 12 },
  },
  {
    item: {
      rules: [{ required: true, message: t('lastNameRequire') }],
      name: 'last_name',
      type: 'default',
    },
    inputProps: { label: t('lastNameLabel'), placeholder: t('lastName') },
    columns: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12, xxl: 12 },
  },
  {
    item: {
      rules: [],
      name: 'arabic_full_name',
      type: 'default',
    },
    inputProps: { label: t('arabicFullName'), placeholder: t('arabicFullName') },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
    style: { direction: 'rtl' },
  },
];
export default fields;
