import { useTranslation } from 'react-i18next';
import style from './index.module.scss';

const PriceText = ({ price, currency, symbol = true, priceBold = false }) => {
  const { t } = useTranslation('priceText');

  const unit = symbol ? (
    currency === 'usd' ? (
      <span className="dollarSymbol">{t('$')}</span>
    ) : (
      <span>{t('le')}</span>
    )
  ) : currency === 'usd' ? (
    <span>{t('USD')}</span>
  ) : (
    <span>{t('EGP')}</span>
  );

  return (
    <div className={symbol && currency === 'usd' && style.dollarSymbolDir} style={{ display: 'inline-block' }}>
      <span className={`${priceBold ? style.priceBold : ''}`}>{price}</span> {unit}
    </div>
  );
};

export default PriceText;
