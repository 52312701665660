import sponsorships from 'redux/sponsorships';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/atoms/Table';
import LoadingList from 'components/molecules/LoadingList';
import PaymentCard from 'components/molecules/PaymentCard';
import PriceText from 'components/atoms/PriceText';
import { frequency, parseDate } from 'utils/date';
import style from './index.module.scss';

const INIT_PARAMS = {
  status: 'active',
  includes: 'next_due_date,sponsoree',
  per_page: 4,
};
const NextPayment = () => {
  const { t, i18n } = useTranslation('dashboard');
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [filterParams, setFilterParams] = useState(INIT_PARAMS);

  const nextPayment = useSelector(sponsorships.selectors.getNextPay);

  const columns = [
    {
      title: '',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: '',
      dataIndex: 'id_name',
      key: 'id_name',
    },
  ];

  const getDataSource = () =>
    nextPayment.map((el, index) => {
      return {
        key: index,
        date: <div className={style.childPayDate}>{parseDate(el.next_due_date, i18n.language)}</div>,
        price: (
          <div className={style.childPrice}>
            <PriceText
              price={(el.sponsoree?.price * frequency[el.frequency].value).toFixed(2)}
              currency={el.payment_currency}
            />
          </div>
        ),
        id_name: (
          <div className={style.childIdName}>
            <span>{el.sponsoree?.code}</span>
            {el.sponsoree?.full_name}
          </div>
        ),
      };
    });

  useEffect(() => {
    setLoading(true);
    dispatch(sponsorships.thunks.getNextPayment(filterParams));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className={style.nextPayWrapper}>
      <div className={style.nextPayTitle}>
        <p>{t('nextPaymentDate')}</p>
      </div>
      {!mediumScreen ? (
        <div className="hideTableHeader myDonationTable">
          <Table
            columns={columns}
            dataSource={getDataSource()}
            loading={loading}
            EmptyTablePath="/dashboard-donations"
          />
        </div>
      ) : !loading ? (
        <PaymentCard dataSource={getDataSource()} EmptyTablePath="/dashboard-donations" />
      ) : (
        <LoadingList />
      )}
    </div>
  );
};

export default NextPayment;
