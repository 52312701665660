import style from './index.module.scss';

const SectionTitle = ({ text, iconOne, iconTwo, type, customStyle, customTextStyle }) => {
  return (
    <div className={style.sectionTitle} style={customStyle}>
      <div className={style.sectionIcon}>
        {iconOne && (
          <div className={style.sectionIconOne}>
            <img src={iconOne} />
          </div>
        )}
      </div>
      <div className={style.sectionInfo}>
        {text && (
          <div className={style.titleInfoText} style={customTextStyle}>
            <span className={style[type]}>{text}</span>
          </div>
        )}
        {iconTwo && (
          <div className={style.iconTwo}>
            <img src={iconTwo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionTitle;
