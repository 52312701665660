import Sponsors from 'redux/sponsor';
import PaymentCards from 'redux/cards';
import Dues from 'redux/dues';
import { useTranslation } from 'react-i18next';
import { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Row, Col, Radio } from 'antd';
import CtaButton from 'components/atoms/ctaButton';
import PriceText from 'components/atoms/PriceText';
import FormCard from 'components/molecules/FormCard';
import PaymentCard from 'components/atoms/PaymentCard';
import InfoCard from 'components/molecules/InfoCard';
import style from './index.module.scss';
import LoadingList from 'components/molecules/LoadingList';
import Alert from 'components/atoms/Alert';
import { loadFromLocalStorage } from 'utils/localStorageHelpers';

const LateDuesCheckout = () => {
  const { t, i18n } = useTranslation('lateDues');
  const [form] = Form.useForm();

  const largeScreen = useMedia(`(max-width: 992px)`);
  const mediumScreen = useMedia(`(max-width: 768px)`);

  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [cardsLoading, setCardsLoading] = useState(false);
  const [addCardLoading, setAddCardLoading] = useState(false);
  const [value, setValue] = useState(0);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: '#00008B' }} spin />;

  const sponsor = useSelector(Sponsors.selectors.getCurrentSponsor);
  const cards = useSelector(PaymentCards.selectors.cardsSelectors.selectAll);
  const duesIds = useSelector(Dues.selectors.getDuesIds);
  const default_payment_method = useSelector(PaymentCards.selectors.getDefaultPaymentMethod);

  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const total = queryParams.get('total');

  useEffect(() => {
    setCardsLoading(true);
    dispatch(PaymentCards.thunks.getAllCards());
    dispatch(Sponsors.thunks.getProfile());
    setTimeout(() => {
      setCardsLoading(false);
    }, 1000);
  }, [i18n.language]);

  useEffect(() => {
    if (duesIds.length == 0) history.push('/late-dues');
  }, [duesIds]);

  const addNewCard = useCallback(() => {
    setAddCardLoading(true);
    dispatch(PaymentCards.thunks.createCard()).then((res) => {
      window.open(res.payload.data.url, '_self');
      setTimeout(() => {
        setAddCardLoading(false);
      }, 1000);
    });
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    setLoading(true);
    let due = {
      dues_ids: duesIds[0],
      payment_method_id: value,
    };

    dispatch(Dues.thunks.payLateDues({ due })).then((res) => {
      if (!res.error) {
        history.push('/payment?payment_confirmation_success=true');
      } else history.push('/late-dues');
    });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [value]);

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const renderExistingCardsForm = () => (
    <div className={style.existingCardsForm}>
      {!cardsLoading ? (
        <>
          {cards.map((card) => (
            <div className={`${'RadioWrapperForm'} ${'RadioWrapperCards'}`}>
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={card.id}>
                  <PaymentCard
                    cardId={card?.id}
                    cardName={card?.billing_details?.name}
                    cardNumber={card?.card?.last4}
                    cardExpires={`${card?.card?.exp_month}/${card?.card?.exp_year}`}
                    defaultCard={default_payment_method}
                    selected={value == card.id}
                  />
                </Radio>
              </Radio.Group>
            </div>
          ))}
        </>
      ) : (
        <LoadingList />
      )}

      <div className={cards.length > 0 ? style.addNewCardBtns : style.emptyAddNewCard}>
        <CtaButton
          text={t('add-new-card')}
          onClick={() => {
            addNewCard();
          }}
          type="border-primary"
          customStyle={{
            minWidth: mediumScreen ? '180px' : '230px',
            minHeight: mediumScreen ? '45px' : '60px',
            fontSize: mediumScreen ? '1.063rem' : '1.375rem',
          }}
          loading={addCardLoading ? antIcon : ''}
        />
      </div>
    </div>
  );

  const renderSummary = () => (
    <div className={style.summary}>
      <div>{t('total')}</div>
      <div>
        <PriceText price={total} currency={sponsor.payment_currency} />
      </div>
    </div>
  );

  return (
    <div className={style.lateDuesCheckoutWrapper}>
      <div className={style.checkoutTitle}>
        <p>{t('checkout')}</p>
      </div>
      <div className={style.checkoutContentWrapper}>
        <Row gutter={[{ xs: 15, sm: 15, md: 30, lg: 30, xl: 40 }]}>
          <Col xs={24} md={24} lg={16} xl={16} order={largeScreen ? 2 : 1}>
            <div className={style.cards}>
              <FormCard title={t('existing-cards')} form={renderExistingCardsForm()} />
            </div>
            {cards.length > 0 && (
              <div className={style.submitBtn}>
                <CtaButton
                  type={'fill-secondary'}
                  onClickType="submit"
                  text={t('submit')}
                  customStyle={{
                    minWidth: mediumScreen ? '220px' : '275px',
                    minHeight: mediumScreen ? '64px' : '80px',
                    fontSize: mediumScreen ? '1.188rem' : '1.563rem',
                    fontWeight: 'bold',
                  }}
                  loading={loading ? antIcon : ''}
                  onClick={onSubmit}
                  disabled={value == 0}
                />
              </div>
            )}
          </Col>
          <Col xs={24} md={24} lg={8} xl={8} order={largeScreen ? 1 : 2}>
            <InfoCard
              title={t('summary')}
              info={renderSummary()}
              customWrapper={`${'cartSummary'} ${'lateDuesSummary'}`}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LateDuesCheckout;
