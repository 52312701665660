import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const giftSelectors = {
  ...adapter.getSelectors((state) => state.gifts),
};

export const getMeta = createSelector(
  (state) => state?.gifts,
  (gifts) => gifts?.meta,
);

export const totalEntries = createSelector(
  (state) => state?.gifts?.meta,
  (meta) => meta?.total_entries,
);

export const currentPage = createSelector(
  (state) => state?.gifts?.meta,
  (meta) => meta?.current_page,
);
