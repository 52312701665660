import { Helmet } from 'react-helmet';

const HelmetTag = ({ title, image_url, url, description }) => {
  return (
    <Helmet>
      <title>{`Healing Grace - ${title}`}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image_url} />
      <meta property="og:image:width" content="401" />
      <meta property="og:image:height" content="201" />
      <meta property="og:url" content={`https://dev-healing-grace-web.thewebops.com/${url}`} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_GB" />

      <meta property="title" content={title} />
      <meta property="description" content={description} />
      <meta property="image" content={image_url} />
      <meta property="url" content={`https://dev-healing-grace-web.thewebops.com/${url}`} />
      <meta property="type" content="website" />
      <meta property="locale" content="en_GB" />
    </Helmet>
  );
};

export default HelmetTag;
