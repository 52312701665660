import axios from '../../utils/requestHelper';
import { loadFromLocalStorage } from 'utils/localStorageHelpers';

const authHeaders = loadFromLocalStorage('authHeaders');

const getSponsoree = (id) => axios.get(`/${authHeaders ? 'sponsors' : 'guests'}/sponsorees/${id}`);

const getAllSponsorees = (params) => axios.get(`/${authHeaders ? 'sponsors' : 'guests'}/sponsorees`, { params });

const SponsoreesAPI = {
  getSponsoree,
  getAllSponsorees,
};

export default SponsoreesAPI;
