import SponsoreesRedux from 'redux/sponserees';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Card from 'components/atoms/Card';
import Carousel from 'components/atoms/ Carousel';
import SectionHeader from 'components/atoms/SectionHeader';
import { useTranslation } from 'react-i18next';
import LoadingList from 'components/molecules/LoadingList';
import { Col } from 'antd';
import { useMedia } from 'react-use';
import { useHistory } from 'react-router-dom';
import CtaButton from 'components/atoms/ctaButton';
import { yearTranslateHelper } from 'utils/yearsTranslateHelper';
import style from './index.module.scss';

const Children = () => {
  const { t, i18n } = useTranslation('home');
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, SetLoading] = useState(false);
  const childrenSelector = useSelector(SponsoreesRedux.selectors.getFeatured);
  const xxLargeScreen = useMedia(`(max-width: 1600px)`);
  const xlargeScreen = useMedia(`(max-width: 1200px)`);
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const smallScreen = useMedia(`(max-width: 576px)`);

  const pageSize = useCallback(() => (smallScreen ? 1 : xlargeScreen ? 2 : 4), [mediumScreen, xlargeScreen]);

  const renderSection = () => loading || (!loading && childrenSelector.length > 0);

  useEffect(() => {
    SetLoading(true);
    dispatch(SponsoreesRedux.thunks.getFeaturedSponsorees({ featured: true, per_page: 4 }));
    setTimeout(() => {
      SetLoading(false);
    }, 1000);
  }, [i18n.language]);

  const redirectToPage = (path) => {
    history.push(path);
  };

  const childrenList = () =>
    childrenSelector.map((el, index) => (
      <Col key={index} xl={6} lg={12} md={12} sm={12} xs={24} xxs={24} className={style.childCard}>
        <Card
          key={el.id}
          title={el.full_name}
          image={el.image_url}
          onClickButton={() => {
            redirectToPage(`/children/${el.id}`);
          }}
          badge={el.education_level === 'rising_leader' ? t('university') : ''}
          subTitle={
            <div className={style.childrenListSubTitle}>
              <span className={style.childrenListSubTitleCode}>{el.code}</span>
              <span>{yearTranslateHelper(t, el.current_age)}</span>
            </div>
          }
          textButton={t('chooseMe')}
          customCardStyle={{
            border: '1px solid #97a3ae',
            height: mediumScreen ? '237px' : '282px',
            alignItems: 'start',
            overflow: 'hidden',
          }}
        />
      </Col>
    ));
  return (
    renderSection() && (
      <div className={style.childrenListWrapper}>
        <div className={style.childrenList}>
          <div className={style.childrenListHeader}>
            <SectionHeader
              text={t('ourchildren')}
              customTextStyle={{ fontSize: mediumScreen ? '1.563rem' : xxLargeScreen ? '1.875rem' : '3.125rem' }}
            />
          </div>
          <div className={style.childrenListCards}>
            {!loading && <Carousel pageSize={pageSize()}>{childrenList()}</Carousel>}
            {loading && <LoadingList />}
          </div>
          <div className={style.childrenListExplore}>
            <CtaButton
              type={'fill-primary'}
              text={t('exploreMore')}
              onClick={() => {
                redirectToPage('/children');
              }}
              customStyle={{
                minWidth: mediumScreen ? '220px' : '275px',
                minHeight: mediumScreen ? '64px' : '80px',
                fontSize: mediumScreen ? '1.188rem' : '1.563rem',
                fontWeight: 'bold',
              }}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default Children;
