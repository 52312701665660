import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from 'router';
import Projects from './projects';
import Children from './children';
import Purposes from './purposes';
import Subjects from './subjects';
import Villages from './villages';
import Sponsorees from './sponserees';
import Sponsor from './sponsor';
import Gifts from './gifts';
import Cart from './cart';
import Sponsorships from './sponsorships';
import Transactions from './transactions';
import Dues from './dues';
import Letters from './letters';
import Cards from './cards';

const rootReducer = () =>
  combineReducers({
    router: connectRouter(history),
    projects: Projects.slice.reducer,
    children: Children.slice.reducer,
    purposes: Purposes.slice.reducer,
    subjects: Subjects.slice.reducer,
    villages: Villages.slice.reducer,
    sponsorees: Sponsorees.slice.reducer,
    sponsors: Sponsor.slice.reducer,
    gifts: Gifts.slice.reducer,
    cart: Cart.slice.reducer,
    sponsorships: Sponsorships.slice.reducer,
    transactions: Transactions.slice.reducer,
    dues: Dues.slice.reducer,
    letters: Letters.slice.reducer,
    cards: Cards.slice.reducer,
  });

export default rootReducer;
