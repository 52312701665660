import Sponsor from 'redux/sponsor';
import Cart from 'redux/cart';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import FormCard from 'components/molecules/FormCard';
import InputForm from 'components/atoms/InputForm';
import CtaButton from 'components/atoms/ctaButton';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const ChangePassword = () => {
  const { t } = useTranslation('changePassword');
  const mediumScreen = useMedia(`(max-width: 768px)`);

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const [errorList, setErrorList] = useState({});

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const handleFormChange = () => {
    const isFormValid = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(isFormValid);
  };

  const logout = () => {
    dispatch(Sponsor.slice.actions.logout());
    dispatch(Cart.slice.actions.updateCart());
    history.push('/sign-in');
  };

  const passwordMatchValidation =
    (t) =>
    ({ getFieldValue }) => ({
      validator(_, value) {
        let password = getFieldValue('password');
        if (password === value) return Promise.resolve();
        else return Promise.reject(t('confirm-password-validation'));
      },
    });

  const clearErrors = (name) => {
    setErrorList((prevState) => {
      delete prevState[name];
      return prevState;
    });
  };

  const onSubmit = useCallback(
    (values) => {
      setLoading(true);
      dispatch(Sponsor.thunks.changePassword(values)).then((res) => {
        if (!res.payload.errors) {
          logout();
        } else {
          setErrorList(res.payload.errors);
        }
        setLoading(false);
      });
    },
    [dispatch, history],
  );

  const renderForm = () => (
    <div className={styles.formWrapper}>
      <div className={styles.form}>
        <Form
          className={styles.formInputs}
          onFieldsChange={handleFormChange}
          onFinish={onSubmit}
          form={form}
          layout="vertical"
        >
          <InputForm
            label={t('old-password')}
            name="current_password"
            placeholder={t('old-password')}
            error={errorList.current_password ? [t('old-password-invalid')] : null}
            clearError={clearErrors}
            type="password"
            rules={[{ required: true, message: t('old-password-required') }]}
          />
          <InputForm
            label={t('new-password')}
            name="password"
            placeholder={t('new-password')}
            type="password"
            error={errorList.password}
            clearError={clearErrors}
            rules={[
              { required: true, message: t('new-password-required') },
              { pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{6,}$/, message: t('new-password-validation') },
            ]}
          />

          <InputForm
            label={t('password-confirmation')}
            name="password_confirmation"
            placeholder={t('password-confirmation')}
            type="password"
            dependencies={['password']}
            error={errorList.password_confirmation}
            rules={[passwordMatchValidation(t)]}
          />

          <Form.Item>
            <div className={styles.sendBtn}>
              <CtaButton
                type={'fill-primary-500'}
                onClickType="submit"
                text={t('update')}
                customStyle={{
                  minWidth: mediumScreen ? '180px' : '230px',
                  minHeight: mediumScreen ? '46px' : '59px',
                  fontSize: mediumScreen ? '1.063rem' : '1.375rem',
                  fontWeight: 'normal',
                }}
                loading={loading ? antIcon : ''}
                disabled={disabled}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );

  return (
    <div className={styles.changePasswordWrapper}>
      <FormCard title={t('change-password')} form={renderForm()} backRoute={'profile'} type={'full-white-color'} />
    </div>
  );
};

export default ChangePassword;
