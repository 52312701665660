import { Spin } from 'antd';
import style from './index.module.scss';
const CtaButton = ({
  text,
  icon,
  onClick,
  type,
  customStyle,
  customTextStyle,
  customIconStyle,
  loading,
  counter = false,
  disabled = false,
}) => {
  return (
    <div className={style.ctaButton}>
      {counter !== 0 && counter && <div className={style.ctaButtonCounter}>{counter}</div>}
      <button
        className={`${style[type]} ${disabled && style.disabledButton}`}
        onClick={onClick}
        disabled={disabled}
        style={customStyle}
      >
        {icon && <img src={icon} style={customIconStyle} />}
        <div style={customTextStyle}>
          {loading && <Spin className={style.loadingIcon} indicator={loading} />}
          <span>{text && text}</span>
        </div>
      </button>
    </div>
  );
};

export default CtaButton;
