import { useTranslation } from 'react-i18next';
import Table from 'components/atoms/Table';
import style from './index.module.scss';
import Tabs from 'components/atoms/Tabs';
import sponsorships from 'redux/sponsorships';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { frequency, parseDate } from 'utils/date';
import Pagination from 'components/atoms/Pagination';
import { useMedia } from 'react-use';
import SponsershipCard from 'components/atoms/SponsershipCard';
import LoadingList from 'components/molecules/LoadingList';

const PER_PAGE = 4;

const initParams = {
  page: 1,
  per_page: PER_PAGE,
  status: 'active',
  includes: 'sponsor,sponsoree',
};

const Sponsorships = () => {
  const { t, i18n } = useTranslation('sponsorships');
  const { language } = i18n;
  const history = useHistory();
  const dispatch = useDispatch();
  const ships = useSelector(sponsorships.selectors.sponsorshipsSelectors.selectAll);
  const [activeTab, setActiveTab] = useState('1');
  const [filterParams, setFilterParams] = useState(initParams);
  const [loading, setLoading] = useState(false);
  const mediumScreen = useMedia(`(max-width: 1060px)`);

  const sponsorshipsCount = useSelector(sponsorships.selectors.totalEntries);
  const currenPage = useSelector(sponsorships.selectors.currentPage);

  const redirect = (path) => {
    history.push(path);
  };

  const columnsActive = [
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
    },
    {
      title: t('ID NAME'),
      dataIndex: 'id_name',
      key: 'id_name',
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: t('Sponsored since'),
      dataIndex: 'sponsoredSince',
      key: 'sponsoredSince',
    },
    {
      title: t('Next due'),
      dataIndex: 'nextDue',
      key: 'nextDue',
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
    },
  ];

  const columnsInactive = [
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
    },
    {
      title: t('ID NAME'),
      dataIndex: 'id_name',
      key: 'id_name',
    },
    {
      title: t('Sponsored since'),
      dataIndex: 'sponsoredSince',
      key: 'sponsoredSince',
    },
    {
      title: t('exitDate'),
      dataIndex: 'exitDate',
      key: 'exitDate',
    },
    {
      title: t('exitReason'),
      dataIndex: 'exitReason',
      key: 'exitReason',
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
    },
  ];

  const parsePriceCurrency = (price, currency, _frequency) => (
    <>
      {currency === 'usd' ? (
        <>
          <span>
            <span className="dollarSymbol">{t('$')}</span>
            {price.toFixed(2) * frequency[_frequency].value}
          </span>{' '}
          /{frequency[_frequency].label[i18n.language]}
        </>
      ) : (
        <>
          <span>
            {price.toFixed(2) * frequency[_frequency].value}
            <span className="poundSymbol">{t('le')}</span>
          </span>{' '}
          /{frequency[_frequency].label[i18n.language]}
        </>
      )}
    </>
  );
  const getDataSourceActive = () =>
    ships
      .filter((el) => el.status === 'active')
      .map((el, index) => {
        return {
          key: index,
          image: <img className={style.childImage} src={el.sponsoree.image_url} />,
          id_name: (
            <div className={style.childIdName}>
              <span>{el.sponsoree.code}</span>
              {el.sponsoree.full_name}
            </div>
          ),
          amount: (
            <div className={style.childPrice}>
              {parsePriceCurrency(el.sponsoree.price, el.payment_currency, el.frequency)}
            </div>
          ),
          sponsoredSince: (
            <div className={style.date}>{parseDate(el.subscription_start_date ?? el.started_at, i18n.language)}</div>
          ),
          nextDue: <div className={style.date}>{parseDate(el.next_due_date, i18n.language)}</div>,
          actions: (
            <div className={`${style.actions} actions`}>
              <div
                onClick={() => {
                  redirect(`/sponsorships/${el.id}`);
                }}
              >
                <img src="/images/info-blue-circle.svg" />
              </div>
              <div
                onClick={() => {
                  redirect(`/letters/${el.id}`);
                }}
              >
                <img src="/images/message-blue-circle.svg" />
              </div>
              <div
                onClick={() => {
                  redirect('/gift');
                }}
              >
                <img src="/images/gift-blue-circle.svg" />
              </div>
            </div>
          ),
        };
      });

  const getDataSourceInactive = () =>
    ships
      .filter((el) => el.status === 'inactive')
      .map((el, index) => {
        return {
          key: index,
          image: <img className={style.childImage} src={el.sponsoree.image_url} />,
          id_name: (
            <div className={style.childIdName}>
              <span>{el.sponsoree.code}</span>
              {el.sponsoree.full_name}
            </div>
          ),
          sponsoredSince: (
            <div className={style.date}>{parseDate(el.subscription_start_date ?? el.started_at, i18n.language)}</div>
          ),
          exitDate: <div className={style.date}>{parseDate(el.ended_at, i18n.language)}</div>,
          exitReason: (
            <div className={style.date}>{el.other_exit_reason ? el.other_exit_reason : t(el.exit_reason)}</div>
          ),
          actions: (
            <div className={`${style.actions} actions`}>
              <div
                onClick={() => {
                  redirect(`/sponsorships/${el.id}`);
                }}
              >
                <img src="/images/info-blue-circle.svg" />
              </div>
            </div>
          ),
        };
      });

  const handleTabChange = (key) => {
    setActiveTab(key);
    setFilterParams({
      ...initParams,
      status: key === '1' ? 'active' : 'inactive',
    });
  };

  const handlePageChagne = (page) => {
    setFilterParams((prevState) => {
      return {
        ...prevState,
        page: page,
      };
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const tabsItem = [
    {
      key: '1',
      label: t('currentSponsorships'),
      children: !mediumScreen ? (
        <Table columns={columnsActive} dataSource={getDataSourceActive()} loading={loading} />
      ) : !loading ? (
        <SponsershipCard dataSource={getDataSourceActive()} />
      ) : (
        <LoadingList />
      ),
    },
    {
      key: '2',
      label: t('previousSponsorships'),
      children: !mediumScreen ? (
        <Table
          columns={columnsInactive}
          dataSource={getDataSourceInactive()}
          loading={loading}
          EmptyTablePath="/previous-sponsorships"
        />
      ) : !loading ? (
        <SponsershipCard dataSource={getDataSourceInactive()} />
      ) : (
        <LoadingList />
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    dispatch(sponsorships.thunks.getAllSponsorships(filterParams));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [filterParams, language]);

  return (
    <div className={style.sponsorshipsWrapper}>
      <div className={`${style.sponsorshipsTitle} sponsorshipsTitle`}>
        <Tabs
          title={activeTab === '1' ? t('currentSponsorships') : t('previousSponsorships')}
          items={tabsItem}
          onChange={handleTabChange}
        />
        <div className={style.sponsorshipsPagination}>
          <Pagination total={sponsorshipsCount} pageSize={PER_PAGE} current={currenPage} onChange={handlePageChagne} />
        </div>
      </div>
    </div>
  );
};

export default Sponsorships;
