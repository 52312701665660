import PriceText from 'components/atoms/PriceText';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Sponsors from 'redux/sponsor';
import style from './index.module.scss';
import axios from 'utils/requestHelper';

const Header = () => {
  const { t, i18n } = useTranslation('dashboard');
  const dispatch = useDispatch();

  const sponsor = useSelector(Sponsors.selectors.getCurrentSponsor);
  const [yearlyPayment, setYearlyPayment] = useState({
    total_donations: 0,
    current_year_donations: 0,
    previous_year_donations: 0,
  });

  const getYearlyPayment = () => {
    axios.get(`/sponsors/yearly_payments_reports`, {}).then((res) => {
      setYearlyPayment(res.data);
    });
  };

  useEffect(() => {
    getYearlyPayment();
    dispatch(Sponsors.thunks.getProfile());
  }, [dispatch, i18n.language]);

  return (
    <div className={style.dashboardHeaderWrapper}>
      <div className={style.dashboardHeader}>
        <div className={style.dashboardHeaderWelcome}>
          <div className={style.dashboardHeaderWelcomeHeader}>
            {t('welcome')}
            <span>{sponsor.first_name}!</span>
          </div>
          <div className={style.dashboardHeaderWelcomeSubHeader}>
            <img src="/images/balance.svg" />
            {t('accountBalance')}{' '}
            <span>
              <PriceText price={sponsor.balance} currency={sponsor.payment_currency} symbol={false} />
            </span>
          </div>
        </div>
        <div className={`${style.dashboardHeaderBalance} dashboardHeaderBalance`}>
          <div className={style.dashboardHeaderBalanceItem}>
            <img src="/images/totalDonations.svg" />
            <div>
              <p className={style.dashboardHeaderBalanceItemHeader}>{t('totalDonations')}</p>
              <p>
                <PriceText
                  price={yearlyPayment?.total_donations.toLocaleString('en-US')}
                  currency={sponsor.payment_currency}
                  symbol={false}
                />
              </p>
            </div>
          </div>
          <div className={style.divider}></div>
          <div className={style.dashboardHeaderBalanceItem}>
            <img src="/images/donationsYearToDate.svg" />
            <div>
              <p className={style.dashboardHeaderBalanceItemHeader}>{t('donationsYearToDate')}</p>
              <p>
                <PriceText
                  price={yearlyPayment?.current_year_donations.toLocaleString('en-US')}
                  currency={sponsor.payment_currency}
                  symbol={false}
                />
              </p>
            </div>
          </div>
          <div className={style.divider}></div>
          <div className={style.dashboardHeaderBalanceItem}>
            <img src="/images/donationsPreviousYear.svg" />
            <div>
              <p className={style.dashboardHeaderBalanceItemHeader}>{t('donationsPreviousYear')}</p>
              <p>
                <PriceText
                  price={yearlyPayment?.previous_year_donations.toLocaleString('en-US')}
                  currency={sponsor.payment_currency}
                  symbol={false}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
