import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const transactionsSelectors = {
  ...adapter.getSelectors((state) => state.transactions),
};

export const getMeta = createSelector(
  (state) => state?.transactions,
  (transactions) => transactions?.meta,
);

export const totalEntries = createSelector(
  (state) => state?.transactions?.meta,
  (meta) => meta?.total_entries,
);

export const currentPage = createSelector(
  (state) => state?.transactions?.meta,
  (meta) => meta?.current_page,
);
