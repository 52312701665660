import { createAsyncThunk } from '@reduxjs/toolkit';
import GiftsAPI from './services';

export const getGift = createAsyncThunk('gifts/getGift', async (id, thunkAPI) => {
  try {
    const response = await GiftsAPI.getGift(id);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const getAllGifts = createAsyncThunk('gifts/getAllGifts', async (params, thunkAPI) => {
  try {
    const response = await GiftsAPI.getAllGifts(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
