import axios from '../../utils/requestHelper';

const getProject = (id) => axios.get(`/guests/projects/${id}`);

const getAllProjects = (params) => axios.get(`/guests/projects`, { params });

const ProjectsAPI = {
  getProject,
  getAllProjects,
};

export default ProjectsAPI;
