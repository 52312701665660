const STEPS_NUMBER = 3;
const stepsMap = {
  image: 0,
  first_name: 0,
  last_name: 0,
  full_name: 0,
  email: 0,
  country_code: 1,
  phone_number_primary: 1,
  phone_number_secondary: 1,
  community_id: 1,
  has_whatsapp: 1,
  language_preference: 1,
  date_of_birth: 1,
  gender: 1,
  city: 2,
  state: 2,
  street_name: 2,
  zip: 2,
};

/*
FROM
{
  first_name
  last_name
  country_code
  phone_number_primary
  city
  state
}
TO
{
  step1: {
    first_name: "Mostafa",
    last_name: "Samir"
  },
  step2: ...
}
*/
export const formatDatawithStep = (obj, updateProfileSteps) => {
  let identifiers = updateProfileSteps.map((el) => el.identifier);
  let _Obj = {};
  identifiers.map((el) => {
    _Obj[el] = {};
  });
  Object.keys(obj).map((key) => {
    let stepId = identifiers[stepsMap[key]]; // step1 || step2 || ...
    if (!stepId) return;
    _Obj[stepId] = {
      ..._Obj[stepId],
      ...{ [key]: obj[key] },
    };
  });
  return _Obj;
};

export const getFieldStepIdentifier = (fieldKey, updateProfileSteps) => {
  let identifiers = updateProfileSteps.map((el) => el.identifier);
  let stepId = identifiers[stepsMap[fieldKey]]; // step1 || step2 || ...
  return stepId;
};
