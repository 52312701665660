import Dues from 'redux/dues';
import Sponsors from 'redux/sponsor';
import { useTranslation } from 'react-i18next';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Form, Checkbox, Row, Col, Radio, Space } from 'antd';
import CtaButton from 'components/atoms/ctaButton';
import PriceText from 'components/atoms/PriceText';
import { parseDate } from 'utils/date';
import LoadingList from 'components/molecules/LoadingList';

import style from './index.module.scss';
import Alert from 'components/atoms/Alert';

const LateDues = () => {
  const { t, i18n } = useTranslation('lateDues');
  const mediumScreen = useMedia(`(max-width: 768px)`);

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const history = useHistory();

  const [status, setStatus] = useState(1);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoadingoading] = useState(false);
  const [checked, setChecked] = useState({});
  const [errors, setErrors] = useState({});

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const sponsor = useSelector(Sponsors.selectors.getCurrentSponsor);
  const dues = useSelector(Dues.selectors.duesSelectors.selectAll);
  const duesErrors = useSelector(Dues.selectors.getDuesErrors);

  useEffect(() => {
    setLoading(true);
    dispatch(Dues.thunks.getLateDues());
    dispatch(Sponsors.thunks.getProfile());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [dispatch, i18n.language]);

  useEffect(() => {
    dues.map((el) => {
      setChecked((prevState) => {
        return {
          ...prevState,
          [el.id]: true,
        };
      });
    });
  }, [dues, status]);

  useEffect(() => {
    if (dues && dues.length === 0) history.push('/dashboard');
  }, [dues]);

  useEffect(() => {
    if (duesErrors) {
      setErrors(duesErrors);
      dispatch(Dues.slice.actions.clearErrors());
    }
  }, []);

  const radioHandler = (status) => {
    setStatus(status);
  };

  const calculateAppliedBalance = useCallback(() => {
    if (status == 2) {
      return Object.entries(checked).reduce(
        (acc, [id, value]) => (!value ? acc - dues.find((item) => item.id + '' === id)?.applied_balance : acc),
        dues.map((el) => el?.balance_applied == true && el?.applied_balance).reduce((acc, price) => acc + price, 0),
      );
    } else
      return dues
        .map((el) => el?.balance_applied == true && el?.applied_balance)
        .reduce((acc, price) => acc + price, 0);
  }, [checked]);

  const calculateTotal = () => dues.map((el) => el?.amount).reduce((acc, price) => acc + price, 0);

  const calculateSpecific = useCallback(() => {
    return Object.entries(checked).reduce(
      (acc, [id, value]) => (!value ? acc - dues.find((item) => item.id + '' === id)?.amount : acc),
      calculateTotal(),
    );
  }, [calculateTotal(), checked]);

  const renderAmountDue = () =>
    status == 2
      ? calculateAppliedBalance() > 0 && calculateSpecific().toFixed(2) >= calculateAppliedBalance()
        ? calculateSpecific().toFixed(2) - calculateAppliedBalance()
        : calculateSpecific().toFixed(2)
      : calculateTotal().toFixed(2) > calculateAppliedBalance()
      ? calculateTotal().toFixed(2) - calculateAppliedBalance()
      : 0;

  const onSubmit = useCallback(() => {
    setBtnLoadingoading(true);
    let duesIds = dues.filter((el) => checked[el.id]).map((el) => el.id);
    dispatch(Dues.thunks.addIdsToCheckout(duesIds));
    setTimeout(() => {
      history.push({
        pathname: '/late-dues-checkout',
        search: `?total=${renderAmountDue()}`,
      });
      setBtnLoadingoading(false);
    }, 1000);
  }, [renderAmountDue(), dues, status, checked]);

  const renderLateDues = () =>
    dues.map((el, index) => (
      <>
        <div className={style.lateDuesDataWrapperDanger}>
          {errors[el.id] ? <Alert type="alert-danger" value={t('checkout-error')} /> : null}
        </div>
        <div className={style.lateDuesDataWrapper}>
          <div>
            <input
              name="dues"
              type="checkbox"
              className={style.checkboxInput}
              id={index}
              value={el.amount}
              defaultChecked
              checked={checked[el.id] == true}
              disabled={status == 1}
              onChange={() => {
                setChecked({
                  ...checked,
                  [el.id]: !checked[el.id],
                });
              }}
            />
          </div>
          <div className={style.lateDuesData}>
            {!mediumScreen ? (
              <>
                <div className={style.childPayDate}>
                  <div>
                    <img src="/images/Line-blue.svg" />
                  </div>
                  {parseDate(el.due_date, i18n.language)}
                </div>
                <div className={style.childPrice}>
                  <PriceText price={el.amount} currency={sponsor.payment_currency} />
                </div>
                <div className={style.childIdName}>
                  <span>{el.sponsoree_code}</span>
                  {el.sponsoree_name}
                </div>
              </>
            ) : (
              <>
                <div className={style.datePriceWrapper}>
                  <div className={style.childPayDate}>
                    <div>
                      <img src="/images/Line-blue.svg" />
                    </div>
                    {parseDate(el.due_date, i18n.language)}
                  </div>
                  <div className={style.childPrice}>
                    <PriceText price={el.amount} currency={sponsor.payment_currency} />
                  </div>
                </div>
                <div className={style.childIdName}>
                  {el.sponsoree_code}
                  <span>{el.sponsoree_name}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    ));

  const renderLateDuesPay = () => (
    <div className={style.lateDuesPay}>
      <Form className="lateDues" form={form} onFinish={onSubmit} layout="vertical">
        <Radio defaultChecked checked={status === 1} onClick={(e) => radioHandler(1)}>
          {t('pay-total-amount')}
        </Radio>
        <Divider className={style.divider} />
        <Radio checked={status === 2} onClick={(e) => radioHandler(2)}>
          {t('pay-specific-amount')}
        </Radio>
        <Divider className={style.divider} />
        <div className={style.lateDuesTotal}>
          <div className={style.lateDuesTotalTitle}>{t('total')}</div>
          <div className={style.lateDuesTotalValue}>
            <PriceText
              price={status == 2 ? calculateSpecific().toFixed(2) : calculateTotal().toFixed(2)}
              currency={sponsor.payment_currency}
            />
          </div>
        </div>
        {calculateAppliedBalance() > 0 && (
          <div className={style.lateDuesTotal}>
            <div className={style.lateDuesTotalTitle}>{t('deducted-from-balance')}</div>
            <div className={`${style.lateDuesTotalValue} ${style.lateDuesTotalValueDanger}`}>
              <PriceText price={'- ' + calculateAppliedBalance()} currency={sponsor.payment_currency} />
            </div>
          </div>
        )}

        <div className={style.lateDuesTotal}>
          <div className={style.lateDuesTotalTitle}>{t('amount-due')}</div>
          <div className={style.lateDuesTotalValue}>
            <PriceText price={renderAmountDue()} currency={sponsor.payment_currency} />
          </div>
        </div>

        <Form.Item>
          <div className={style.payBtn}>
            <CtaButton
              type={'fill-primary-500'}
              text={t('pay')}
              customStyle={{
                minWidth: mediumScreen ? '180px' : '230px',
                minHeight: mediumScreen ? '46px' : '59px',
                fontSize: mediumScreen ? '1.063rem' : '1.375rem',
                fontWeight: 'normal',
              }}
              loading={btnLoading ? antIcon : ''}
              disabled={calculateSpecific() === 0}
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <div className={style.lateDuesWrapper}>
      <div className={style.lateDuesTitle}>
        <p>{t('late-dues')}</p>
      </div>
      <div className={style.lateDuesContentWrapper}>
        <Row className={style.lateDuesContentRow} gutter={30}>
          <Col lg={16} xs={24}>
            {!loading ? <div className={style.lateDuesContent}>{renderLateDues()}</div> : <LoadingList />}
          </Col>
          <Col lg={8} xs={24}>
            {renderLateDuesPay()}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LateDues;
