import { DateMask } from 'utils/inputMask';
import { getCountryDropDown } from '../../country';

const fields = (t) => [
  {
    item: {
      rules: [{ required: true, message: t('countryRequire') }],
      name: 'country_code',
      type: 'select',
    },
    inputProps: {
      label: t('countryLabel'),
      placeholder: t('country'),
      options: getCountryDropDown(),
      searchable: true,
      parse: (value) => value.toLocaleUpperCase(),
    },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [{ required: true, message: t('primaryPhoneNumberRequire') }],
      name: 'phone_number_primary',
      type: 'phoneNumber',
    },
    inputProps: {
      label: t('primaryPhoneNumberLabel'),
      placeholder: t('primaryPhoneNumber'),
    },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [],
      name: 'phone_number_secondary',
      type: 'phoneNumber',
    },
    inputProps: {
      label: t('secondaryPhoneNumberLabel'),
      placeholder: t('secondaryPhoneNumber'),
    },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [],
      name: 'community',
      type: 'community',
    },
    inputProps: { label: t('communityID'), placeholder: t('communityID') },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [{ required: true, message: t('whatsAppRequire') }],
      name: 'has_whatsapp',
      type: 'radio',
    },
    inputProps: {
      label: t('whatsAppLabel'),
      placeholder: t('whatsApp'),
      options: [
        { value: true, label: t('Yes') },
        { value: false, label: t('No') },
      ],
    },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [{ required: true, message: t('languagePreferenceRequire') }],
      name: 'language_preference',
      type: 'select',
    },
    inputProps: {
      label: t('languagePreferenceLabel'),
      placeholder: t('languagePreference'),
      options: [
        { value: 'english', label: t('English') },
        { value: 'arabic', label: t('Arabic') },
        { value: 'either', label: t('Either') },
      ],
    },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [{ required: false, message: t('dateOfBirthRequire') }, dateValidation(t)],
      name: 'date_of_birth',
      type: 'mask',
    },
    inputProps: {
      label: t('dateOfBirthLabel'),
      placeholder: t('dateOfBirth'),
      mask: DateMask,
      parse: parseDateValue,
      format: formatDateValue,
    },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
  {
    item: {
      rules: [{ required: true, message: t('genderRequire') }],
      name: 'gender',
      type: 'radio',
    },
    inputProps: {
      label: t('genderLabel'),
      placeholder: t('gender'),
      options: [
        { value: 'male', label: t('Male') },
        { value: 'female', label: t('Female') },
      ],
    },
    columns: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
  },
];
export default fields;

const fixDate = (value) => {
  if (!value) return value;

  let dateParts = value.replaceAll(' ', '').split('/');
  let date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

  return date;
};

const dateValidation = (t) => () => ({
  validator(_, value) {
    if (value === 'dd/mm/yyyy' || !value) return Promise.resolve();

    let date = fixDate(value);
    if (!(date instanceof Date && !isNaN(date))) return Promise.reject(t('dateOfBirthValid'));
    return Promise.resolve();
  },
});

const parseDateValue = (value) => {
  let date = new Date(value);

  if (!(date instanceof Date && !isNaN(date))) {
    return value;
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${day} / ${month} / ${year}`;
};

const formatDateValue = (value) => value.replaceAll(' ', '');
