import InputForm from 'components/atoms/InputForm';
import styles from './index.module.scss';
import { Col, Form as AntForm, Form, Row } from 'antd';
import CtaButton from 'components/atoms/ctaButton';
import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
const FormStep = ({
  stepData,
  stepValues,
  errorsList,
  onValuesChange,
  showSubmit = true,
  onStepSubmit,
  t,
  loading = false,
}) => {
  const [form] = Form.useForm();
  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const [formValues, setFormValues] = useState({});
  const [disabled, setDisabled] = useState(false);

  const handleFormChange = () => {
    const isFormValid = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(isFormValid);
  };

  useEffect(() => {
    setFormValues(stepValues);
  }, [stepData, stepValues]);

  const handleSubmit = () => {
    onStepSubmit(formValues, stepData.identifier);
    if (!stepData.submit) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const onValueChange = (values) => {
    let _formValues = {
      ...formValues,
      ...values,
    };
    setFormValues(_formValues);
    onValuesChange(_formValues, stepData.identifier);
  };

  const renderFormButton = () => (
    <CtaButton
      text={stepData.submit ? t('signup') : t('Next')}
      onClickType="submit"
      type={stepData.submit ? 'fill-secondary' : 'fill-primary'}
      customStyle={{ padding: '17px 60px' }}
      loading={stepData.submit && loading ? antIcon : ''}
      disabled={disabled}
    />
  );

  const renderInputs = () => (
    <div className={styles.formStepFields}>
      <Row gutter={[40, { xs: 0, md: 16 }]}>
        {stepData.fields(t).map((field) => {
          return (
            <Col {...field.columns}>
              <InputForm
                label={field.inputProps.label}
                name={field.item.name}
                placeholder={field.inputProps.placeholder}
                type={field.item.type}
                customStyle={field.style}
                rules={field.item.rules}
                error={errorsList && errorsList[field.item.name]}
                options={field.inputProps.options || []}
                mask={field.inputProps.mask || ''}
                parse={field.inputProps.parse || undefined}
                format={field.inputProps.format || undefined}
                searchable={field.inputProps.searchable || false}
                dependencies={field.inputProps.dependencies || undefined}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );

  const renderForm = () => (
    <AntForm
      name="form"
      layout="vertical"
      initialValues={stepValues}
      onValuesChange={onValueChange}
      onFinish={handleSubmit}
      onFieldsChange={handleFormChange}
      form={form}
    >
      {renderInputs()}
      <div className={styles.formStepButton}>{renderFormButton()}</div>
    </AntForm>
  );

  return <>{showSubmit ? renderForm() : renderInputs()}</>;
};

export default FormStep;
