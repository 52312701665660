import { Layout } from 'antd';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useLocation, matchPath } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import Header from 'components/molecules/Header';
import Footer from 'components/molecules/Footer';
import './index.scss';

const pathNamesBGWhite = [
  {
    path: '/',
  },
  {
    path: '/who-we-are',
  },
  {
    path: '/donate',
  },
  {
    path: '/projects',
  },
  {
    path: '/projects/:id',
  },
  {
    path: '/children',
    maxWidth: '768',
  },
  {
    path: '/children',
  },
  {
    path: '/children/:id',
  },
  {
    path: '/contact-us',
  },
];
const DefaultLayout = ({ children }) => {
  const screenSize = useWindowSize();
  const matchAllowedWidth = (min, max) => {
    if (min) {
      if (min < screenSize.width) return true;
      else return false;
    }
    if (max) {
      if (max > screenSize.width) return true;
      else return false;
    }
    return true;
  };
  const location = useLocation();
  const [IsBackgroundWhite, setIsBackgroundWhite] = useState(false);
  useEffect(() => {
    const data = pathNamesBGWhite.reduce(function (accumulator, currentValue) {
      return (
        (matchPath(location.pathname, currentValue.path)?.isExact &&
          matchAllowedWidth(currentValue.minWidth, currentValue.maxWidth)) ||
        accumulator
      );
    }, false);
    setIsBackgroundWhite(data);
  }, [location.pathname]);

  return (
    <Layout style={IsBackgroundWhite ? { background: '#ffffff' } : { background: '#f6f8f9' }}>
      <Header />
      <Layout.Content>
        <TransitionGroup>
          <CSSTransition key={location} timeout={1000} classNames="fade">
            <div className="site-layout">{children}</div>
          </CSSTransition>
        </TransitionGroup>
      </Layout.Content>
      <Footer />
    </Layout>
  );
};

export default DefaultLayout;
