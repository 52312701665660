import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const purposesSelectors = {
  ...adapter.getSelectors((state) => state.purposes),
};

export const getMeta = createSelector(
  (state) => state?.purposes,
  (projects) => projects?.purposes,
);
