import styles from './index.module.scss';

const Alert = ({ type, value }) => {
  return (
    <div className={`${styles.alertWrapper} ${styles[type]}`}>
      <div className={styles.alertIcon} />
      <div className={styles.alertValue}>{value}</div>
    </div>
  );
};

export default Alert;
