import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const sponsorSelectors = {
  ...adapter.getSelectors((state) => state.sponsors),
};

export const getCurrentSponsor = createSelector(
  (state) => state?.sponsors,
  (sponsors) => sponsors.currentSponsor,
);

export const getMessages = createSelector(
  (state) => state?.sponsors,
  (sponsors) => sponsors?.messages,
);
