import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { getAllPurposes } from './thunks';

export const slice = createSlice({
  name: 'purpose',
  initialState: adapter.getInitialState({ meta: {} }),
  extraReducers: (builder) => {
    builder.addCase(getAllPurposes.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.purposes) return;
      state.meta = payload.data.meta;
      adapter.setAll(state, data.purposes);
    });
  },
});
const Purposes = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Purposes;
