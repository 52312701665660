import { Typography } from 'antd';
import style from './index.module.scss';

const SectionHeader = ({ text, customTextStyle, customstyle }) => (
  <div className={style.sectionHeader} style={customstyle}>
    <div className={style.sectionHeaderText}>
      <Typography style={customTextStyle}>{text}</Typography>
    </div>
    <div className={style.sectionHeaderImage}>
      <img src="/images/title-line.svg" />
    </div>
  </div>
);

export default SectionHeader;
