import HelmetTag from 'components/atoms/Helmet';
import SectionHeader from 'components/atoms/SectionHeader';
import TextSection from 'components/atoms/TextSection';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import style from './index.module.scss';
import { Protection } from 'utils/data/childProtection';

const ChildProtection = () => {
  const { t, i18n } = useTranslation('child-protection');
  const smallScreen = useMedia(`(max-width: 576px)`);

  return (
    <div className={style.childProtectionWrapper}>
      <HelmetTag
        title="Child Protection Policy"
        description="Healing Grace Child Protection Policy"
        url="child-protection"
        image_url="https://company-assets.fra1.cdn.digitaloceanspaces.com/healing-grace/logo.png"
      />
      <SectionHeader text={t('main-title')} />

      {Protection.length > 0 &&
        Protection.map((section, index) => (
          <TextSection
            text={section.header?.[i18n.language]}
            value={section.para?.[i18n.language]}
            customStyle={{ whiteSpace: 'break-spaces' }}
          />
        ))}
    </div>
  );
};

export default ChildProtection;
