import step1Fields from './steps/step1Fields';
import step2Fields from './steps/step2Fields';
import step3Fields from './steps/step3Fields';

const signupSteps = (t) => [
  {
    identifier: 'step1',
    name: t('accountInformation'),
    submit: false,
    number: 1,
    fields: step1Fields,
  },
  {
    identifier: 'step2',
    name: t('personalInformation'),
    submit: false,
    number: 2,
    fields: step2Fields,
  },
  {
    identifier: 'step3',
    name: t('addressinformation'),
    submit: false,
    number: 3,
    fields: step3Fields,
  },
];

export default signupSteps;
