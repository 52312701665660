import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Sponsor from 'redux/sponsor';
import style from './index.module.scss';

const GiftCart = ({ title, price, image, addToCart, animationState = 'SUCCESS' }) => {
  const { t } = useTranslation('giftCard');
  const [showAnimation, setShowAnimation] = useState(false);

  const sponsor = useSelector(Sponsor.selectors.getCurrentSponsor);

  const onCartClick = () => {
    setShowAnimation(true);
    addToCart();
    setTimeout(() => {
      setShowAnimation(false);
    }, 2000);
  };

  const getPrice = () =>
    price && !isNaN(price) ? (
      sponsor?.payment_currency === 'egp' ? (
        <span>
          {price.toFixed(2)}
          {t('le')}
        </span>
      ) : (
        <span className="dollarSymbol">
          {price.toFixed(2)}
          {t('$')}
        </span>
      )
    ) : (
      ''
    );

  return (
    <div className={style.giftCardWrapper}>
      <div className={style.giftCard}>
        <div
          className={`${style.giftCardToaster} ${
            animationState === 'SUCCESS' ? style.giftCardToasterSucces : style.giftCardToasterFail
          } ${!showAnimation && style.giftCardToasterHide}`}
        >
          <img src={animationState === 'SUCCESS' ? '/images/green-sucess.svg' : '/images/failure-confirm.svg'} />
          <span>{animationState === 'SUCCESS' ? t('itemAdded') : t('noSponsorShip')}</span>
        </div>

        <div className={style.giftCardImage}>
          <img src={image} />
        </div>

        <div className={style.giftCardBottom}>
          <div className={style.giftCardBottomInfo}>
            <h4>{title}</h4>
            <p>{getPrice()}</p>
          </div>
          <div
            className={style.giftCardBottomIcon}
            onClick={() => {
              onCartClick();
            }}
          >
            <img src="/images/cart.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCart;
