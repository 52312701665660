import { createAsyncThunk } from '@reduxjs/toolkit';
import DuesAPI from './services';

export const getAllDues = createAsyncThunk('dues/getAllDues', async (params, thunkAPI) => {
  try {
    const response = await DuesAPI.getAllDues(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const getLateDues = createAsyncThunk('dues/getLateDues', async (params, thunkAPI) => {
  try {
    const response = await DuesAPI.getLateDues(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const payLateDues = createAsyncThunk('dues/payLateDues', async ({ due }, { rejectWithValue }) => {
  try {
    const response = await DuesAPI.payLateDues({ due });
    return { data: response.data };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addIdsToCheckout = createAsyncThunk('dues/addIdsToCheckout', async (data, thunkAPI) => {
  try {
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
