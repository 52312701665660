import Sponsor from 'redux/sponsor';
import Cart from 'redux/cart';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import FormCard from 'components/molecules/FormCard';
import InputForm from 'components/atoms/InputForm';
import CtaButton from 'components/atoms/ctaButton';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const CompleteProfile = () => {
  const { t } = useTranslation('completeProfile');
  const mediumScreen = useMedia(`(max-width: 768px)`);

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const { online_access_token } = useParams();

  const [errorList, setErrorList] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const handleFormChange = () => {
    const isFormValid = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(isFormValid);
  };

  const passwordMatchValidation =
    (t) =>
    ({ getFieldValue }) => ({
      validator(_, value) {
        let password = getFieldValue('password');
        if (password === value) return Promise.resolve();
        else return Promise.reject(t('confirm-password-validation'));
      },
    });
  const logout = () => {
    dispatch(Sponsor.slice.actions.logout());
    dispatch(Cart.slice.actions.updateCart());
    history.push('/sign-in');
  };

  const clearErrors = (name) => {
    setErrorList((prevState) => {
      delete prevState[name];
      return prevState;
    });
  };

  const onSubmit = useCallback(
    (values) => {
      setLoading(true);
      var formData = new FormData();
      formData.append('online_access_token', online_access_token);
      formData.append('password', values.password);
      formData.append('password_confirmation', values.password_confirmation);
      formData.append('image', values.image ? values.image : '');
      dispatch(Sponsor.thunks.completeProfile(formData)).then((res) => {
        if (!res.payload.error) {
          logout();
        } else setErrorList(res.payload);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    },
    [dispatch, history],
  );

  const renderForm = () => (
    <div className={styles.formWrapper}>
      <div className={`${styles.form} ${'completeForm'}`}>
        <Form
          className={styles.formInputs}
          onFieldsChange={handleFormChange}
          onFinish={onSubmit}
          form={form}
          layout="vertical"
        >
          <InputForm name="image" type="upload" accessor="photo_url" accept="image/jpeg,image/png" canRemove="true" />

          <InputForm
            label={t('password')}
            name="password"
            placeholder={t('password')}
            type="password"
            error={errorList.password}
            clearError={clearErrors}
            rules={[
              { required: true, message: t('password-required') },
              { pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{6,}$/, message: t('password-validation') },
            ]}
          />

          <InputForm
            label={t('password-confirmation')}
            name="password_confirmation"
            placeholder={t('password-confirmation')}
            type="password"
            error={errorList.password_confirmation}
            clearError={clearErrors}
            rules={[passwordMatchValidation(t)]}
            dependencies={['password']}
          />

          <Form.Item>
            <div className={styles.sendBtn}>
              <CtaButton
                type={'fill-secondary'}
                onClickType="submit"
                full={true}
                text={t('complete-profile')}
                customStyle={{
                  minWidth: mediumScreen ? '180px' : '230px',
                  minHeight: mediumScreen ? '46px' : '59px',
                  fontSize: mediumScreen ? '1.063rem' : '1.375rem',
                  fontWeight: 'normal',
                }}
                loading={loading ? antIcon : ''}
                disabled={disabled}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );

  return (
    <div className={styles.completeProfileWrapper}>
      <div className={styles.completeProfileForm}>
        <FormCard title={t('complete-your-profile')} form={renderForm()} />
      </div>
    </div>
  );
};

export default CompleteProfile;
