import Sponsor from 'redux/sponsor';
import Cart from 'redux/cart';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import style from './index.module.scss';

const AccountConfirmation = () => {
  const { t } = useTranslation('account-confirmation');
  const history = useHistory();
  const dispatch = useDispatch();

  const [isSuccess, setIsSuccess] = useState(null);
  const [counter, setCounter] = useState(5);

  const redirectToPage = (path) => {
    history.push(path);
  };
  const logout = () => {
    dispatch(Sponsor.slice.actions.logout());
    dispatch(Cart.slice.actions.updateCart());
    history.push('/sign-in');
  };
  useEffect(() => {
    if (isSuccess) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      counter == 0 && setTimeout(() => logout(), 1000);
    }
  }, [counter, isSuccess]);

  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);

    const success = queryParams.get('account_confirmation_success');

    if (success === 'true') {
      setIsSuccess(true);
    } else if (success === 'false') {
      setIsSuccess(false);
    }
  }, []);

  return (
    <div className={style.confirmationWrapper}>
      <div className={style.confirmationIcon}>
        <img src={isSuccess ? 'images/success-confirm.svg' : 'images/failure-confirm.svg'} />
      </div>
      <div className={style.confirmationText}>
        <p>{isSuccess ? t('success-text') : t('failure-text')}</p>
      </div>
      <div className={`${style.confirmationRedirect} ${isSuccess ? style.successConfirmation : ''}`}>
        {!isSuccess && (
          <span
            className={style.clickHere}
            onClick={() => {
              redirectToPage('/resend-confirmation');
            }}
          >
            {t('click-here')}
          </span>
        )}
        {isSuccess ? t('success-redirect') : t('failure-redirect')}
        {isSuccess && <span>{counter}</span>}
      </div>
    </div>
  );
};

export default AccountConfirmation;
