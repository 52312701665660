import FormCard from 'components/molecules/FormCard';
import ProgressSteps from 'components/molecules/ProgressSteps';
import styles from './index.module.scss';
import signupSteps from '../../../utils/signupSteps';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import FormStep from 'components/molecules/FormStep';
import { useDispatch } from 'react-redux';
import SponsorRedux from 'redux/sponsor';
import { navigateToFirstStepinList } from 'utils/signupSteps/stepMapper';
import { useHistory } from 'react-router-dom';

const SignUp = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('signup');
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [errorsList, setErrorsList] = useState({});
  const [fieldData, setFieldSData] = useState({});
  // {
  //   step1: {firstName: "", lastName: ""},
  //   step2: {phone: "", fax: ""}
  // }

  // login Function
  const redirect = (path) => {
    history.push(path);
  };
  const getCurrentStep = () => {
    return signupSteps(t)[currentStep];
  };

  const getCurrentStepValues = () => {
    let id = getCurrentStep().identifier;
    return fieldData[id];
  };

  const onStepChange = (step = 1) => {
    // in this function steps will chagne with index
    setCurrentStep(step);
  };

  const formatDataValue = (data) => {
    let _data = Object.keys(data)
      .map((key) => data[key])
      .reduce((result, current) => Object.assign(result, current), {});
    if (_data.community_id && _data.community_id.id) _data.community_id = _data.community_id.id;
    else delete _data.community_id;
    if (_data.phone_number_primary) {
      _data['phone_number_primary[code]'] = _data.phone_number_primary.code;
      _data['phone_number_primary[number]'] = _data.phone_number_primary.number;
    }
    if (_data.phone_number_secondary) {
      _data['phone_number_secondary[code]'] = _data.phone_number_secondary.code;
      _data['phone_number_secondary[number]'] = _data.phone_number_secondary.number;
    }
    if (_data.date_of_birth === 'dd/mm/yyyy') delete _data.date_of_birth;
    delete _data.phone_number_primary;
    delete _data.phone_number_secondary;
    return _data;
  };

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const getErrorList = (errors) => {
    return Object.keys(errors);
  };

  const onValuesChange = (data, identifier) => {
    setFieldSData((prevState) => {
      return {
        ...prevState,
        [identifier]: data,
      };
    });
  };

  const onStepSubmit = (data, identifier) => {
    // bind data if validate to the fieldData with identifier as key :''''
    setFieldSData((prevState) => {
      return {
        ...prevState,
        [identifier]: data,
      };
    });
    if (getCurrentStep().submit) {
      setLoading(true);
      let _data = {
        ...fieldData,
        [identifier]: data,
      };
      let postData = formatDataValue(_data);
      let formData = getFormData(postData);
      dispatch(SponsorRedux.thunks.signUp(formData)).then((res) => {
        if (!res.error) {
          redirect('/sign-in');
        } else {
          let { errors } = res.payload.response.data;
          setErrorsList(errors);
          let errorList = getErrorList(errors);
          let firstStepError = navigateToFirstStepinList(errorList);
          onStepChange(firstStepError);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
      return;
    } else {
      onStepChange(currentStep + 1);
    }
    setErrorsList({});
  };

  // render view function
  const renderProgressBar = () => (
    <ProgressSteps steps={signupSteps(t)} currentStep={currentStep} onStepChange={onStepChange} disableNext={true} />
  );

  const renderFormStep = () => (
    <FormStep
      stepData={getCurrentStep()}
      stepValues={getCurrentStepValues()}
      errorsList={errorsList}
      onValuesChange={onValuesChange}
      onStepSubmit={onStepSubmit}
      t={t}
      loading={loading}
    />
  );

  const renderForm = () => (
    <>
      {renderProgressBar()}
      {renderFormStep()}
      <div className={styles.forCardBottom}></div>
    </>
  );

  return (
    <div className={styles.signupWrapper}>
      <div className={styles.signup}>
        <div className={styles.formCard}>
          <FormCard title={t('signup')} form={renderForm()} />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
