import Sponsor from 'redux/sponsor';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import FormCard from 'components/molecules/FormCard';
import InputForm from 'components/atoms/InputForm';
import CtaButton from 'components/atoms/ctaButton';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const UpdateEmail = () => {
  const { t } = useTranslation('changeEmail');
  const mediumScreen = useMedia(`(max-width: 768px)`);

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const [errorList, setErrorList] = useState({});

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;
  const handleFormChange = () => {
    const isFormValid = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(isFormValid);
  };

  const clearErrors = (name) => {
    setErrorList((prevState) => {
      delete prevState[name];
      return prevState;
    });
  };

  const onSubmit = useCallback(
    (values) => {
      setLoading(true);
      var formData = new FormData();
      formData.append('sponsor[email]', values.email);
      formData.append('sponsor[current_password]', values.current_password);
      dispatch(Sponsor.thunks.updateEmail(formData)).then((res) => {
        if (!res.payload.errors) {
          history.push('/profile');
        } else {
          setErrorList(res.payload.errors);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    },
    [dispatch, history],
  );

  const renderForm = () => (
    <div className={styles.formWrapper}>
      <div className={styles.form}>
        <Form
          className={styles.formInputs}
          onFieldsChange={handleFormChange}
          onFinish={onSubmit}
          form={form}
          layout="vertical"
        >
          <InputForm
            label={t('enter-password')}
            name="current_password"
            placeholder={t('password')}
            type="password"
            error={errorList.password}
            clearError={clearErrors}
            rules={[{ required: true, message: t('password-required') }]}
          />

          <InputForm
            label={t('new-email')}
            name="email"
            placeholder="Example@example.com"
            error={errorList.email}
            clearError={clearErrors}
            rules={[
              { type: 'email', message: t('email-validation-message') },
              { required: true, message: t('email-required-message') },
            ]}
          />

          <Form.Item>
            <div className={styles.submitBtn}>
              <CtaButton
                type={'fill-primary-500'}
                onClickType="submit"
                text={t('submit')}
                customStyle={{
                  minWidth: mediumScreen ? '180px' : '230px',
                  minHeight: mediumScreen ? '46px' : '59px',
                  fontSize: mediumScreen ? '1.063rem' : '1.375rem',
                  fontWeight: 'normal',
                }}
                loading={loading ? antIcon : ''}
                disabled={disabled}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );

  return (
    <div className={styles.changeEmailWrapper}>
      <FormCard title={t('change-email')} form={renderForm()} backRoute={'profile'} type={'full-white-color'} />
    </div>
  );
};

export default UpdateEmail;
