import { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import GuestRoute from 'router/GuestRoute';
import SponsorRoute from 'router/SponsorRoute';
import HomePage from 'screens/Guest/Homepage';
import WhoWeAre from 'screens/Guest/WhoWeAre';
import Profile from 'screens/Admin/Profile';
import UpdateProfile from 'screens/Admin/UpdateProfile';
import Donation from 'screens/Guest/Donation';
import NotFound from 'screens/Guest/404';
import PrivacyPolicy from 'screens/Guest/PrivacyPolicy';
import TermsConditions from 'screens/Guest/TermsConditions';
import ChildProtection from 'screens/Guest/ChildProtection';
import FAQs from 'screens/Guest/FAQs';
import Projects from 'screens/Guest/Projects';
import SingleCild from 'screens/Guest/SingleChild';
import SingleProject from 'screens/Guest/SingleProject';
import SignUp from 'screens/Auth/SignUp';
import Children from 'screens/Guest/Children';
import SignIn from 'screens/Auth/SignIn';

import DefaultLayout from 'layouts/DefaultLayout';
import CompleteProfile from 'screens/Auth/CompleteProfile';
import ServerError from 'screens/Guest/500';
import ResendConfirmation from 'screens/Auth/ResendConfirmation';
import ForgetPassword from 'screens/Auth/ForgetPassword';
import ResetPassword from 'screens/Auth/ResetPassword';
import AccountConfirmation from 'screens/Auth/AccountConfirmation';
import ChangePassword from 'screens/Admin/ChangePassword';
import UpdateEmail from 'screens/Admin/UpdateEmail';
import AuthRoute from './AuthRoute';
import Gift from 'screens/Admin/Gift';
import Sponsorships from 'screens/Admin/Sponsorships';
import MyDonations from 'screens/Admin/MyDonations';
import SponsoredSingleChild from 'screens/Admin/SponsoredSingleChild';
import Dashboard from 'screens/Admin/Dashboard';
import PaymentConfirmatioin from 'screens/Admin/PaymentConfirmation';
import CardConfirmatioin from 'screens/Admin/CardConfirmation';
import LateDues from 'screens/Admin/LateDues';
import Letters from 'screens/Admin/Letters';
import SendLetters from 'screens/Admin/SendLetters';
import MyCards from 'screens/Admin/MyCards';
import Cart from 'screens/Admin/Cart';
import LateDuesCheckout from 'screens/Admin/LateDuesCheckout';
import StatementOfFaith from 'screens/Guest/StatementOfFaith';
import ContactUs from 'screens/Guest/ContactUs';

export const history = createBrowserHistory();

const Router = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <Route
      render={() => (
        <Switch>
          <SponsorRoute exact path="/profile" component={Profile} />
          <SponsorRoute exact path="/my-transactions" component={MyDonations} />
          <SponsorRoute exact path="/gift" component={Gift} />
          <SponsorRoute exact path="/change-password" component={ChangePassword} />
          <SponsorRoute exact path="/update-email" component={UpdateEmail} />
          <SponsorRoute exact path="/sponsorships/:id" component={SponsoredSingleChild} />
          <SponsorRoute exact path="/sponsorships" component={Sponsorships} />
          <SponsorRoute exact path="/update-profile" component={UpdateProfile} />
          <SponsorRoute exact path="/dashboard" component={Dashboard} />
          <SponsorRoute exact path="/late-dues" component={LateDues} />
          <SponsorRoute exact path="/late-dues-checkout" component={LateDuesCheckout} />
          <SponsorRoute exact path="/letters/:id" component={Letters} />
          <SponsorRoute exact path="/send-letters/:id" component={SendLetters} />
          <SponsorRoute exact path="/my-cards" component={MyCards} />
          <SponsorRoute exact path="/cart" component={Cart} />

          <AuthRoute exact path="/sign-up" component={SignUp} />
          <AuthRoute exact path="/sign-in" component={SignIn} />
          <AuthRoute exact path="/resend-confirmation" component={ResendConfirmation} />
          <AuthRoute exact path="/forget-password" component={ForgetPassword} />

          <GuestRoute exact path="/sponsors/passwords" component={ResetPassword} />
          <GuestRoute exact path="/payment" component={PaymentConfirmatioin} />
          <GuestRoute exact path="/card" component={CardConfirmatioin} />
          <GuestRoute exact path="/complete-profile/:online_access_token" component={CompleteProfile} />
          <GuestRoute exact path="/confirmation" component={AccountConfirmation} />
          <GuestRoute exact path="/who-we-are" component={WhoWeAre} />
          <GuestRoute exact path="/projects" component={Projects} />
          <GuestRoute exact path="/children" component={Children} />
          <GuestRoute exact path="/donate" component={Donation} />
          <GuestRoute exact path="/contact-us" component={ContactUs} />
          <GuestRoute exact path="/children/:id" component={SingleCild} />
          <GuestRoute exact path="/projects/:id" component={SingleProject} />
          <GuestRoute exact path="/privacy-policy" component={PrivacyPolicy} />
          <GuestRoute exact path="/statement-of-faith" component={StatementOfFaith} />
          <GuestRoute exact path="/terms-conditions" component={TermsConditions} />
          <GuestRoute exact path="/child-protection" component={ChildProtection} />
          <GuestRoute exact path="/faqs" component={FAQs} />
          <GuestRoute exact path="/500" component={ServerError} />
          <GuestRoute exact path="/" component={HomePage} />
          <GuestRoute exact path="*" component={NotFound} />
        </Switch>
      )}
    />
  );
};

export default Router;
