import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { getAllSponsorships, getSponsorship, getNextPayment, cancelSponsorship } from './thunks';

export const slice = createSlice({
  name: 'sponsorships',
  initialState: adapter.getInitialState({ meta: {}, nextPayment: [] }),
  extraReducers: (builder) => {
    builder.addCase(getSponsorship.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.sponsorship) return;
      adapter.setOne(state, data.sponsorship);
    });
    builder.addCase(getSponsorship.rejected, (state, action) => {
      let { message } = action.error;
      if (message === 'Rejected') window.location.href = '/404';
    });
    builder.addCase(getAllSponsorships.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.sponsorships) return;
      state.meta = payload.data.meta;
      adapter.setAll(state, data.sponsorships);
    });
    builder.addCase(getNextPayment.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.sponsorships) return;
      state.nextPayment = data.sponsorships;
    });
    builder.addCase(cancelSponsorship.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.sponsor) return;
      adapter.addOne(state, data.sponsor);
    });
  },
});
const Sponsorships = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Sponsorships;
