import HelmetTag from 'components/atoms/Helmet';
import SectionHeader from 'components/atoms/SectionHeader';
import TextSection from 'components/atoms/TextSection';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import style from './index.module.scss';
import { statementOfFaith } from 'utils/data/statementOfFaith';

const StatementOfFaith = () => {
  const { t, i18n } = useTranslation('statement-of-faith');
  const smallScreen = useMedia(`(max-width: 576px)`);

  return (
    <div className={style.statementOfFaithWrapper}>
      <HelmetTag
        title="Statement of faith"
        description="Healing Grace Statement Of Faith"
        url="statement-of-faith"
        image_url="https://company-assets.fra1.cdn.digitaloceanspaces.com/healing-grace/logo.png"
      />
      <SectionHeader text={t('main-title')} />
      {statementOfFaith.length > 0 &&
        statementOfFaith.map((statementOfFaith, index) => (
          <TextSection
            text={statementOfFaith.header?.[i18n.language]}
            value={statementOfFaith.para?.[i18n.language]}
            customStyle={
              index === 0
                ? { marginTop: smallScreen ? '-45px' : '-60px', whiteSpace: 'break-spaces' }
                : { whiteSpace: 'break-spaces' }
            }
          />
        ))}
    </div>
  );
};

export default StatementOfFaith;
