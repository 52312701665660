import { Divider } from 'antd';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';

import style from './index.module.scss';

const Footer = () => {
  const { t } = useTranslation('footer');
  const largeScreen = useMedia(`(max-width: 992px)`);

  const MenuItems = [
    {
      link: '/who-we-are',
      name: t('who-we-are'),
    },
    {
      link: '/projects',
      name: t('projects'),
    },
    {
      link: '/donate',
      name: t('donate'),
    },
    {
      link: '/faqs',
      name: t('faqs'),
    },
    {
      link: '/contact-us',
      name: t('contact-us'),
    },
  ];

  const renderFooterContent = () => (
    <div className={style.footerContent}>
      <div className={style.footerContentLogo}>
        <a href="/">
          <img src="/logo.svg" />
        </a>
      </div>
      <div className={style.footerContentMenuItems}>
        {MenuItems.map((item, index) => (
          <>
            <Link key={index} to={item.link}>
              {item.name}
            </Link>
            <Divider className={style.antDivider} type="vertical" />
          </>
        ))}
      </div>
      <div className={style.footerContactInfo}>
        <div className={style.footerContactInfoPhone}>
          <div>
            <img src="/images/phone-blue.svg" />
          </div>
          <div>
            <div>
              <div>
                <img className={style.footerFlagImage} src="/images/egypt.svg" />
              </div>
              <div>
                <a className={style.footerFlagPhone} href="tel:+201211966868">
                  +201211966868
                </a>
              </div>
            </div>
            <div>
              <div>
                <img className={style.footerFlagImage} src="/images/USA.svg" />
              </div>
              <div>
                <a href="tel:(+1)704-228-3820">(+1)704-228-3820</a>
              </div>
            </div>
          </div>
        </div>
        <div className={style.footerContactInfoEmail}>
          <img src="/images/email-blue.svg" />
          <a href="mailto:communications@hgegypt.org">communications@hgegypt.org</a>
        </div>
      </div>
    </div>
  );

  const renderFooterInfo = () => (
    <div className={style.footerInfo}>
      <div className={style.footerInfoWrapper}>
        <div className={style.footerInfoLeft}>
          <div>{t('copyright')}</div>
          {largeScreen && <div>{t('HG-definition')}</div>}
        </div>
        <div className={style.footerInfoMiddle}>
          {t('developed-by')}{' '}
          <a href="https://thewebops.com/" target="_blank" rel="noreferrer">
            WebOps
          </a>
        </div>
        <div className={style.footerInfoRight}>
          <a href="/statement-of-faith">{t('statement-of-faith')}</a>
          <Divider className={style.antDivider} type="vertical" />
          <a href="/privacy-policy">{t('privacy-policy')}</a>
          <Divider className={style.antDivider} type="vertical" />
          <a href="/terms-conditions">{t('terms-conditions')}</a>
          <Divider className={style.antDivider} type="vertical" />
          <a href="/child-protection">{t('child-protection-policy')}</a>
        </div>
      </div>
      {!largeScreen && <div>{t('HG-definition')}</div>}
    </div>
  );

  return (
    <div className={style.footerWrapper}>
      {renderFooterContent()}
      {renderFooterInfo()}
    </div>
  );
};

export default Footer;
