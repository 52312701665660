import { createAsyncThunk } from '@reduxjs/toolkit';
import CardsAPI from './services';

export const getAllCards = createAsyncThunk('cards/getAllCards', async (params, thunkAPI) => {
  try {
    const response = await CardsAPI.getAllCards(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const createCard = createAsyncThunk('sponsors/createCard', async (thunkAPI, { rejectWithValue }) => {
  try {
    const response = await CardsAPI.createCard();
    return { data: response.data };
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const updateDefaultCard = createAsyncThunk('cards/updateDefaultCard', async (id, thunkAPI) => {
  try {
    const response = await CardsAPI.updateDefaultCard(id);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const removeCard = createAsyncThunk('cards/removeCard', async (id, thunkAPI) => {
  try {
    const response = await CardsAPI.removeCard(id);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
