import { createAsyncThunk } from '@reduxjs/toolkit';
import SponsorshipsAPI from './services';

export const getSponsorship = createAsyncThunk('sponsorships/getSponsorship', async ({ id, params }, thunkAPI) => {
  try {
    const response = await SponsorshipsAPI.getSponsorship({ id, params });
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const getAllSponsorships = createAsyncThunk('sponsorships/getAllSponsorships', async (params, thunkAPI) => {
  try {
    const response = await SponsorshipsAPI.getAllSponsorships(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const getNextPayment = createAsyncThunk('sponsorships/getNextPayment', async (params, thunkAPI) => {
  try {
    const response = await SponsorshipsAPI.getAllSponsorships(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});

export const cancelSponsorship = createAsyncThunk(
  'sponsorships/cancelSponsorship',
  async ({ formData, id }, { rejectWithValue }) => {
    try {
      const response = await SponsorshipsAPI.cancelSponsorship(formData, id);
      return { data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
