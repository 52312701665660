import HelmetTag from 'components/atoms/Helmet';
import SectionHeader from 'components/atoms/SectionHeader';
import TextSection from 'components/atoms/TextSection';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import style from './index.module.scss';
import { Privacy } from 'utils/data/privacy';

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation('privacy-policy');
  const smallScreen = useMedia(`(max-width: 576px)`);

  return (
    <div className={style.privacyPolicyWrapper}>
      <HelmetTag
        title="Privacy Policy"
        description="Healing Grace Privacy Policy"
        url="privacy-policy"
        image_url="https://company-assets.fra1.cdn.digitaloceanspaces.com/healing-grace/logo.png"
      />
      <SectionHeader text={t('main-title')} />
      {Privacy.length > 0 &&
        Privacy.map((privacy, index) => (
          <TextSection
            text={privacy.header?.[i18n.language]}
            value={privacy.para?.[i18n.language]}
            customStyle={
              index === 0
                ? { marginTop: smallScreen ? '-45px' : '-60px', whiteSpace: 'break-spaces' }
                : { whiteSpace: 'break-spaces' }
            }
          />
        ))}
    </div>
  );
};

export default PrivacyPolicy;
