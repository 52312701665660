import axios from '../../utils/requestHelper';

const getGift = (id) => axios.get(`/sponsors/gifts/${id}`);

const getAllGifts = (params) => axios.get(`/sponsors/gifts`, { params });

const GiftsAPI = {
  getGift,
  getAllGifts,
};

export default GiftsAPI;
