import axios from '../../utils/requestHelper';

const getAllDues = (params) => axios.get(`/sponsors/dues`, { params });
const getLateDues = (params) => axios.get(`/sponsors/late_dues`, { params });
const payLateDues = (id) => axios.put(`/sponsors/dues/statuses`, id);

const DuesAPI = {
  getAllDues,
  getLateDues,
  payLateDues,
};

export default DuesAPI;
