import axios from '../../utils/requestHelper';

const getAllCards = (params) => axios.get(`/sponsors/payment_methods`, { params });

const createCard = () => axios.post(`/sponsors/payment_methods/`);

const updateDefaultCard = (id) => axios.put(`/sponsors/payment_methods/${id}`);

const removeCard = (id) => axios.delete(`/sponsors/payment_methods/${id}`);

const CardsAPI = {
  getAllCards,
  createCard,
  updateDefaultCard,
  removeCard,
};

export default CardsAPI;
