import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import CtaButton from 'components/atoms/ctaButton';
import { ReactComponent as LargeCloseIcon } from 'assets/images/largeCloseIcon.svg';
import { ReactComponent as MediumCloseIcon } from 'assets/images/mediumCloseIcon.svg';
import PaymentCards from 'redux/cards';
import { Modal } from 'antd';
import style from './index.module.scss';

const PaymentCard = ({
  cardId,
  defaultCard,
  cardName,
  cardNumber,
  cardExpires,
  cardsLength,
  selected = false,
  hasBtns = false,
  changeMessageError,
}) => {
  const { t } = useTranslation('myCards');
  const dispatch = useDispatch();

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const mediumScreen = useMedia(`(max-width: 768px)`);
  const smallScreen = useMedia(`(max-width: 576px)`);

  const [loading, setLoading] = useState(false);

  const [modalPrimary, setModalPrimary] = useState(false);
  const [modalRemove, setModalRemove] = useState(false);

  const changeError = (error) => {
    changeMessageError(error);
  };

  const showPrimaryModal = () => {
    setModalPrimary(true);
  };

  const showRemoveModal = () => {
    setModalRemove(true);
  };

  const handleCancel = () => {
    setModalPrimary(false);
    setModalRemove(false);
  };

  const removeCard = useCallback(() => {
    setLoading(true);
    dispatch(PaymentCards.thunks.removeCard(cardId)).then((res) => {
      if (!res.error) {
        dispatch(PaymentCards.thunks.getAllCards());
      } else changeError(t('error-message'));
      setTimeout(() => {
        setLoading(false);
        setModalRemove(false);
      }, 1000);
    });
  }, [dispatch]);

  const markCard = useCallback(() => {
    setLoading(true);
    dispatch(PaymentCards.thunks.updateDefaultCard(cardId)).then((res) => {
      if (!res.error) {
        dispatch(PaymentCards.thunks.getAllCards());
      } else changeError(t('error-message'));
      setTimeout(() => {
        setLoading(false);
        setModalPrimary(false);
      }, 1000);
    });
  }, [dispatch]);

  const renderPrimaryModal = () => (
    <Modal
      title="Basic Modal"
      visible={modalPrimary}
      onCancel={handleCancel}
      width={1019}
      closeIcon={mediumScreen ? <MediumCloseIcon /> : <LargeCloseIcon />}
    >
      <div className={style.primaryModalTitle}>
        <p>{t('primary-modal-title')}</p>
      </div>
      <div className={style.primaryModalBtn}>
        <CtaButton
          text={t('mark-as-primary')}
          onClickType="submit"
          type={'fill-primary'}
          customStyle={{
            minWidth: smallScreen ? '230px' : '180px',
            minHeight: smallScreen ? '60px' : '46px',
          }}
          loading={loading ? antIcon : ''}
          onClick={() => {
            markCard();
          }}
        />
      </div>
    </Modal>
  );

  const renderRemoveModal = () => (
    <Modal
      title="Basic Modal"
      visible={modalRemove}
      onCancel={handleCancel}
      width={1019}
      closeIcon={mediumScreen ? <MediumCloseIcon /> : <LargeCloseIcon />}
    >
      <div className={style.primaryModalTitle}>
        <p>{t('remove-modal-title')}</p>
      </div>
      <div className={style.primaryModalBtn}>
        <CtaButton
          text={t('remove')}
          onClickType="submit"
          type={'fill-primary'}
          customStyle={{
            minWidth: smallScreen ? '230px' : '180px',
            minHeight: smallScreen ? '60px' : '46px',
          }}
          loading={loading ? antIcon : ''}
          onClick={() => {
            removeCard();
          }}
        />
      </div>
    </Modal>
  );

  return (
    <>
      <div
        className={
          cardId == defaultCard ? `${style.primaryMark} ${!hasBtns && style.selectCardPrimary}` : style.hidePrimaryMark
        }
      >
        {t('primary')}
      </div>
      <div
        className={`${style.cardWrapper}  ${!hasBtns && style.cardWrapperBorder} ${
          selected && !hasBtns && style.cardWrapperShadow
        }`}
      >
        <div className={style.cardTitle}>{t('card-information')}</div>
        <div className={style.cardContent}>
          <div className={style.card}>
            <div className={style.cardRow}>
              <div className={style.rowHeader}>{t('name-on-card')} </div>
              <div className={style.rowContent}>{cardName}</div>
            </div>
            <div className={style.cardRow}>
              <div className={style.rowHeader}>{t('card-number')}</div>
              <div className={`${style.rowContent} ${style.cardNumber}`}>XXXX-XXXX-XXXX-{cardNumber}</div>
            </div>
            <div className={style.cardRow}>
              <div className={style.rowHeader}>{t('expires')}</div>
              <div className={style.rowContent}>{cardExpires}</div>
            </div>
          </div>
        </div>
        {hasBtns && (
          <div className={style.cardBtns}>
            {cardId != defaultCard && (
              <div>
                <CtaButton
                  text={t('mark-as-primary')}
                  type="border-primary"
                  onClick={() => showPrimaryModal()}
                  customStyle={{
                    minWidth: mediumScreen ? '122px' : '170px',
                    minHeight: mediumScreen ? '31px' : '43px',
                    fontSize: mediumScreen ? '0.75rem' : '1rem',
                  }}
                />
                {renderPrimaryModal()}
              </div>
            )}
            {
              <div
                className={`${style.cardRemove} ${cardId == defaultCard && cardsLength > 1 && style.hideCardRemove}`}
                onClick={showRemoveModal}
              >
                <img src={'/images/trash-white.svg'} />
              </div>
            }
            {renderRemoveModal()}
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentCard;
