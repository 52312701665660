import { createAsyncThunk } from '@reduxjs/toolkit';
import TransactionsAPI from './services';

export const getAllTransactions = createAsyncThunk('transactions/getAllTransactions', async (params, thunkAPI) => {
  try {
    const response = await TransactionsAPI.getAllTransactions(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
