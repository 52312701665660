import Sponsor from 'redux/sponsor';
import Cart from 'redux/cart';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Form, message } from 'antd';
import FormCard from 'components/molecules/FormCard';
import InputForm from 'components/atoms/InputForm';
import CtaButton from 'components/atoms/ctaButton';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const ResetPassword = () => {
  const { t } = useTranslation('forgetPassword');
  const mediumScreen = useMedia(`(max-width: 768px)`);

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const reset_password_token = queryParams.get('reset_password_token');

  const [errorList, setErrorList] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  useEffect(() => {
    dispatch(Sponsor.slice.actions.logout());
    dispatch(Cart.slice.actions.updateCart());
  }, []);

  const handleFormChange = () => {
    const isFormValid = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(isFormValid);
  };

  const passwordMatchValidation =
    (t) =>
    ({ getFieldValue }) => ({
      validator(_, value) {
        let password = getFieldValue('password');
        if (password === value) return Promise.resolve();
        else return Promise.reject(t('confirm-password-validation'));
      },
    });

  const clearErrors = (name) => {
    setErrorList((prevState) => {
      delete prevState[name];
      return prevState;
    });
  };

  const onSubmit = useCallback(
    (values) => {
      setLoading(true);
      dispatch(
        Sponsor.thunks.resetPassword({
          ...values,
          reset_password_token,
        }),
      ).then((res) => {
        if (!res.payload.errors) {
          history.push('/sign-in');
        } else setErrorList(res.payload.errors);
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
    [dispatch, history],
  );

  const renderForm = () => (
    <div className={styles.formWrapper}>
      <div className={`${styles.form} ${'resetForm'}`}>
        <Form
          className={styles.formInputs}
          onFieldsChange={handleFormChange}
          onFinish={onSubmit}
          form={form}
          layout="vertical"
        >
          <InputForm
            label={t('password')}
            name="password"
            placeholder={t('password')}
            type="password"
            error={errorList.password}
            clearError={clearErrors}
            rules={[
              { required: true, message: t('password-required') },
              { pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{6,}$/, message: t('password-validation') },
            ]}
          />

          <InputForm
            label={t('password-confirmation')}
            name="password_confirmation"
            placeholder={t('password-confirmation')}
            type="password"
            error={errorList.password_confirmation}
            clearError={clearErrors}
            rules={[passwordMatchValidation(t)]}
          />

          <Form.Item>
            <div className={styles.sendBtn}>
              <CtaButton
                type={'fill-secondary'}
                onClickType="submit"
                text={t('reset-password')}
                customStyle={{
                  minWidth: mediumScreen ? '180px' : '230px',
                  minHeight: mediumScreen ? '46px' : '59px',
                  fontSize: mediumScreen ? '1.063rem' : '1.375rem',
                  fontWeight: 'normal',
                }}
                loading={loading ? antIcon : ''}
                disabled={disabled}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );

  return (
    <div className={styles.resetPasswordWrapper}>
      <div className={styles.resetPasswordImage} />
      <div className={styles.resetPasswordForm}>
        <FormCard title={t('reset-password')} form={renderForm()} />
      </div>
    </div>
  );
};

export default ResetPassword;
