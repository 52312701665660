import axios from '../../utils/requestHelper';

const getPurpose = (id) => axios.get(`/guests/purposes/${id}`);

const getAllPurposes = (params) => axios.get(`/guests/purposes`, { params });

const PurposesAPI = {
  getPurpose,
  getAllPurposes,
};

export default PurposesAPI;
