import styles from './index.module.scss';
import { Empty } from 'antd';

const EmptyList = () => (
  <div className={styles.EmptyList}>
    <Empty />
  </div>
);

export default EmptyList;
