import { Typography } from 'antd';
import style from './index.module.scss';

const TextSection = ({ text, value, customStyle }) => (
  <div className={style.textSection}>
    {text && <Typography className={style.textSectionText}>{text}</Typography>}
    <Typography className={style.textSectionValue} style={customStyle}>
      <div dangerouslySetInnerHTML={{ __html: value }} />
    </Typography>
  </div>
);

export default TextSection;
