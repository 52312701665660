import subjectsRedux from 'redux/subjects';
import Sponsor from 'redux/sponsor';
import { Form as AntForm } from 'antd';
import InputForm from 'components/atoms/InputForm';
import SectionHeader from 'components/atoms/SectionHeader';
import CtaButton from 'components/atoms/ctaButton';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import { LoadingOutlined } from '@ant-design/icons';
import style from './index.module.scss';
import HelmetTag from 'components/atoms/Helmet';
import axios from '../../../utils/requestHelper';
import Alert from 'components/atoms/Alert';
import { useHistory } from 'react-router-dom';

const ContactUs = () => {
  const { t, i18n } = useTranslation('contact-us');
  const history = useHistory();
  const [form] = AntForm.useForm();
  const dispatch = useDispatch();
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const subjectsSelector = useSelector(subjectsRedux.selectors.subjectsSelectors.selectAll);
  const sponsor = useSelector(Sponsor.selectors.getCurrentSponsor);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const redirect = (path) => {
    history.push(path);
  };

  const handleFormChange = () => {
    const isFormValid = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(isFormValid);
  };

  const submitSuccess = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setSuccess(true);
    setErrors([]);
    form.resetFields();
    setTimeout(() => {
      redirect('/');
    }, 2000);
  };

  useEffect(() => {
    dispatch(subjectsRedux.thunks.getAllSubjects());
  }, []);

  const onSubmit = (values) => {
    setLoading(true);
    axios
      .post(`/${sponsor ? 'sponsors' : 'guests'}/inquiries`, values)
      .then(submitSuccess)
      .catch((err) => {
        setSuccess(false);
        setErrors(err.response.data.messages);
      });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const parseSubjectsData = () =>
    subjectsSelector.map((item) => ({
      label: i18n.language == 'en' ? item.name_en : item.name_ar,
      value: item.id,
    }));

  const renderSuccess = () =>
    !!success && (
      <div className={style.alertWrapper}>
        <Alert type={'alert-success'} value={'Your Feedback Submitted'} />
      </div>
    );

  const renderErrors = () =>
    !!errors.length && (
      <div className={style.alertWrapper}>
        <Alert type={'alert-danger'} value={errors.join('\r\n')} />
      </div>
    );

  const renderContactUsForm = () => (
    <>
      {renderSuccess()}
      {renderErrors()}
      <div className={style.contactUsForm}>
        {!sponsor && (
          <>
            <InputForm
              label={t('name-input-title')}
              name={'name'}
              placeholder={t('name-input-placeholder')}
              type={'default'}
              rules={[{ required: true, message: t('name-required') }]}
              errorMessage=""
            />
            <InputForm
              label={t('email-input-title')}
              name={'email'}
              placeholder={t('email-input-placeholder')}
              type={'default'}
              rules={[
                { required: true, message: t('email-required') },
                { type: 'email', message: t('email-valid') },
              ]}
              errorMessage=""
            />
            <InputForm
              label={t('phone-input-title')}
              name={'phone_number'}
              placeholder={t('phone-input-placeholder')}
              type={'number'}
              rules={[{ required: true, message: t('phone-required') }]}
              errorMessage=""
            />
          </>
        )}

        <InputForm
          label={t('subject-input-title')}
          name="inquiry_subject_id"
          type="select"
          errorMessage=""
          options={parseSubjectsData()}
          placeholder={t('subject-input-placeholder')}
          rules={[{ required: true, message: t('subject-required') }]}
        />
        <InputForm
          label={t('message-input-title')}
          name={'message'}
          placeholder={t('message-input-placeholder')}
          type={'text'}
          value={' '}
          style={{ height: 174 }}
          rules={[{ required: true, message: t('message-required') }]}
          errorMessage=""
        />
      </div>
    </>
  );

  return (
    <div className={style.contactUsWrapper}>
      <HelmetTag
        title={t('helmet-tag-title')}
        description="Healing Grace Contact Us"
        url="contact-us"
        image_url="https://company-assets.fra1.cdn.digitaloceanspaces.com/healing-grace/logo.png"
      />
      <div className={style.contactUsTitle}>
        <SectionHeader text={t('title')} />
      </div>
      <div className={style.contactUs}>
        <div className={style.contactUsFormsWrapper}>
          <AntForm
            name="form"
            layout="vertical"
            form={form}
            onFieldsChange={handleFormChange}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            autoComplete="off"
            className={style.contactUsForms}
          >
            {renderContactUsForm()}
            <div className={style.submitBtn}>
              <CtaButton
                type={'fill-secondary'}
                onClickType="submit"
                full={true}
                text={t('submit')}
                customStyle={{
                  minWidth: mediumScreen ? '220px' : '275px',
                  minHeight: mediumScreen ? '64px' : '80px',
                  fontSize: mediumScreen ? '1.188rem' : '1.563rem',
                  fontWeight: 'bold',
                }}
                loading={loading ? antIcon : ''}
                disabled={disabled}
              />
            </div>
          </AntForm>
        </div>
        <div className={style.contactUsInfo}>
          <img alt="" src="/images/contact-us.png" style={{ borderRadius: 10 }} />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
