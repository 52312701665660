import { createAsyncThunk } from '@reduxjs/toolkit';
import LettersAPI from './services';

export const sendLetter = createAsyncThunk('letters/sendLetter', async (data, thunkAPI) => {
  try {
    const response = await LettersAPI.sendLetter(data);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getSponsorshipLetters = createAsyncThunk('letters/getSponsorshipLetters', async (params, thunkAPI) => {
  try {
    const response = await LettersAPI.getSponsorshipLetters(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
