import { Fragment } from 'react';
import Hero from './sections/Hero';
import Statstics from './sections/Statistic';
import SponsorShip from './sections/SponsorShip';
import HowWeWork from './sections/HowWeWork';
import Donate from './sections/Donate';
import Projects from './sections/Projects';
import Children from './sections/children';
import HelmetTag from 'components/atoms/Helmet';
import style from './index.module.scss';

const HomePage = () => {
  return (
    <div className={style.homePage}>
      <HelmetTag
        title="Homepage"
        description="Healing Grace Homepage"
        image_url="https://company-assets.fra1.cdn.digitaloceanspaces.com/healing-grace/logo.png"
      />
      <Hero />
      <Statstics />
      <SponsorShip />
      <Children />
      <HowWeWork />
      <Donate />
      <Projects />
    </div>
  );
};

export default HomePage;
