import CtaButton from 'components/atoms/ctaButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import style from './index.module.scss';

const Donate = () => {
  const { t, i18n } = useTranslation('home');
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const largeScreen = useMedia(`(max-width: 992px)`);

  let history = useHistory();
  const onclick = () => {
    history.push('/donate');
  };
  const DonateList = [t('item1'), t('item2'), t('item3')];
  return (
    <div className={style.donateWrapper}>
      <div className={style.donateBG}>
        <img
          src={largeScreen ? '/images/home-donate-BG-mobile.png' : '/images/home-donate-BG-web.png'}
          style={i18n.language == 'ar' ? { transform: 'rotateY(180deg)' } : {}}
        />
      </div>
      <div className={style.donate}>
        <div className={style.donateTitle}>
          <div className={style.donateTitleImage}>
            <img alt="Donate Image" src="/images/donate-white.svg" />
          </div>
          <div className={style.donateTitleText}>
            <p>{t('donate-title')}</p>
          </div>
        </div>
        <div className={style.donateList}>
          {DonateList.map((item, index) => (
            <div key={index} className={style.donateListItem}>
              <img src="/images/check.svg" />
              <div>{item}</div>
            </div>
          ))}
        </div>
        <div className={style.donateBtn}>
          <CtaButton
            type={'fill-secondary'}
            text={t('donate')}
            onClick={onclick}
            customStyle={{
              minWidth: mediumScreen ? '220px' : '275px',
              minHeight: mediumScreen ? '64px' : '80px',
              fontSize: mediumScreen ? '1.188rem' : '1.563rem',
              fontWeight: 'bold',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Donate;
