import style from './index.module.scss';
import Gifts from 'redux/gifts';
import Cart from 'redux/cart';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Pagination from 'components/atoms/Pagination';
import { useHistory } from 'react-router-dom';
import GiftCart from 'components/molecules/GiftCart';
import { Col, Row } from 'antd';
import LoadingList from 'components/molecules/LoadingList';
import EmptyList from 'components/molecules/EmptyList';
import Sponsorships from 'redux/sponsorships';

const PAGE_SIZE = 12;

const Gift = () => {
  const { t, i18n } = useTranslation('gift');
  const dispatch = useDispatch();
  const gifts = useSelector(Gifts.selectors.giftSelectors.selectAll);
  const ships = useSelector(Sponsorships.selectors.sponsorshipsSelectors.selectAll);
  const totalEntries = useSelector(Gifts.selectors.totalEntries);
  const currenPage = useSelector(Gifts.selectors.currentPage);
  const [hasShip, setHasShip] = useState(false);

  const [paramAttributes, setParamAttributes] = useState({ page: 1, per_page: PAGE_SIZE });
  const [loading, setLoading] = useState(false);

  const onPageChange = (page) => {
    setLoading(true);
    setParamAttributes((prevState) => {
      return {
        ...prevState,
        page,
      };
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const addToCart = (gift) => {
    dispatch(Cart.thunks.addGiftToCart(gift));
  };

  const renderGifts = () =>
    gifts
      .map((gift) => {
        let _gift = { ...gift };
        _gift.title = gift[`title_${i18n.language}`];
        return _gift;
      })
      .map((gift) => (
        <Col xs={24} sm={12} md={8} lg={8} xl={6}>
          <GiftCart
            title={gift.title}
            price={gift.price}
            image={gift.image_url}
            animationState={hasShip ? 'SUCCESS' : 'ERROR'}
            addToCart={() => {
              if (hasShip) addToCart(gift);
            }}
          />
        </Col>
      ));

  useEffect(() => {
    setLoading(true);
    dispatch(Gifts.thunks.getAllGifts(paramAttributes));
    dispatch(Sponsorships.thunks.getAllSponsorships({ includes: 'sponsoree' }));
    setHasShip(ships.length > 0);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [paramAttributes]);

  return (
    <div className={style.giftsWrapper}>
      <div className={style.gifts}>
        <div className={style.giftsHeader}>
          <h1>{t('giftCatalogue')}</h1>
        </div>

        {loading && <LoadingList />}
        {!loading && gifts.length == 0 && <EmptyList />}
        {!loading && gifts.length > 0 && (
          <div className={style.giftsCards}>
            <Row
              gutter={[
                { xs: 15, sm: 15, md: 30, lg: 30, xl: 40 },
                { xs: 20, sm: 20, md: 30, lg: 30, xl: 40 },
              ]}
            >
              {renderGifts()}
            </Row>
          </div>
        )}

        {!loading && (
          <div className={style.giftsPagination}>
            <Pagination total={totalEntries} pageSize={PAGE_SIZE} current={currenPage} onChange={onPageChange} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Gift;
