import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

export const subjectsSelectors = {
  ...adapter.getSelectors((state) => state.subjects),
};

export const getMeta = createSelector(
  (state) => state?.subjects,
  (projects) => projects?.subjects,
);
