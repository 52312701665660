import { createAsyncThunk } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import SponsorsAPI from './services';

export const signIn = createAsyncThunk('sponsors/signIn', async (data, { rejectWithValue }) => {
  try {
    const response = await SponsorsAPI.signIn(data);
    const authHeaders = pick(response.headers, ['client', 'expiry', 'token', 'uid']);
    return { data: response.data, authHeaders };
  } catch (err) {
    return rejectWithValue(err.response.data.errors);
  }
});

export const signUp = createAsyncThunk('sponsors/signUp', async (data, thunkAPI) => {
  try {
    const response = await SponsorsAPI.signUp(data);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateProfile = createAsyncThunk('sponsors/updateProfile', async (data, thunkAPI) => {
  try {
    const response = await SponsorsAPI.updateProfile(data);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const forgetPassword = createAsyncThunk('sponsors/forgetPassword', async (email, { rejectWithValue }) => {
  try {
    const response = await SponsorsAPI.forgetPassword(email);
    return { data: { sponsor: response.data.sponsor } };
  } catch (err) {
    return rejectWithValue({ data: err.response.data, status: err.response.status });
  }
});

export const resetPassword = createAsyncThunk('sponsors/resetPassword', async (data, { rejectWithValue }) => {
  try {
    const response = await SponsorsAPI.resetPassword(data);
    return { data: response.data };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resendConfirmation = createAsyncThunk(
  'sponsors/resendConfirmation',
  async (email, { rejectWithValue }) => {
    try {
      const response = await SponsorsAPI.resendConfirmation(email);
      return { data: { sponsor: response.data.sponsor } };
    } catch (err) {
      return rejectWithValue({ data: err.response.data, status: err.response.status });
    }
  },
);

export const changePassword = createAsyncThunk('sponsors/changePassword', async (data, { rejectWithValue }) => {
  try {
    const response = await SponsorsAPI.changePassword(data);
    return { data: { sponsor: response.data.sponsor } };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
export const completeProfile = createAsyncThunk('sponsors/completeProfile', async (data, { rejectWithValue }) => {
  try {
    const response = await SponsorsAPI.completeProfile(data);
    return { data: { sponsor: response.data.sponsor } };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getProfile = createAsyncThunk('sponsors/getProfile', async (data, thunkAPI) => {
  try {
    const response = await SponsorsAPI.getProfile(data);
    return { data: { sponsor: response.data.sponsor } };
  } catch (err) {
    return thunkAPI.rejectWithValue({ data: err.response.data, status: err.response.status });
  }
});

export const updateEmail = createAsyncThunk('sponsors/updateEmail', async (data, { rejectWithValue }) => {
  try {
    const response = await SponsorsAPI.updateEmail(data);
    return { data: { sponsor: response.data.sponsor } };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
