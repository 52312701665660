import style from './index.module.scss';
import ProjectsRedux from 'redux/projects';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Card from 'components/atoms/Card';
import { Col } from 'antd';
import Carousel from 'components/atoms/ Carousel';
import SectionHeader from 'components/atoms/SectionHeader';
import { useTranslation } from 'react-i18next';
import LoadingList from 'components/molecules/LoadingList';
import { useMedia } from 'react-use';
import { useHistory } from 'react-router-dom';
import CtaButton from 'components/atoms/ctaButton';

const Projects = () => {
  const { t, i18n } = useTranslation('home');
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, SetLoading] = useState(false);
  const projectsSelector = useSelector(ProjectsRedux.selectors.projectSelectors.selectAll);
  const xxLargeScreen = useMedia(`(max-width: 1600px)`);
  const xlargeScreen = useMedia(`(max-width: 1200px)`);
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const smallScreen = useMedia(`(max-width: 576px)`);

  const pageSize = useCallback(() => (smallScreen ? 1 : xlargeScreen ? 2 : 4), [mediumScreen, xlargeScreen]);

  const renderSection = () => loading || (!loading && projectsSelector.length > 0);

  useEffect(() => {
    SetLoading(true);
    dispatch(ProjectsRedux.thunks.getAllProjects({ featured: true, per_page: 4 }));
    setTimeout(() => {
      SetLoading(false);
    }, 1000);
  }, [i18n.language]);

  const projectList = () =>
    projectsSelector.map((el, index) => (
      <Col key={index} xl={6} lg={12} md={12} sm={12} xs={24} xxs={24} className={style.projectCard}>
        <Card
          key={el.id}
          title={el.title}
          image={el.featured_image_url}
          textLink={t('viewProject')}
          link={`/projects/${el.id}`}
          customCardStyle={{ height: mediumScreen ? '252px' : '300px' }}
        />
      </Col>
    ));
  return (
    renderSection() && (
      <div className={style.projectListWrapper}>
        <div className={style.projectList}>
          <div className={style.projectListHeader}>
            <SectionHeader
              text={t('beyondSponsorships')}
              customTextStyle={{ fontSize: mediumScreen ? '1.563rem' : xxLargeScreen ? '30px' : '50px' }}
            />
          </div>
          <div className={style.projectListCards}>
            {!loading && <Carousel pageSize={pageSize()}>{projectList()}</Carousel>}
            {loading && <LoadingList />}
          </div>
          <div className={style.projectListExplore}>
            <CtaButton
              type={'fill-primary'}
              text={t('exploreMore')}
              onClick={() => {
                history.push('/projects');
              }}
              customStyle={{
                minWidth: mediumScreen ? '220px' : '275px',
                minHeight: mediumScreen ? '64px' : '80px',
                fontSize: mediumScreen ? '1.188rem' : '1.563rem',
                fontWeight: 'bold',
              }}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default Projects;
