import Transactions from 'redux/transactions';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/atoms/Table';
import DonationsCard from 'components/atoms/DonationsCard';
import style from './index.module.scss';
import PriceText from 'components/atoms/PriceText';
import { parseDate } from 'utils/date';

const Donations = () => {
  const { t, i18n } = useTranslation('dashboard');
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    per_page: 2,
  });

  const transactions = useSelector(Transactions.selectors.transactionsSelectors.selectAll);

  const columns = [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('totalDonated'),
      dataIndex: 'total_donated',
      key: 'total_donated',
    },
    {
      title: t('purpose'),
      dataIndex: 'purpose',
      key: 'purpose',
    },
  ];

  useEffect(() => {
    setLoading(true);
    dispatch(Transactions.thunks.getAllTransactions(params));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [params]);

  const getDataSource = () =>
    transactions.map((el, index) => {
      return {
        key: index,
        date: <div className={style.donationDate}>{parseDate(el.received_date, i18n.language)}</div>,
        total_donated: (
          <div className={style.donationTotalPrice}>
            <PriceText price={el.unsigned_amount} currency={el.currency} symbol={false} priceBold={true} />
          </div>
        ),
        purpose: (
          <div className={style.purposeWrapper}>
            {(() => {
              switch (el.mediator_type) {
                case 'PurchasedGift':
                  return (
                    <div className={style.purpose}>
                      <img className={style.purposeIcon} src="/images/purchased-gift.svg" />
                      <div className={style.purposeText}>{t('purchased-gift')}</div>
                    </div>
                  );
                case 'GeneralDonation':
                  return (
                    <div className={style.purpose}>
                      <img className={style.purposeIcon} src="/images/general-donation.svg" />
                      <div className={style.purposeText}>{t('general-donation')}</div>
                    </div>
                  );
                case 'Due':
                  return (
                    <div className={style.purpose}>
                      <img className={style.purposeIcon} src="/images/sponsorship-donation.svg" />
                      <div className={style.purposeText}>{t('sponsorship-donation')}</div>
                    </div>
                  );
                default:
                  return (
                    <div className={style.purpose}>
                      <img className={style.purposeIcon} src="/images/balance-icon.png" />
                      <div className={style.purposeText}>
                        {el.invoice_type === 'balance' ? t('balance') : t('applied-balance')}
                      </div>
                    </div>
                  );
              }
            })()}
          </div>
        ),
      };
    });

  const redirect = (path) => {
    history.push(path);
  };
  return (
    <div className={`${style.myDonationsWrapper} font-weight-normal`}>
      <div className={style.myDonationsTitle}>
        <p>{t('recentTransactions')}</p>
        <p
          className={style.myDonationsTitleView}
          onClick={() => {
            redirect('/my-transactions');
          }}
        >
          {t('viewAll')} <img src="/images/arrow-bold.svg" />
        </p>
      </div>
      {!mediumScreen ? (
        <div className="myDonationTable">
          <Table
            columns={columns}
            dataSource={getDataSource()}
            loading={loading}
            EmptyTablePath="/dashboard-donations"
          />
        </div>
      ) : (
        <DonationsCard dataSource={getDataSource()} EmptyTablePath="/dashboard-donations" />
      )}
    </div>
  );
};

export default Donations;
