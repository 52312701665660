import { Col, Row } from 'antd';
import InfoCard from 'components/molecules/InfoCard';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Sponsor from 'redux/sponsor';
import CtaButton from 'components/atoms/ctaButton';
import style from './index.module.scss';
import { useMedia } from 'react-use';
import { useDispatch } from 'react-redux';

const Profile = () => {
  const { t } = useTranslation('profile');
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const dispatch = useDispatch();
  const sponsor = useSelector(Sponsor.selectors.getCurrentSponsor);
  const history = useHistory();

  const ctaStyle = {
    minWidth: mediumScreen ? '170px' : '245px',
    minHeight: mediumScreen ? '45px' : '60px',
    fontSize: mediumScreen ? '1.063rem' : '1.375rem',
  };

  const redirectToPage = (path) => {
    history.push(path);
  };

  const getPhoneNumber = (phoneNumber) => `${phoneNumber.code} ${phoneNumber.number}`;

  const renderHeader = () => (
    <div className={style.profileHeader}>
      <h1>{t('header')}</h1>
    </div>
  );

  const renderBasicInfo = () => (
    <div className={style.profileBasicInfo}>
      <div className={style.profileBasic}>
        <div className={style.profileBasicInfoPhoto}>
          <img src={sponsor.image_url || '/images/profile.svg'} />
        </div>
        <div className={style.profileBasicInfoName}>{sponsor.first_name + ' ' + sponsor.last_name}</div>
        <div className={style.profileBasicInfoEmail}>{sponsor.email}</div>
        <div className={style.profileBasicInfoUpdateProfile}>
          <CtaButton
            text={t('updateProfile')}
            onClick={() => {
              redirectToPage('/update-profile');
            }}
            type="border-primary"
            customStyle={ctaStyle}
          />
        </div>
      </div>
    </div>
  );

  const renderFullInfo = () => (
    <div className={style.profilerFullInfo}>
      {sponsor.phone_number_primary && (
        <div className={style.fullInfo}>
          <div className={style.fullInfoKey}>{t('phoneNumber')}</div>
          <div className={`${style.fullInfoValue} ${style.fullInfoValueNumber}`}>
            {getPhoneNumber(sponsor.phone_number_primary)}
          </div>
        </div>
      )}
      {sponsor.gender && (
        <div className={style.fullInfo}>
          <div className={style.fullInfoKey}>{t('gender')}</div>
          <div className={style.fullInfoValue}>{sponsor.gender}</div>
        </div>
      )}
      {sponsor.language_preference && (
        <div className={style.fullInfo}>
          <div className={style.fullInfoKey}>{t('languagePreference')}</div>
          <div className={style.fullInfoValue}>
            {sponsor.language_preference === 'either' ? 'English and Arabic' : sponsor.language_preference}
          </div>
        </div>
      )}
      {sponsor.country && (
        <div className={style.fullInfo}>
          <div className={style.fullInfoKey}>{t('country')}</div>
          <div className={style.fullInfoValue}>{sponsor.country}</div>
        </div>
      )}
      {sponsor.street_name && sponsor.city && (
        <div className={style.fullInfo}>
          <div className={style.fullInfoKey}>{t('address')}</div>
          <div className={style.fullInfoValue}>{sponsor.street_name + ', ' + sponsor.city}</div>
        </div>
      )}
    </div>
  );

  const renderButtonInfo = () => (
    <div className={style.profileButtonInfo}>
      <CtaButton
        text={t('updateEmail')}
        onClick={() => {
          redirectToPage('/update-email');
        }}
        type="border-primary"
        customStyle={ctaStyle}
      />
      <CtaButton
        text={t('resetPassword')}
        onClick={() => {
          redirectToPage('/change-password');
        }}
        type="border-primary"
        customStyle={ctaStyle}
      />
    </div>
  );

  const renderProfileCardInfo = () => (
    <Row className={style.profileInfo} gutter={[0, { xs: 10, xl: 55 }]}>
      <Col xs={24} xl={8}>
        {renderBasicInfo()}
      </Col>
      <Col xs={24} xl={8}>
        {renderFullInfo()}
      </Col>
      <Col xs={24} xl={8}>
        {renderButtonInfo()}
      </Col>
    </Row>
  );

  const renderProfileCard = () => <InfoCard title={t('contactInformation')} info={renderProfileCardInfo()} />;

  useEffect(() => {
    dispatch(Sponsor.thunks.getProfile());
  }, []);

  return (
    <div className={style.profileWrapper}>
      {renderHeader()}
      {renderProfileCard()}
    </div>
  );
};

export default Profile;
