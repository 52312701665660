import { Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import style from './index.module.scss';

const Error = ({ title, subtitle1, subtitle2 }) => {
  const { t } = useTranslation('error');

  const links = [
    { name: t('home'), link: '/' },
    { name: t('who-we-are'), link: '/who-we-are' },
    { name: t('projects'), link: '/projects' },
    { name: t('donate'), link: '/donate' },
    { name: t('sponser-now'), link: '/children' },
    { name: t('faqs'), link: '/faqs' },
  ];
  return (
    <div className={style.errorWrapper}>
      <div className={style.error}>
        <div className={style.errorImage}>
          <img src="/images/errorIcon.svg" />
        </div>
        <div>
          <p className={style.errorTitle}>{title}</p>
        </div>
        <div>
          <p className={style.errorSubtitle1}>{subtitle1}</p>
        </div>
        <div>
          <p className={style.errorSubtitle2}>{subtitle2}</p>
        </div>
        <div className={style.errorLinksWrapper}>
          {links.map((recommendedLink, index) => (
            <div key={recommendedLink.link}>
              <a className={style.errorLink} href={recommendedLink?.link}>
                {recommendedLink.name}
              </a>
              {index < links.length - 1}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Error;
