import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import 'translation/i18n';
import { Helmet } from 'react-helmet';

import store from './redux/store';
import { history } from './router';
import Router from './router';
import './index.scss';

const Root = () => {
  const { i18n } = useTranslation();
  // CHANGE LANGE MUST UNDEO
  // const currentLanguage = i18n.language || 'en';
  const currentLanguage = 'en';
  const direction = i18n.dir(currentLanguage);

  return (
    <Provider store={store}>
      <Helmet
        htmlAttributes={{
          lang: currentLanguage,
        }}
      >
        <title>Healing Grace</title>
        <meta property="og:title" content="Healing Grace" />
        <meta property="og:description" content="Healing Grace" />
        <link rel="apple-touch-icon" sizes="180x180" href="logo.svg" />
        <link rel="icon" type="image/png" href="logo.svg" sizes="16x16" />

        <meta
          property="og:image"
          content="https://res.cloudinary.com/guc2/image/upload/v1680606114/healing-grace-logo_elfpam.jpg"
        />
        <meta property="og:image:width" content="401" />
        <meta property="og:image:height" content="201" />
        <meta property="og:url" content="https://dev-healing-grace-web.thewebops.com" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />

        <meta property="title" content="Healing Grace" />
        <meta property="description" content="Healing Grace" />
        <meta
          property="image"
          content="https://res.cloudinary.com/guc2/image/upload/v1680606114/healing-grace-logo_elfpam.jpg"
        />
        <meta property="url" content="https://dev-healing-grace-web.thewebops.com" />
        <meta property="type" content="website" />
        <meta property="locale" content="en_GB" />
      </Helmet>
      <ConnectedRouter history={history}>
        <ConfigProvider direction={direction}>
          <Router />
        </ConfigProvider>
      </ConnectedRouter>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root'),
);
