import { createAsyncThunk } from '@reduxjs/toolkit';
import SubjectsAPI from './services';

export const getAllSubjects = createAsyncThunk('subjects/getAllSubjects', async (params, thunkAPI) => {
  try {
    const response = await SubjectsAPI.getAllSubjects(params);
    return { data: response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.data);
  }
});
