import style from './index.module.scss';
import { Upload } from 'antd';
import CtaButton from 'components/atoms/ctaButton';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';

const AttachmentUploader = ({ onUpload }) => {
  const { t, i18n } = useTranslation('letters');
  const mediumScreen = useMedia(`(max-width: 768px)`);
  return (
    <div className={style.attachmentUploader}>
      <Upload beforeUpload={() => false} onChange={onUpload} showUploadList={false}>
        <CtaButton
          text={t('attachFile')}
          icon="/images/attachment-gray.svg"
          customTextStyle={{
            display: 'inline',
            padding: '2px 10px 0px',
          }}
          customStyle={{
            background: 'transparent',
            padding: mediumScreen ? '8px 25px' : '11px 40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: mediumScreen ? '1.063rem' : '1.375rem',
          }}
          type={'border-primary'}
        />
      </Upload>
    </div>
  );
};

export default AttachmentUploader;
