import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LettersRedux from 'redux/letters';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CtaButton from 'components/atoms/ctaButton';
import { useMedia } from 'react-use';
import EmptyTable from 'components/atoms/Table/Empty';
import { LoadingOutlined } from '@ant-design/icons';
import { parseDate } from 'utils/date';
import LoadingList from 'components/molecules/LoadingList';
import style from './index.module.scss';

const Letters = () => {
  const { t, i18n } = useTranslation('letters');

  const listInnerRef = useRef();
  const { id } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const mediumScreen = useMedia(`(max-width: 768px)`);

  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const [currPage, setCurrPage] = useState(1);

  const sponsorshipLetters = useSelector(LettersRedux.selectors.lettersSelectors.selectAll);

  const antIcon = <LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />;

  const sponsoreeCode = sponsorshipLetters[0]?.sponsoree?.code;
  const sponsoreeName = sponsorshipLetters[0]?.sponsoree?.full_name;

  const initParams = {
    sponsorship_id: id,
    per_page: 10,
    page: currPage,
    includes: 'sponsoree',
  };

  useEffect(() => {
    setDataLoading(true);
    dispatch(LettersRedux.thunks.getSponsorshipLetters({ ...initParams, refresh: true }));
    setTimeout(() => {
      setDataLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(LettersRedux.thunks.getSponsorshipLetters(initParams));
  }, [dispatch, i18n.language, currPage, id]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight == scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const redirect = (path) => {
    history.push(path);
  };

  const renderHeader = () => (
    <div className={style.lettersHeader}>
      <div className={style.lettersHeaderStart}>
        <div
          className={style.lettersHeaderStartArrow}
          onClick={() => {
            redirect('/sponsorships');
          }}
        >
          <img src="/images/back-arrow.svg" />
        </div>
        <div className={style.lettersHeaderStartTitle}>{t('inbox')}</div>
      </div>
      <div className={style.lettersHeaderEnd}>
        <CtaButton
          text={t('sendNewLetter')}
          type="fill-primary"
          onClick={() => {
            setLoading(true);
            setTimeout(() => {
              redirect(`/send-letters/${id}`);
              setLoading(false);
            }, 1000);
          }}
          customStyle={{
            fontWeight: 'normal',
            fontSize: mediumScreen ? '17px' : '1.25rem',
            padding: mediumScreen ? '12px 20px' : '12px 40px',
          }}
          loading={loading ? antIcon : ''}
        />
      </div>
    </div>
  );

  const renderChildName = () => (
    <div className={style.lettersChildSender}>
      <div>{t('to')}</div>
      <div className={style.lettersChildSenderName}>
        <span>{sponsoreeCode}</span>
        {sponsoreeName}
      </div>
    </div>
  );

  const getTime = (created_at) => {
    const date = new Date(created_at);
    return date.toLocaleTimeString('default', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const renderChildMessage = () => (
    <>
      {sponsorshipLetters?.length > 0 ? (
        <div className={style.messagesContainer}>
          <div className={style.senderBlock}></div>
          <div className={style.reciverBlock}></div>
          <div className={style.messages} onScroll={onScroll} ref={listInnerRef}>
            {sponsorshipLetters.map((letter, index) => (
              <div
                key={index}
                className={`${style.message} ${letter?.sender_type == 'SponsorLetter' && style.messageReceiverWrapper}`}
              >
                <div
                  className={`${
                    letter?.sender_type == 'SponsoreeLetter' ? style.messageSender : style.messageReceiver
                  }`}
                >
                  <div className={style.messageInfo}>
                    <div>{parseDate(letter?.created_at, i18n.language)}</div>
                    <div>{getTime(letter?.created_at)}</div>
                  </div>

                  <div className={style.messageContent}>
                    {letter?.attachments?.map(
                      (attachment) =>
                        attachment != null && (
                          <div
                            className={style.messageContentAttachment}
                            onClick={() => {
                              onclick = `${window.open(attachment, '_blank')}`;
                            }}
                          >
                            <img
                              src={
                                letter?.sender_type == 'SponsoreeLetter'
                                  ? '/images/attachment-blue.svg'
                                  : '/images/attachment-white.svg'
                              }
                            />
                            {t('viewAttachment')}
                          </div>
                        ),
                    )}
                    {letter?.text && (
                      <div className={`${style.messageContentText} ${style.messageContentTextHasAttachment}`}>
                        {letter?.text}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={!mediumScreen ? style.messagesContainer : style.messagesContainerWebEmpty}>
          <div className={`${style.emptyLetters} ${style.emptyLettersMobile}`}>
            <EmptyTable customePath="/letters" />
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className={style.lettersWrapper}>
      {renderHeader()}
      {!dataLoading ? (
        <div className={style.letters}>
          {sponsorshipLetters.length > 0 && renderChildName()}
          {renderChildMessage()}
        </div>
      ) : (
        <LoadingList />
      )}
    </div>
  );
};

export default Letters;
