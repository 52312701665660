import { createSlice } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import * as selectors from './selectors';
import { getProject, getAllProjects } from './thunks';

export const slice = createSlice({
  name: 'projects',
  initialState: adapter.getInitialState({ meta: {} }),
  extraReducers: (builder) => {
    builder.addCase(getProject.fulfilled, (state, action) => {
      const { payload } = action;
      const { data } = payload;
      if (!data.project) return;
      adapter.setOne(state, data.project);
    });
    builder.addCase(getProject.rejected, (state, action) => {
      let { status } = action.payload.response;
      if (status === 404) window.location.href = '/404';
    });
    builder.addCase(getAllProjects.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { data } = payload;
      if (!data.projects) return;
      const { arg } = meta;
      state.meta = payload.data.meta;
      adapter.setAll(state, data.projects);
      // if (arg?.refresh) adapter.setAll(state, data.projects);
      // else adapter.upsertMany(state, data.projects);
    });
  },
});
const Projects = {
  adapter,
  slice,
  thunks,
  selectors,
};
export default Projects;
