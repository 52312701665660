import SponsoreesRedux from 'redux/sponserees';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CtaButton from 'components/atoms/ctaButton';
import { yearTranslateHelper } from 'utils/yearsTranslateHelper';
import Sponsor from 'redux/sponsor';
import { getCountryCode } from 'utils/geoLocation';
import Cart from 'redux/cart';
import { months } from 'utils/date';
import HelmetTag from 'components/atoms/Helmet';
import style from './index.module.scss';
import InfoTable from 'components/molecules/InfoTable';

const incomeEarnerValues = {
  brother: { en: 'Brother', ar: 'أخي' },
  father: { en: 'Father', ar: 'أبي' },
  mother: { en: 'Mother', ar: 'أمي' },
  other_relative: { en: 'Other Relative', ar: 'قريب اخر' },
  outside_source: { en: 'Outside Source', ar: 'مصدر خارجى' },
  sister: { en: 'Sister', ar: 'أختي' },
  other: { en: 'Other', ar: 'آخر' },
};

const SingleCild = () => {
  const { t, i18n } = useTranslation('singleChild');
  const dispatch = useDispatch();
  const history = useHistory();

  const mediumScreen = useMedia(`(max-width: 768px)`);

  const { id } = useParams();

  const child = useSelector((state) => SponsoreesRedux.selectors.sponsoreeSelectors.selectById(state, id) || {});
  const sponsor = useSelector(Sponsor.selectors.getCurrentSponsor);

  const { village } = child || {};
  const { district } = village || {};
  const { governorate } = district || {};
  const [currentCountryCode, setCurrentCountryCode] = useState('');

  const getCurrentCountry = async () => {
    let code = await getCountryCode();
    setCurrentCountryCode(code);
  };

  const getChildLocalizeValue = (key) => {
    return child && child[`${key}_${i18n.language}`];
  };

  const getPrice = () =>
    sponsor?.payment_currency ? (
      sponsor?.payment_currency === 'egp' ? (
        <>
          {child.price} <span>{t('le')}</span>
        </>
      ) : (
        <>
          <span className="dollarSymbol">{t('dollar')}</span> {child.price}
        </>
      )
    ) : currentCountryCode === 'EG' ? (
      <>
        {child.price} <span>{t('le')}</span>
      </>
    ) : (
      <>
        <span className="dollarSymbol">{t('dollar')}</span> {child.price}
      </>
    );

  useEffect(() => {
    dispatch(SponsoreesRedux.thunks.getSponsoree(id));
    getCurrentCountry();
  }, []);

  const childFamilyMembers = {
    father_name: t('father'),
    mother_name: t('mother'),
    sisters: t('sister'),
    brothers: t('brother'),
  };

  const renderVillageName = () =>
    village && (
      <span>
        {t('I-live-in')} <span>{village[`name_${i18n.language}`]}</span>,
      </span>
    );

  const parseDateOfBirth = () => {
    if (!child.date_of_birth) return '';

    const date = new Date(child.date_of_birth);
    const day = date.getDate();
    const month = months[date.getMonth()][i18n.language];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const renderVillageInfo = () =>
    village && (
      <div className={style.singleChildVillageInfo}>
        <div className={style.childTitle}>
          <div>
            <img src="/images/Line-blue.svg" />
          </div>
          <div>
            <p>{t('village-information')}</p>
          </div>
        </div>
        <div className={style.childInfoData}>{village[`village_info_${i18n.language}`]}</div>
      </div>
    );

  const renderGovernorateName = () =>
    governorate && (
      <span>
        {t('which-is-a-village-in-the-governorate-of')} {governorate[`name_${i18n.language}`]}.
      </span>
    );

  const renderAdditionalInfo = () => (
    <div className={style.childAdditionalInfo}>
      <div className={style.childTitle}>
        <div>
          <img src="/images/Line-blue.svg" />
        </div>
        <div>{t('additional-information')}</div>
      </div>
      <div className={style.childInfoData}>
        {getChildLocalizeValue('character') && `${t('I-am')} ${getChildLocalizeValue('character')} `}
        {(getChildLocalizeValue('special_talents') || getChildLocalizeValue('hobbies')) && `${t('I-enjoy')} `}
        {getChildLocalizeValue('special_talents') && `${getChildLocalizeValue('special_talents')} ,`}
        {getChildLocalizeValue('hobbies') && `${getChildLocalizeValue('hobbies')}. `}
        {getChildLocalizeValue('dreams') && `${t('I-want-to-be')} ${getChildLocalizeValue('dreams')}.`}
        {getChildLocalizeValue('best_subject') &&
          `${t('My-best-subject-is')} ${getChildLocalizeValue('best_subject')}.`}
      </div>
    </div>
  );

  const renderChildFamilyMember = (MemberKey) => {
    return {
      key: childFamilyMembers[MemberKey],
      value: getChildLocalizeValue(MemberKey),
    };
  };

  const renderChildFamilyMembers = () => {
    let rows = [];

    if (getChildLocalizeValue('father_name')) rows.push(renderChildFamilyMember('father_name'));
    if (getChildLocalizeValue('mother_name')) rows.push(renderChildFamilyMember('mother_name'));
    if (getChildLocalizeValue('sisters')) rows.push(renderChildFamilyMember('sisters'));
    if (getChildLocalizeValue('brothers')) rows.push(renderChildFamilyMember('brothers'));

    return rows;
  };

  const renderChildFamilyIncome = () => [
    {
      key: t('income-earner'),
      value: child.income_earner ? incomeEarnerValues[child.income_earner][i18n.language] : '',
    },
    { key: t('income-amount'), value: getChildLocalizeValue('income_amount') },
    { key: t('income-source'), value: getChildLocalizeValue('income_earner_professions') },
    { key: t('income-frequency'), value: t(child.income_frequency) },
  ];

  const renderChildInfo = () => (
    <div className={style.childInfo}>
      <div className={style.childInfoYear}>
        <div className={style.qouteIcon}>
          <img src="/images/qouteIcon.svg" />
        </div>
        <div style={{ display: 'flex', columnGap: '5px' }}>
          <div>{t('I-am')}</div>
          <div>{yearTranslateHelper(t, child.current_age)}</div>
          <div>{t('old')}</div>
        </div>
      </div>
      <div className={style.childInfoData}>
        {renderVillageName()}
        {renderGovernorateName()}
        {t('My-birthday-is')} {parseDateOfBirth()}.
        {child.school_year && `${t('I-am-in-grade')} ${t(child.school_year)}`}
      </div>
    </div>
  );

  return (
    child && (
      <div className={style.singleChildWrapper}>
        <HelmetTag
          title={child[`full_name_${i18n.language}`]}
          description={`I am ${yearTranslateHelper(t, child.current_age)} old. I live in ${
            village?.name
          }.My birthday is ${parseDateOfBirth()}. I am in grade ${child.school_year}.`}
          url={child?.id}
          image_url={child?.image_url}
        />
        <div className={style.singleChildHeader}>
          <div className={style.childCodeName}>
            <div className={style.childCode}>{child.code}</div>
            <div className={style.childName}>{child[`full_name_${i18n.language}`]}</div>
          </div>
          <div className={style.childSponsorNow}>
            <div className={style.childPay}>
              {t('I-will-pay')} {getPrice()}
            </div>
            <div>
              <CtaButton
                type={'fill-secondary'}
                text={t('sponsor-now')}
                customStyle={{
                  minWidth: mediumScreen ? '180px' : '230px',
                  minHeight: mediumScreen ? '46px' : '60px',
                  fontSize: mediumScreen ? '1.063rem' : '1.375rem',
                  fontWeight: 'normal',
                }}
                onClick={() => {
                  dispatch(Cart.thunks.addChildToCart(child));
                  history.push('/cart');
                }}
              />
            </div>
          </div>
        </div>
        <div className={style.singleChildInfo}>
          <div className={style.childImage}>
            {child.education_level === 'rising_leader' && <div className={style.childBadge}>{t('university')}</div>}
            <img src={child.image_url} />
          </div>
          <div className={style.childInfoContent}>
            {renderChildInfo()}
            <InfoTable title={t('family')} rows={renderChildFamilyMembers()} />
            <InfoTable title={t('family-income-information')} rows={renderChildFamilyIncome()} />
            {renderAdditionalInfo()}
          </div>
        </div>
        {renderVillageInfo()}
        <div className={style.singleChildRemember}>
          <div className={style.qouteIcon}>
            <img src="/images/qouteIcon.svg" />
          </div>
          {t('remember', { name: child[`full_name_${i18n.language}`] })}
        </div>
      </div>
    )
  );
};

export default SingleCild;
