import axios from '../../utils/requestHelper';

const sendLetter = (data) => axios.post(`/sponsors/sponsor_letters`, data);

const getSponsorshipLetters = (params) => axios.get(`/sponsors/sponsorships/sponsorship_letters`, { params });

const LettersAPI = {
  sendLetter,
  getSponsorshipLetters,
};

export default LettersAPI;
