import SponsoreesRedux from 'redux/sponserees';
import VillagesRedux from 'redux/villages';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { Col, Row, Input } from 'antd';
import Select from 'components/atoms/Select';
import Radio from 'components/atoms/Radio';
import Card from '../../../components/atoms/Card';
import SectionHeader from 'components/atoms/SectionHeader';
import Pagination from 'components/atoms/Pagination';
import EmptyList from 'components/molecules/EmptyList';
import LoadingList from 'components/molecules/LoadingList';
import CtaButton from 'components/atoms/ctaButton';
import { yearTranslateHelper } from 'utils/yearsTranslateHelper';
import { Testimonies } from '../../../utils/testimonies';
import { useHistory } from 'react-router-dom';
import style from './index.module.scss';
import HelmetTag from 'components/atoms/Helmet';

const { Search } = Input;
const PAGE_SIZE = 16;
const INIT_PARAMS = { page: 1, per_page: PAGE_SIZE };

const Children = () => {
  const { t, i18n } = useTranslation('children');
  const locale = i18n.language;

  const dispatch = useDispatch();
  const history = useHistory();

  const xxLargeScreen = useMedia(`(max-width: 1600px)`);
  const xLargeScreen = useMedia(`(max-width: 1200px)`);
  const largeScreen = useMedia(`(max-width: 992px)`);
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const xxsmallScreen = useMedia(`(max-width: 375px)`);

  const [query, setQuery] = useState('');

  const [loading, SetLoading] = useState(false);
  const [openDrawer, SetOpenDrawer] = useState(false);

  const [paramAttributes, setParamAttributes] = useState(INIT_PARAMS);

  const sponsoreeSelector = useSelector(SponsoreesRedux.selectors.sponsoreeSelectors.selectAll);
  const villagesSelector = useSelector(VillagesRedux.selectors.villageSelectors.selectAll);

  const childrenCount = useSelector(SponsoreesRedux.selectors.totalEntries);
  const currenPage = useSelector(SponsoreesRedux.selectors.currentPage);

  const gender_menu = [
    { label: t('female'), value: 'female' },
    { label: t('male'), value: 'male' },
  ];

  const education_level_menu = [
    { label: t('school'), value: 'child' },
    { label: t('university'), value: 'rising_leader' },
  ];

  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const gender = queryParams.get('gender');
  const education_level = queryParams.get('level');

  const redirect = (path) => {
    history.push(path);
  };

  const fetchData = (params) => {
    SetLoading(true);
    dispatch(SponsoreesRedux.thunks.getAllSponsorees(params));
    setTimeout(() => {
      SetLoading(false);
    }, 1000);
  };

  useEffect(() => {
    SetLoading(true);
    let filters = {};
    if (gender) {
      filters.gender = gender == 'both' ? null : gender == 'girl' ? 'female' : 'male';
    }
    if (education_level) {
      filters.education_level = education_level == '' ? null : education_level == 'school' ? 'child' : 'rising_leader';
    }
    const params = {
      ...paramAttributes,
      ...filters,
    };
    setParamAttributes(params);
    fetchData(params);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [locale]);

  useEffect(() => {
    dispatch(VillagesRedux.thunks.getAllVillages());
  }, [locale]);

  const parseVillagesData = () =>
    villagesSelector.map((item) => ({
      label: item.name,
      value: item.id,
    }));

  const clearFilters = () => {
    SetOpenDrawer(false);
    setParamAttributes(INIT_PARAMS);
    fetchData(INIT_PARAMS);
    if (gender || education_level) {
      redirect('/children');
    }
  };

  const onSearchExecute = useCallback(() => {
    const params = {
      ...paramAttributes,
      ...INIT_PARAMS,
    };
    setParamAttributes(params);
    fetchData(params);
    if (gender || education_level) {
      history.push(
        `/children?${paramAttributes.gender && `gender=${paramAttributes.gender === 'female' ? 'girl' : 'boy'}`}${
          paramAttributes.education_level &&
          `&level=${paramAttributes.education_level === 'child' ? 'school' : 'university'}`
        }`,
      );
    }
    SetOpenDrawer(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [paramAttributes]);

  const onPageChange = useCallback(
    (page) => {
      const params = {
        ...paramAttributes,
        page: page,
      };
      setParamAttributes(params);
      fetchData(params);
      if (gender || education_level) {
        redirect('/children');
      }
      SetLoading(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    [paramAttributes],
  );

  const renderGenderFilter = () => (
    <>
      {!largeScreen ? (
        <div className={style.filter}>
          <Select
            placeholder={t('gender')}
            options={gender_menu}
            value={paramAttributes.gender ? paramAttributes.gender : null}
            onChange={(value) =>
              setParamAttributes((prevState) => {
                return {
                  ...prevState,
                  gender: value,
                };
              })
            }
          />
        </div>
      ) : (
        <div>
          <p>{t('gender')}</p>
          <Radio
            isFilter={true}
            value={paramAttributes.gender}
            onChange={(value) =>
              setParamAttributes((prevState) => {
                return {
                  ...prevState,
                  gender: value,
                };
              })
            }
            options={gender_menu}
          />
        </div>
      )}
    </>
  );
  const renderEducationFilter = () => (
    <>
      {!largeScreen ? (
        <div className={style.filter}>
          <Select
            placeholder={t('education')}
            options={education_level_menu}
            value={paramAttributes.education_level ? paramAttributes.education_level : null}
            onChange={(value) =>
              setParamAttributes((prevState) => {
                return {
                  ...prevState,
                  education_level: value,
                };
              })
            }
          />
        </div>
      ) : (
        <div>
          <p>{t('education')}</p>
          <Radio
            isFilter={true}
            value={paramAttributes.education_level}
            onChange={(value) =>
              setParamAttributes((prevState) => {
                return {
                  ...prevState,
                  education_level: value,
                };
              })
            }
            options={education_level_menu}
          />
        </div>
      )}
    </>
  );

  const renderFilters = () => (
    <>
      {!largeScreen && (
        <div className={style.filter}>
          <Search
            value={paramAttributes.query}
            className={'childrenSearchInput'}
            placeholder={t('search-placeholder')}
            bordered={true}
            onChange={(e) =>
              setParamAttributes((prevState) => {
                return {
                  ...prevState,
                  query: e.target.value,
                };
              })
            }
          />
        </div>
      )}
      {renderGenderFilter()}
      {renderEducationFilter()}
      <div className={style.filter}>
        {largeScreen && <p>{t('village')}</p>}
        <Select
          placeholder={t('village')}
          options={parseVillagesData()}
          value={paramAttributes.village_ids ? paramAttributes.village_ids : null}
          onChange={(value) =>
            setParamAttributes((prevState) => {
              return {
                ...prevState,
                village_ids: value,
              };
            })
          }
        />
      </div>
      <div className={style.clearFiltersSearch}>
        <div className={style.clearFilters} onClick={clearFilters}>
          <img src="/images/clear-filters.svg" width={'40px'} />
        </div>

        <div className={style.searchBtn}>
          <CtaButton
            type={'fill-primary'}
            text={openDrawer ? t('filter') : t('search')}
            customStyle={{
              minWidth: xxsmallScreen ? '160px' : xxLargeScreen ? '220px' : '276px',
              minHeight: xLargeScreen ? '64px' : '70px',
              fontSize: xxLargeScreen ? '1.3rem' : '1.6rem',
              fontWeight: 'bold',
            }}
            onClick={onSearchExecute}
          />
          <div className={style.icon}>
            <img src="/images/icon.svg" />
          </div>
        </div>
      </div>
    </>
  );

  const renderBurgerMenu = () =>
    !openDrawer ? (
      <div className={style.filterDrawer}>
        <div className={style.filterOPenIcon} onClick={() => SetOpenDrawer((prev) => !prev)}>
          <img src="/images/filter.svg" />
        </div>

        <Search
          value={paramAttributes.query}
          className={'childrenSearchInput'}
          placeholder={t('search-placeholder')}
          bordered={true}
          onChange={(e) =>
            setParamAttributes((prevState) => {
              return {
                ...prevState,
                query: e.target.value,
              };
            })
          }
          onSearch={onSearchExecute}
          enterButton
        />
      </div>
    ) : (
      <div className={style.filterDrawerWrapper}>
        <div className={style.filterDrawer}>
          <div className={style.filterDrawerTitle}>
            <p>{t('filter')}</p>
          </div>
          <div className={style.filterCloseIcon} onClick={() => SetOpenDrawer((prev) => !prev)}>
            <img src="/images/close.svg" />
          </div>
        </div>
        <div className={style.filterDrawerContent}>{renderFilters()}</div>
      </div>
    );

  const childrenList = () =>
    sponsoreeSelector.map((el, index) => (
      <Col key={index} xl={6} lg={12} md={12} sm={24} xs={24} className={style.childrenList}>
        <Card
          key={el.id}
          title={el.full_name}
          image={el.image_url}
          onClickButton={() => {
            redirect(`/children/${el.id}`);
          }}
          subTitle={
            <div className={style.childrenListSubTitle}>
              <span className={style.childrenListSubTitleCode}>{el.code}</span>
              <span>{yearTranslateHelper(t, el.current_age)}</span>
            </div>
          }
          textButton={t('chooseMe')}
          badge={el.education_level === 'rising_leader' ? t('university-badge') : ''}
          customCardStyle={{
            border: '1px solid #97a3ae',
            height: mediumScreen ? '237px' : '282px',
            alignItems: 'start',
            overflow: 'hidden',
          }}
        />
      </Col>
    ));

  const renderTestimonies = () =>
    Testimonies(t).map((el, index) => (
      <Col key={index} xl={4} lg={12} md={12} sm={24} xs={24} className={style.testimoniesItem}>
        <div className={style.testimoniesList}>
          <div className={style.testimonyImage}>
            <img src={el.image} />
          </div>
          <div className={style.testimonyName}>{el.name}</div>
          <div className={style.testimonyPosition}>{el.position}</div>
          <div className={style.testimonyFeedback}>{el.feedback}</div>
        </div>
      </Col>
    ));

  return (
    <div className={style.childrenWrapper}>
      <HelmetTag
        title="Children"
        description="Healing Grace Children"
        url="children"
        image_url="https://company-assets.fra1.cdn.digitaloceanspaces.com/healing-grace/logo.png"
      />
      <div className={style.childrenListWrapper}>
        <div className={style.childrenList}>
          {!openDrawer && (
            <div className={style.childrenListHeader}>
              <SectionHeader text={t('our-children')} customstyle={{ backgroundColor: '#f6f8f9' }} />
            </div>
          )}
          <div className={`${style.childrenFilters} ${openDrawer ? style.childrenFiltersDrawer : ''}`}>
            {!largeScreen ? renderFilters() : renderBurgerMenu()}
          </div>
          <div className={style.childrenListCards}>
            <Row
              className={style.childrenListCardsRow}
              gutter={[{ xl: 40, lg: 40, md: 40, sm: 0, xs: 0 }, mediumScreen ? 37 : xxLargeScreen ? 57 : 100]}
            >
              {!loading && sponsoreeSelector.length > 0 && childrenList()}
              {!loading && sponsoreeSelector.length == 0 && <EmptyList />}
              {loading && <LoadingList />}
            </Row>
            {!loading && (
              <div className={style.childrenPagination}>
                <Pagination total={childrenCount} pageSize={PAGE_SIZE} current={currenPage} onChange={onPageChange} />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className={style.testimoniesWrapper}>
        <div className={style.testimoniesHeader}>
          <SectionHeader
            text={t('testimonies')}
            customTextStyle={{ fontSize: mediumScreen ? '1.563rem' : xxLargeScreen ? '1.875rem' : '3.125rem' }}
          />
        </div>
        <Row className={style.testimoniesContent}>{renderTestimonies()}</Row>
      </div> */}
    </div>
  );
};

export default Children;
