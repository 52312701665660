import SectionHeader from 'components/atoms/SectionHeader';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import style from './index.module.scss';

const SponsorShip = () => {
  const { t, i18n } = useTranslation('home');
  const xxLargeScreen = useMedia(`(max-width: 1600px)`);
  const mediumScreen = useMedia(`(max-width: 768px)`);
  const smallScreen = useMedia(`(max-width: 576px)`);

  return (
    <div className={style.sponsorshipWrapper}>
      <SectionHeader
        text={t('sponsorship-dimensions')}
        customTextStyle={{ fontSize: mediumScreen ? '1.563rem' : xxLargeScreen ? '30px' : '50px' }}
      />
      <div className={style.sponsorshipImage}>
        {!smallScreen ? (
          <img
            src={`${i18n.language == 'ar' ? '/images/sponsorship-ar-web.gif' : '/images/sponsorship-en-web.gif'}`}
            alt="SponsorShip"
          />
        ) : (
          <img
            src={`${i18n.language == 'ar' ? '/images/sponsorship-ar-mob.png' : '/images/sponsorship-en-mob.png'}`}
            alt="SponsorShip"
          />
        )}
      </div>
    </div>
  );
};

export default SponsorShip;
