import { Input } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import style from './index.module.scss';

const CommunityInput = ({ placeholder, onChange, value, ...props }) => {
  const { t } = useTranslation('communityInput');

  const [community, setCommunity] = useState({});
  const [status, setStatus] = useState('empty');
  const [code, setCode] = useState('');

  const handleChange = async (e) => {
    let _code = e.target.value;
    setCode(_code);
    await axios
      .get(`/guests/communities`, { params: { code: _code } })
      .then((res) => {
        setStatus('sucess');
        setCommunity(res.data);
        onChange({
          code: _code,
          community_name: res.data.community_name,
          id: res.data.id,
        });
      })
      .catch((error) => {
        setStatus('error');
        onChange({
          code: _code,
        });
      });
    if (_code == '') {
      setStatus('empty');
      return;
    }
  };

  useEffect(() => {
    if (value) {
      if (!code) setCode(value.code);
      if (value.id) {
        setStatus('sucess');
      } else {
        setStatus('error');
      }
    } else {
      setStatus('empty');
    }
  }, [value]);
  return (
    <>
      <Input
        className={status === 'sucess' ? style.communitySucess : status === 'error' ? style.communityError : ''}
        placeholder={placeholder}
        onChange={handleChange}
        value={code}
        {...props}
      />
      {status === 'sucess' && <div className={style.communityNameSuccess}>{t('joinSucces')}</div>}
      {status === 'error' && <div className={style.communityNameError}>{t('joinError')}</div>}
    </>
  );
};

export default CommunityInput;
