import { MaskedInput } from 'antd-mask-input';
import { useMemo } from 'react';
import style from './index.module.scss';

const Mask = ({ value, onChange, mask, parse, format }) => {
  const _mask = useMemo(() => [mask], []);

  const handleChange = (e) => {
    let formatedValue = e.maskedValue;
    if (format) {
      formatedValue = format(formatedValue);
    }
    onChange(formatedValue);
  };

  const getValue = () => {
    if (parse && value) return parse(value);
    else return value;
  };

  return (
    <div className="maskedInput">
      <MaskedInput dir="ltr" mask={_mask} value={getValue()} onChange={handleChange} />
    </div>
  );
};

export default Mask;
