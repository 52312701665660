const STEPS_NUMBER = 3;
const stepsMap = {
  image: 0,
  first_name: 0,
  last_name: 0,
  full_name: 0,
  email: 0,
  password: 0,
  confirmPassword: 0,
  country_code: 1,
  phone_number_primary: 1,
  phone_number_secondary: 1,
  community_id: 1,
  has_whatsapp: 1,
  language_preference: 1,
  date_of_birth: 1,
  gender: 1,
  city: 2,
  state: 2,
  street_name: 2,
  zip: 2,
};

export const navigateToFirstStepinList = (keyList) => {
  let min = STEPS_NUMBER - 1;
  for (let i = 0; i < keyList.length; i++) {
    if (stepsMap[keyList[i]] < min) min = stepsMap[keyList[i]];
  }
  return min;
};
